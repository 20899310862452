import { useContext, useEffect, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link, Outlet } from 'react-router-dom';
import { getSessionStorage, setSessionStorage } from 'src/common/storage';
import { useGetTranslationLangsQuery } from 'src/reducers/app/api';

import { toAbsoluteUrl } from '../../_metronic/helpers';
import { I18nContext } from '../../_metronic/i18n/Metronici18n';
import SvgIcon from '../host-view/atoms/icons/SvgIcon';

const { REACT_APP_API_X_LOCALE: X_LOCALE } = process.env;

const AuthLayout = () => {
	const toggleRef = useRef<HTMLDivElement | null>(null);
	const { changeLanguage: updateLanguage } = useContext(I18nContext);

	const [isLangsOpen, setIsLangsOpen] = useState(false);
	const [selectedLang, setSelectedLang] = useState(getSessionStorage('language') ?? X_LOCALE);

	const { data: languages, isSuccess } = useGetTranslationLangsQuery({});

	const changeLanguage = (lang: string) => {
		updateLanguage(lang);
		setIsLangsOpen(false);
		setSelectedLang(lang);
		setSessionStorage('language', lang);
	};

	const toggleLanguages = () => {
		setIsLangsOpen((prev) => !prev);
	};

	useEffect(() => {
		const handleOutsideClick = (event: MouseEvent) => {
			if (toggleRef.current && !toggleRef.current.contains(event.target as Node)) {
				setIsLangsOpen(false);
			}
		};

		if (isLangsOpen) {
			document.addEventListener('mousedown', handleOutsideClick);
		} else {
			document.removeEventListener('mousedown', handleOutsideClick);
		}

		return () => {
			document.removeEventListener('mousedown', handleOutsideClick);
		};
	}, [isLangsOpen]);

	useEffect(() => {
		const handleResize = () => {
			setIsLangsOpen(false);
		};

		window.addEventListener('resize', handleResize);

		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	useEffect(() => {
		const root = document.getElementById('root');
		if (root) {
			root.style.height = '100%';
			document.body.classList.add('app-auth-default');
		}

		return () => {
			if (root) {
				root.style.height = 'auto';
			}
		};
	}, []);

	return (
		<>
			{isSuccess && languages && languages.length > 0 && (
				<div className="d-flex justify-content-end p-6 z-index-100 position-absolute w-100 top-0 start-0">
					<div className="dropdown">
						<div
							ref={toggleRef}
							className="d-flex align-items-center"
							role="button"
							id="languageDropdown"
							onClick={toggleLanguages}
							aria-expanded={isLangsOpen}
							style={{ pointerEvents: 'auto' }}
						>
							<div className="d-flex justify-content-center align-items-center position-relative">
								<SvgIcon path="/icons/globe.svg" className="svg-icon-primary svg-icon-3" />
								<div className="text-white ps-2 pe-1 fs-5">{languages.find((lang) => lang.code === selectedLang)?.name}</div>
								<SvgIcon path="/icons/arrow-down.svg" />
							</div>
						</div>
						{isLangsOpen && (
							<div ref={toggleRef} className="dropdown-menu dropdown-menu-end show mt-2 min-w-auto p-1">
								{languages
									.filter(({ code }) => code !== selectedLang)
									.map(({ code, abbr, flag }) => (
										<button
											key={code}
											className="dropdown-item d-flex align-items-center no-focus px-0"
											onClick={() => changeLanguage(code)}
										>
											<img src={flag} alt={`${abbr} Flag`} className="me-2 rounded-circle" width="24" height="24" />
											<div className="fs-5 fw-bold me-2">{abbr}</div>
										</button>
									))}
							</div>
						)}
					</div>
				</div>
			)}

			<div className="d-flex align-items-center flex-column flex-lg-row justify-content-center justify-content-lg-around min-h-100 py-20">
				<div className="d-flex order-1 order-lg-2">
					<div className="d-flex flex-center flex-column">
						<div className="w-300px w-sm-400px w-lg-500px">
							<Outlet />
						</div>
					</div>
				</div>

				<div className="d-flex order-2 order-lg-1">
					<div className="d-flex flex-column flex-center py-15 px-5 px-md-15 w-100">
						<Link to="/" className="mb-2">
							<img alt="Logo" src={toAbsoluteUrl('/media/logos/tabol-logo.svg')} className="h-55px" />
						</Link>

						<h5 className="text-center text-white mb-2 ps-9">
							<FormattedMessage id="AUTH.LAYOUT.TEXT" />
						</h5>
					</div>
				</div>
			</div>
		</>
	);
};

export { AuthLayout };
