import { useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { useEscKey } from 'src/helpers/utils';

import { KTSVG } from '../../../_metronic/helpers';

const ConfirmationModal = ({ show, onConfirm, content }) => {
	const intl = useIntl();

	const defaultContent = {
		title: intl.formatMessage({ id: 'MODAL.FORM.CONFIRM' }),
		message: intl.formatMessage({ id: 'MODAL.FORM.SURE_ABOUT_ACTION' }),
		button: intl.formatMessage({ id: 'MODAL.FORM.PROCEED' }),
	};

	let listener = useEscKey(show, () => onConfirm(true));

	const mergedContent = { ...defaultContent, ...content };

	return (
		<Modal
			id="kt_modal_action_confirmation"
			tabIndex={-1}
			aria-hidden="true"
			dialogClassName="modal-dialog modal-dialog-centered mw-500px"
			style={{ zIndex: 9999, backgroundColor: `rgba(0, 0, 0, 0.5)` }}
			show={show === true}
			onHide={() => onConfirm(false)}
			backdrop
		>
			<div className="modal-header">
				<h2>{mergedContent.title}</h2>
				<div className="btn btn-sm btn-icon btn-active-color-primary" onClick={() => onConfirm(false)}>
					<KTSVG className="svg-icon-1" path="/media/icons/duotune/arrows/arr061.svg" />
				</div>
			</div>

			<div className="modal-body py-lg-5 px-lg-5">
				<p className="fs-4 fw-bold text-gray-700">{mergedContent.message}</p>
			</div>

			<div className="modal-footer">
				<button type="button" className="btn btn-secondary" onClick={() => onConfirm(false)}>
					<FormattedMessage id="GENERAL.CANCEL" />
				</button>
				<button type="button" className="btn btn-primary" onClick={() => onConfirm(true)}>
					{mergedContent.button}
				</button>
			</div>
		</Modal>
	);
};

export default ConfirmationModal;
