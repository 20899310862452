export const partySizeOptions = Array.from({ length: 350 }, (_, i) => ({
	value: i + 1,
	label: (i + 1).toString(),
}));
export const sourceOptions = [
	{ value: 'phone', label: 'Phone' },
	{ value: 'walk-in', label: 'Walk-In' },
	{ value: 'email', label: 'Email' },
];

export const sourceExtraOptions = [
	{ value: 'phone', label: 'Phone' },
	{ value: 'walk-in', label: 'Walk-In' },
	{ value: 'email', label: 'Email' },
	{ value: 'mobile-app', label: 'Mobile App' },
	{ value: 'widget', label: 'Widget' },
];

export const statusOptions = [
	{ value: 'pending', label: 'pending' },
	{ value: 'arrived', label: 'arrived' },
];

export const statusExtraOptions = [
	{ value: 'pending', label: 'Pending' },
	{ value: 'confirmed', label: 'Confirmed' },
	{ value: 'rejected', label: 'Rejected' },
	{ value: 'arrived', label: 'Arrived' },
	{ value: 'completed', label: 'Completed' },
	{ value: 'client_canceled', label: 'Client Canceled' },
	{ value: 'store_canceled', label: 'Store Canceled' },
	{ value: 'noshow', label: 'No-show' },
];