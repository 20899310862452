import React, { useState } from 'react';
import { Controller } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import Select from 'react-select';

import useModal from '../../../../../components/modal/useModal';
import { useGetReservationPreferencesQuery, useGetStoreTraitsQuery } from '../../../../../reducers/stores/storeApiSlice';
import { optionsEnvironment, optionsPreferences, optionsServices, optionsSuggested } from './StoreOptions';

const LocationSelectFields = (props) => {
	const { control, watch, customStyles, animatedComponents, setValue } = props;
	const { id } = useModal();
	const { data: preferences } = useGetReservationPreferencesQuery({ path: { id } });
	const { data: traits } = useGetStoreTraitsQuery({ path: { id } });
	const preferencesData = watch('reservation_prefs');
	const miscData = watch('traits.misc') ?? [];
	const infoData = watch('traits.info') ?? [];
	const misc = traits ? traits.filter((i) => i.type === 'misc') : [];
	const information = traits ? traits.filter((i) => i.type === 'info') : [];
	const isChecked = (value, array) => (value && array && array.length > 0 ? array.some((item) => item.value === value) : false);
	const intl = useIntl();

	return (
		<>
			<div className="row">
				<div className="col-12">
					<div className="separator my-5" />
				</div>
			</div>

			<div className="row pb-5">
				<div className="col-12">
					<div className="mb-0 fs-2 fw-bold">
						<FormattedMessage id="STORE.MODAL.DETAILS.FILTERS" />
					</div>
				</div>
			</div>

			<div className="row pb-5">
				<div className="col-12">
					<div className="input-group">
						<div className=" pb-3 mb-0 fs-7 fw-bold">
							<FormattedMessage id="STORE.MODAL.DETAILS.SUGGESTION" />
						</div>
						<Controller
							control={control}
							name="traits.suggestions"
							render={({ field: { value, name, onChange, ref } }) => (
								<Select
									components={{
										// DropdownIndicator: () => null,
										// IndicatorSeparator: () => null,
										animatedComponents,
									}}
									inputRef={ref}
									closeMenuOnSelect={false}
									isMulti
									onChange={onChange}
									options={traits ? traits.filter((i) => i.type === 'suggested_for') : optionsSuggested}
									styles={customStyles}
									value={value}
									name={name}
									ref={ref}
									placeholder={intl.formatMessage({ id: 'PLACEHOLDER.SELECT' })}
								/>
							)}
						/>
					</div>
				</div>
			</div>

			<div className="row pb-5">
				<div className="col-12">
					<div className="input-group">
						<div className=" pb-3 mb-0 fs-7 fw-bold">
							<FormattedMessage id="STORE.MODAL.DETAILS.SERVICES" />
						</div>
						<Controller
							control={control}
							name="traits.services"
							render={({ field: { value, name, onChange, ref } }) => (
								<Select
									components={{
										// DropdownIndicator: () => null,
										// IndicatorSeparator: () => null,
										animatedComponents,
									}}
									inputRef={ref}
									closeMenuOnSelect={false}
									isMulti
									onChange={onChange}
									options={traits ? traits.filter((i) => i.type === 'services') : optionsServices}
									styles={customStyles}
									value={value}
									name={name}
									ref={ref}
									placeholder={intl.formatMessage({ id: 'PLACEHOLDER.SELECT' })}
								/>
							)}
						/>
					</div>
				</div>
			</div>

			<div className="row pb-5">
				<div className="col-12">
					<div className="input-group">
						<div className=" pb-3 mb-0 fs-7 fw-bold">
							<FormattedMessage id="STORE.MODAL.DETAILS.ENVIRONMENT" />
						</div>
						<Controller
							control={control}
							name="traits.environment"
							render={({ field: { value, name, onChange, ref } }) => (
								<Select
									components={{
										// DropdownIndicator: () => null,
										// IndicatorSeparator: () => null,
										animatedComponents,
									}}
									inputRef={ref}
									closeMenuOnSelect={false}
									isMulti
									onChange={onChange}
									options={traits ? traits.filter((i) => i.type === 'environment') : optionsEnvironment}
									styles={customStyles}
									value={value}
									name={name}
									ref={ref}
									placeholder={intl.formatMessage({ id: 'PLACEHOLDER.SELECT' })}
								/>
							)}
						/>
					</div>
				</div>
			</div>
			<div className="row">
				{misc && misc.length > 0
					? misc.map((deal, index) => (
							<div className="col-12 col-sm-4" key={`misc-${index}`}>
								<div className="form-check form-check-custom form-check-solid my-2">
									<input
										className="form-check-input"
										type="checkbox"
										id={`misc-${index}`}
										aria-labelledby={`misc-checkbox-${deal.label}`}
										defaultChecked={isChecked(deal.value, miscData)}
										onChange={(e) =>
											setValue(
												`traits.misc[${
													miscData.findIndex((i) => i.value === deal.value) !== -1
														? miscData.findIndex((i) => i.value === deal.value) ?? 0
														: miscData.length++ ?? 0
												}]`,
												{ label: deal.label, value: deal.value, checked: e.target.checked }
											)
										}
									/>
									<label className="form-check-label fw-bold fs-7" htmlFor={`misc-${index}`}>
										<span> {deal?.label} </span>
									</label>
								</div>
							</div>
					  ))
					: null}
			</div>
			<div className="row">
				<div className="col-12">
					<div className="separator my-5" />
				</div>
			</div>
			<div className="row pb-5">
				<div className="col-12">
					<div className="mb-0 fs-2 fw-bold">
						<FormattedMessage id="STORE.MODAL.DETAILS.PREFERENCES" />
					</div>
				</div>
			</div>
			<div className="row">
				<div className="col-12">
					<div className="input-group">
						<div className="pb-3 mb-0 fs-7 fw-bold">
							<FormattedMessage id="STORE.MODAL.DETAILS.PREFERENCES" />
						</div>
						<Controller
							control={control}
							name="reservation_prefs"
							render={({ field: { value, name, onChange, ref } }) => (
								<Select
									components={{
										// DropdownIndicator: () => null,
										// IndicatorSeparator: () => null,
										animatedComponents,
									}}
									inputRef={ref}
									defaultValue={preferencesData ? preferencesData : preferences ? preferences.find((c) => value === c._id) : ''}
									closeMenuOnSelect={false}
									isMulti
									onChange={onChange}
									options={preferences ? preferences : optionsPreferences}
									styles={customStyles}
									value={value}
									name={name}
									ref={ref}
									placeholder={intl.formatMessage({ id: 'PLACEHOLDER.SELECT' })}
								/>
							)}
						/>
					</div>
				</div>
			</div>
			<div className="row">
				<div className="col-12">
					<div className="separator my-5" />
				</div>
			</div>
			<div className="row pb-5">
				<div className="col-12">
					<div className="mb-0 fs-2 fw-bold">
						<FormattedMessage id="STORE.MODAL.DETAILS.INFO" />
					</div>
				</div>
			</div>
			<div className="row">
				<div className="input-group">
					<div className="pb-3 mb-0 fs-7 fw-bold">
						<FormattedMessage id="STORE.MODAL.DETAILS.INFO" />
					</div>
					<div className="d-inline-flex flex-sm-row flex-column">
						{information && information.length > 0
							? information.map((info, index) => (
									<div className="col-12" key={`info-${index}`}>
										<div className="form-check form-check-custom form-check-solid my-2">
											<input
												className="form-check-input"
												type="checkbox"
												id={`info-${index}`}
												aria-labelledby={`info-checkbox-${info.label}`}
												defaultChecked={isChecked(info.value, infoData)}
												onChange={(e) =>
													setValue(
														`traits.info[${
															infoData.findIndex((i) => i.value === info.value) !== -1
																? infoData.findIndex((i) => i.value === info.value) ?? 0
																: infoData.length++ ?? 0
														}]`,
														{ label: info.label, value: info.value, checked: e.target.checked }
													)
												}
											/>
											<label className="form-check-label fw-bold fs-7" htmlFor={`info-${index}`}>
												<span> {info?.label} </span>
											</label>
										</div>
									</div>
							  ))
							: null}
					</div>
				</div>
			</div>
		</>
	);
};

export default LocationSelectFields;
