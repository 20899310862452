import prepareURL from 'src/common/prepareURL';
import { setLocalStorage } from 'src/common/storage';
import { SelectOption } from 'src/components/input/Select';
import { ForgotPasswordFormInputs, RegistrationFormInputs, ResetPasswordFormInputs } from 'src/pages/auth/schema';
import apiSlice from 'src/reducers/services/api';

import { setUser } from './slice';
import { ILoginRequest, ILoginResponse, IOwner } from './types';

const authApiSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		login: builder.mutation<ILoginResponse, ILoginRequest>({
			query: (credentials) => {
				const body = credentials.owner ? { owner_id: credentials.owner } : {};

				return {
					url: '/auth/login',
					method: 'POST',
					headers: {
						Authorization: `Basic ${btoa(`${credentials.username}:${credentials.password}`)}`,
					},
					body,
				};
			},
			onQueryStarted: async (_args, { dispatch, queryFulfilled }) => {
				try {
					const { data } = await queryFulfilled;

					const user = {
						username: data.username,
						name: data.name,
						role: data.role,
					};

					setLocalStorage('accessToken', data.access_token);
					setLocalStorage('refreshToken', data.refresh_token);
					setLocalStorage('user', JSON.stringify(user));

					dispatch(setUser(user));
				} catch (error) {
					console.error('Login failed:', error);
				}
			},
			// transformResponse: (response: ILoginResponse) => {
			// 	setLocalStorage('accessToken', response.access_token);
			// 	setLocalStorage('refreshToken', response.refresh_token);
			// 	setLocalStorage(
			// 		'user',
			// 		JSON.stringify({
			// 			username: response.username,
			// 			name: response.name,
			// 			role: response.role,
			// 		})
			// 	);

			// 	return response;
			// },
		}),
		getOwners: builder.query<SelectOption[], void>({
			query: () => ({
				url: '/users/owners',
				method: 'GET',
			}),
			transformResponse: (response: { owners: IOwner[] }) => {
				return response.owners.map((owner) => ({
					value: owner._id,
					label: owner.name,
				}));
			},
		}),
		register: builder.mutation<void, { body: RegistrationFormInputs }>({
			query: ({ body }) => {
				return {
					url: '/users/register',
					method: 'POST',
					body,
				};
			},
		}),
		forgotPassword: builder.mutation<void, { body: ForgotPasswordFormInputs }>({
			query: ({ body }) => {
				return {
					url: '/auth/password/reset',
					method: 'POST',
					body,
				};
			},
		}),
		validateToken: builder.query({
			query: (args) => ({
				url: prepareURL('/auth/password/reset/validate', args),
				method: 'GET',
			}),
		}),
		resetPassword: builder.mutation<void, { body: ResetPasswordFormInputs }>({
			query: ({ body }) => {
				return {
					url: '/auth/password/reset',
					method: 'PUT',
					body,
				};
			},
		}),
	}),
});

export const {
	useLoginMutation,
	useGetOwnersQuery,
	useRegisterMutation,
	useForgotPasswordMutation,
	useValidateTokenQuery,
	useResetPasswordMutation,
} = authApiSlice;
