import { FormattedMessage } from 'react-intl';

const FilterFooter = ({ handleReset }) => {
	return (
		<div className="col-12">
			<div className="d-flex justify-content-end py-5">
				<div className="d-flex pe-5">
					<button type="button" className="generic-button fw-bold" onClick={handleReset}>
						<FormattedMessage id="FILTERS.RESET" />
					</button>
				</div>
				<div className="d-flex">
					<button type="submit" form="filterForm" className="btn btn-primary">
						<FormattedMessage id="FILTERS.APPLY" />
					</button>
				</div>
			</div>
		</div>
	);
};

export default FilterFooter;
