import clsx from 'clsx';
import React from 'react';
import { Controller } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

import { minGuestsOptions } from './PaymentsOptions';

const NoShowFee = (props) => {
	const { register, control, watch, paymentData, setPaymentData, isEditMode, setValue } = props;
	const rules = watch('payment_rules') ?? [];
	const getIndex = () => (rules.length >= 1 ? rules.findIndex((i) => i?._id === paymentData?._id) ?? 0 : rules.length++ ?? 0);
	const isPrepayment = rules.length === 0 ? watch('payment_rules_temp[0].type') === 'prepayment' : paymentData?.type === 'prepayment';
	return (
		<>
			<div className="row pb-5">
				<div className="col-12 d-flex">
					<div className="form-check form-check-custom form-check-solid fs-6 fw-bold pe-5">
						<input
							className="form-check-input"
							type="radio"
							defaultChecked={paymentData?.type === 'prepayment' || !paymentData.type}
							value={paymentData?.type}
							id="prepayment"
							name="type"
							aria-labelledby="prepayment"
							onClick={() =>
								isEditMode
									? setPaymentData({ ...paymentData, type: 'prepayment' })
									: setValue('payment_rules_temp[0].type', 'prepayment')
							}
						/>
						<label className="form-check-label secondary-text" htmlFor="prepayment">
							<FormattedMessage id="LOCATION.DETAILS.PREPAYMENT" />
						</label>
					</div>
					<div className="form-check form-check-custom form-check-solid fs-6 fw-bold pe-5">
						<input
							className="form-check-input"
							type="radio"
							defaultChecked={paymentData?.type === 'no_show_fee' ?? false}
							value={paymentData?.type}
							onClick={() =>
								isEditMode
									? setPaymentData({ ...paymentData, type: 'no_show_fee' })
									: setValue('payment_rules_temp[0].type', 'no_show_fee')
							}
							id="no-show-fee"
							name="type"
							aria-labelledby="no-show-fee"
						/>
						<label className="form-check-label secondary-text" htmlFor="no-show-fee">
							<FormattedMessage id="LOCATION.DETAILS.NOSHOW_FEE" />
						</label>
					</div>
				</div>
			</div>

			<>
				<div className="row pb-5">
					<div className="col-12">
						<div className="mb-0 fs-2 fw-bold">
							{paymentData?.type === 'prepayment' ? (
								<FormattedMessage id="LOCATION.DETAILS.PREPAYMENT" />
							) : (
								<FormattedMessage id="LOCATION.DETAILS.NOSHOW_FEE" />
							)}
						</div>
					</div>
				</div>

				<div className="row pb-5">
					<div className="col-12">
						<p className="mb-0 secondary-text fs-7">
							{isPrepayment ? (
								<FormattedMessage id="LOCATION.DETAILS.ACTIVE_PREPAYMENT_TXT" />
							) : (
								<FormattedMessage id="LOCATION.DETAILS.NO_SHOW_TXT" />
							)}
						</p>
					</div>
				</div>

				<div className="row pb-5">
					<div className="col-12">
						<div className="input-group icon-input">
							<label htmlFor="guest-email" className="form-label pb-3 mb-0 fs-6 fw-bold w-100">
								<FormattedMessage id="LOCATION.DETAILS.AMOUNT" />
							</label>
							<div className="custom-input-icon icon-left d-flex align-items-center fw-bold">€</div>
							{isEditMode ? (
								<input
									type="text"
									className="form-control p-4 fs-6 fw-bold w-auto"
									data-role="tagsinput"
									placeholder="1"
									{...register(`payment_rules[${getIndex()}].amount`)}
								/>
							) : (
								<input
									type="text"
									className="form-control p-4 fs-6 fw-bold w-auto"
									data-role="tagsinput"
									placeholder="1"
									{...register(`payment_rules_temp[0].amount`)}
								/>
							)}
						</div>
					</div>
					<div className="col-12">
						<p className="mb-0 pt-2 secondary-text fs-7">
							{isPrepayment ? (
								<FormattedMessage id="LOCATION.DETAILS.PREPAYMENT_AMOUNT_TXT" />
							) : (
								<FormattedMessage id="LOCATION.DETAILS.NO_SHOW_AMOUNT_TXT" />
							)}
						</p>
					</div>
				</div>

				<div className="row">
					<Controller
						name={isEditMode ? `payment_rules[${getIndex()}].min_prepayment_guests` : `payment_rules_temp[0].min_prepayment_guests`}
						control={control}
						render={({ field }) => (
							<div className="col-12">
								<div className="pb-3 fs-7 fw-bold">
									<FormattedMessage id="LOCATION.DETAILS.MIN_GUESTS_PAYMENT" />
								</div>
								{isEditMode ? (
									<div className="select-container">
										<select
											className="fs-6 fw-bold"
											aria-label="Select example"
											{...register(`payment_rules[${getIndex()}].min_prepayment_guests`)}
										>
											{minGuestsOptions.map((minGuestsOption) => (
												<option
													key={minGuestsOption.value}
													value={minGuestsOption.value}
													defaultValue={minGuestsOption.value}
												>
													{minGuestsOption.label}
												</option>
											))}
										</select>
										<i className="fa-solid fa-chevron-down arrow-icon"></i>
									</div>
								) : (
									<div className="select-container">
										<select
											className="fs-6 fw-bold"
											aria-label="Select example"
											{...register('payment_rules_temp[0].min_prepayment_guests')}
										>
											{minGuestsOptions.map((minGuestsOption) => (
												<option
													key={minGuestsOption.value}
													value={minGuestsOption.value}
													defaultValue={minGuestsOption.value}
												>
													{minGuestsOption.label}
												</option>
											))}
										</select>
										<i className="fa-solid fa-chevron-down arrow-icon"></i>
									</div>
								)}
							</div>
						)}
					/>
					<div className="col-12">
						<p className="mb-0 pt-2 secondary-text fs-7">
							<FormattedMessage id="LOCATION.DETAILS.MIN_GUESTS_PAYMENT_TXT" />
						</p>
					</div>
				</div>

				<div className="row">
					<div className="col-12">
						<div className="separator my-5" />
					</div>
				</div>

				<div className="row pb-5">
					<div className="col-12">
						<div className="info-box p-3">
							<p className="mb-0 dark-text">
								<b>
									<FormattedMessage id="LOCATION.DETAILS.STATUS" />:
								</b>{' '}
								<FormattedMessage id="LOCATION.DETAILS.DISABLE_PAYMENT_RULES" />
							</p>
							<p className="mb-0 dark-text">
								<b>
									<FormattedMessage id="LOCATION.DETAILS.AUTOCANCEL_PERIOD" />:
								</b>{' '}
								<FormattedMessage id="LOCATION.DETAILS.AUTOCANCEL_TXT" />
							</p>
							<p className="mb-0 dark-text">
								<b>
									<FormattedMessage id="LOCATION.DETAILS.TERMS" />:
								</b>{' '}
								<FormattedMessage id="LOCATION.DETAILS.TERMS_TXT" />
							</p>
						</div>
					</div>
				</div>
				<div className="row pb-5">
					<div className="col-12">
						<div className="info-box p-3">
							<p className="mb-0 dark-text">
								<b>
									<FormattedMessage id="LOCATION.DETAILS.RULE_TYPE" />:
								</b>
							</p>
							<p className="mb-0 dark-text">
								<b>
									<FormattedMessage id="LOCATION.DETAILS.PER_BOOKING" />:
								</b>{' '}
								<FormattedMessage id="LOCATION.DETAILS.BOOKING_TXT" />
							</p>
						</div>
					</div>
				</div>
				<div className="row pb-5">
					<div className="col-12">
						<div className="info-box p-3">
							<p className="mb-0 dark-text">
								<b>
									<FormattedMessage id="LOCATION.DETAILS.CHARGE_TYPE" />
								</b>
							</p>
							<p className="mb-0 dark-text">
								<b>
									<FormattedMessage id="LOCATION.DETAILS.PREPAYMENT" />:
								</b>{' '}
								<FormattedMessage id="LOCATION.DETAILS.PREPAYMENT_TXT" />
							</p>
						</div>
					</div>
				</div>
			</>
		</>
	);
};

export default NoShowFee;
