import React, { forwardRef, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { PopUp, PopUpContext } from 'src/components/Popup';
import { useGetAppSectionsQuery } from 'src/reducers/app/api';
import reservationApiSlice from 'src/reducers/reservation/reservationApiSlice';

import decoration from '../../../../components/table/decoration';
import tagApiSlice, {
	createTagRefFromArray,
	useAssignTagMutation,
	useGetTagCategoriesQuery,
	useGetTagsBySectionQuery,
	useGetTagsForRefQuery,
	useUnassignTagMutation,
} from '../api/tagApiSlice';

export const TableTagContent = ({ item }) => {
	const [popup, setPopup] = useState(false);
	return <TagListWrapper row={item} onClick={() => setPopup(true)} />;
};

export const TagPicker = ({ individualRowId, tagLocation, onApply, section, selectedRows, refModel }) => {
	const appSections = useGetAppSectionsQuery({});

	let sectionsForTags = useMemo(() => {
		return (appSections?.data ?? [])
			.filter((s) => {
				return section.includes(s.key);
			})
			.map((ss) => ss._id);
	}, [appSections]);

	//setup, get all tags and categories
	const tags = useGetTagsBySectionQuery(sectionsForTags, { skip: sectionsForTags.length == 0 });
	const categories = useGetTagCategoriesQuery({});
	const [categoryMap, setCategoryMap] = useState({});

	const [assignTag, assignResult] = useAssignTagMutation({});
	const [unssignTag, unassignResult] = useUnassignTagMutation({});

	const [tagToItems, setTagToItems] = useState({});
	const { reload, refreshView, onClose } = useContext(PopUpContext);
	const editingData = useMemo(() => {
		return (individualRowId && [individualRowId]) || selectedRows;
	}, [individualRowId, selectedRows]);

	let allTagsZero = useMemo(() => {
		return Object.values(categoryMap).reduce((current, catTag) => {
			if (catTag.tags.length == 0) return current;
			return current + catTag.tags.length;
		}, 0);
	}, [categoryMap]);

	const tagsForReference = useGetTagsForRefQuery(editingData);
	const sortedSelectedRows = useMemo(() => {
		let rows = [...editingData];
		return rows.sort();
	}, [editingData]);
	/**
	 * Create a structure {category_id: {category: category, tags: [tags]}}
	 * Steps to create the correct State:
	 * 1. Get all tags and categories for this section.
	 * 2. For all the selected rows, get the tags assigned to them.
	 * 3. if a tag has more than one references, mark the checkbox as indeterminate.
	 */
	let assignments = useRef({ assigned: {}, unassigned: {} });

	useEffect(() => {
		refreshView(true);

		if (categories.data?.result) {
			const map = {};
			categories.data?.result?.forEach((category) => {
				map[category._id] = { category, tags: [] };
			});
			tags.data?.result?.forEach((tag) => {
				map[tag.category._id] && (map[tag.category._id].tags = [...map[tag.category._id].tags, tag]);
			});
			setCategoryMap(map);
			refreshView(true);
		}
	}, [categories, tags, editingData]);
	//create a mapping of tagId: tags to help represent the list of checkboxes
	useEffect(() => {
		const tagToItemsMap = {};
		if (tagsForReference.data) {
			for (const assignedTag of tagsForReference.data) {
				let entryExists = tagToItemsMap[assignedTag.tag._id] != null;
				if (entryExists) {
					tagToItemsMap[assignedTag.tag._id] = [...tagToItemsMap[assignedTag.tag._id], assignedTag.ref_id].sort();
				} else {
					tagToItemsMap[assignedTag.tag._id] = [assignedTag.ref_id];
				}
			}
			setTagToItems(tagToItemsMap);
		}
	}, [tagsForReference.data]);

	const handleCheck = (id) => {
		let _assignments = assignments.current;
		let [checked, indeterminate] = getCheckedState(id);

		if (checked && !indeterminate) {
			_assignments.assigned[id] = [];
			_assignments.unassigned[id] = editingData;
			setTagToItems({ ...tagToItems, [`${id}`]: [] });
		} else {
			_assignments.unassigned[id] = [];
			_assignments.assigned[id] = editingData;
			setTagToItems({ ...tagToItems, [`${id}`]: editingData });
		}
	};

	const getCheckedState = (id) => {
		if (tagToItems[id] == null) return [false, false];
		let checked = editingData.length > 0 && tagToItems[id].length > 0;
		let indeterminate = selectedRows.length > 1 ? tagToItems[id].length != editingData.length : false;
		return [checked, indeterminate];
	};

	if (tags.isLoading || categories.isLoading) {
		return <img style={{ width: 50, height: 50, scale: 0.5 }} src="/media/logos/Loader-table.svg" alt="Tabol logo" />;
	}
	//if all the tags that are retrieved are zero, then return no tags found
	if (allTagsZero == 0) return <div>No tags found</div>;
	// !reload && setReload(true);
	return (
		<>
			<div style={{ display: 'grid', justifyContent: 'center', alignItems: 'center' }}>
				{Object.values(categoryMap).map((catTag, index) => {
					const { category, tags } = catTag;
					if (tags.length == 0) return;
					return (
						<div key={category._id + index} style={{ padding: 4 }}>
							<span style={{ marginLeft: '0px', fontWeight: 'bold' }}>{category.title}</span>
							{tags.map((tag) => {
								let [checked, indeterminate] = getCheckedState(tag.id);
								let element = (
									<>
										<div key={tag.id} style={{ padding: 4, display: 'flex', flexDirection: 'row' }}>
											<label className="checkbox-container">
												<input
													id={tag.id}
													type="checkbox"
													checked={checked}
													// onClick={(e) => {}}
													onChange={(e) => {
														handleCheck(tag.id);
													}}
												/>
												<span className={`checkmark ${indeterminate ? ' select-items' : ''}`} />
											</label>
											{decoration({ type: tag.icon.shape, color: tag.icon.color, size: 'xl' })}
											<span style={{ marginLeft: '4px' }}>{tag.title}</span>
										</div>
										<div
											key={'1=' + tag.id}
											style={{ width: '100%', marginTop: '4px', height: 1, backgroundColor: '#CCCCCC' }}
										></div>
									</>
								);
								return element;
							})}
						</div>
					);
				})}
				<button
					type="button"
					className={'btn btn-sm fw-bold btn-primary'}
					onClick={async (e) => {
						e.stopPropagation();
						//Get all assigned/unassigned tags, add them in a promise array and then execute
						try {
							let { assigned, unassigned } = assignments.current;
							let promises = [];
							for (const [key, value] of Object.entries(assigned)) {
								if (value != []) {
									promises.push(assignTag({ tagId: key, reference: createTagRefFromArray(value, refModel) }).unwrap());
								}
							}
							for (const [key, value] of Object.entries(unassigned)) {
								if (value != []) {
									promises.push(unssignTag({ tagId: key, reference: createTagRefFromArray(value, refModel) }).unwrap());
								}
							}
							let results = await Promise.all(promises);
							onClose();
						} catch (error) {
							console.log('TagPicker Error', error);
						}
					}}
				>
					{'Apply'}
				</button>
			</div>
		</>
	);
};

export const TagListWrapper = ({ row, onClick }) => {
	const restCount = useCallback(() => {
		if (row.tags?.length < 2) {
			return;
		} else {
			return ` + ${row.tags.length - 1}`;
		}
	}, [row.tags]);
	const [tagPopUp, setTagPopUp] = useState(false);
	const proxyRef = useRef(null);
	if (row.tags?.length == 0 || !row.tags) {
		return <span>{row.tags?.length || '-'}</span>;
	} else {
		let tag = row.tags.find((tag, index) => index == 0);

		let TagListWithRef = forwardRef((props, ref) =>
			TagList({
				onPress: () => {
					onClick();
				},
				open: tagPopUp,
				tags: row.tags,
				ref: proxyRef,
			})
		);
		return (
			<div
				onMouseEnter={() => {
					setTagPopUp(!tagPopUp);
				}}
				onMouseLeave={() => {
					setTagPopUp(!tagPopUp);
				}}
				ref={(el) => (proxyRef.current = el)}
			>
				<span>
					{decoration({ type: tag.icon, color: tag.category?.color ?? '#000000', size: 'xl' })}
					{restCount()}
				</span>
				{!!tagPopUp && <TagListWithRef />}
			</div>
		);
	}
};

export const HorizontalTagList = ({ tags }) => {
	// No tags case
	const tagRef = useRef(null);
	const [title, setTitle] = useState(null);
	if (tags?.length === 0 || !tags) {
		return null;
	}

	// Calculate how many additional tags beyond the first 2
	const additionalCount = tags.length > 2 ? tags.length - 2 : 0;

	// Get the first 2 tags (or fewer if there aren't 2)
	const visibleTags = tags.slice(0, 2);

	return (
		<div className="flex items-center">
			{/* Display the first 2 tags */}
			<>
				{visibleTags.map((tag, index) => (
					<>
						<span
							key={index}
							className="mr-1 px-1"
							onMouseEnter={(e) => {
								tagRef.current = e.target;
								setTitle(tag.tag.title);
							}}
							onMouseLeave={() => {
								tagRef.current = null;
								setTitle(null);
							}}
						>
							{decoration({
								type: tag.tag.icon,
								color: tag.tag.category.color,
								size: 'xl',
							})}
						</span>
					</>
				))}
				<PopUp showCloseIcon={false} show={!!title} anchorElement={tagRef} selfClose={() => {}}>
					{title}
				</PopUp>
			</>
			{/* Show +X if there are more than 2 tags */}
			{additionalCount > 0 && <span className="text-sm text-gray-500">+{additionalCount}</span>}
		</div>
	);
};

export const HorizontalTagLis1t = ({ tags }) => {
	// No tags case
	if (tags?.length === 0 || !tags) {
		return <span>{tags?.length || '-'}</span>;
	}

	// Calculate how many additional tags beyond the first 2
	const additionalCount = tags.length > 2 ? tags.length - 2 : 0;

	// Get the first 2 tags (or fewer if there aren't 2)
	const visibleTags = tags.slice(0, 2);

	return (
		<div className="flex items-center">
			{/* Display the first 2 tags */}
			{visibleTags.map((tag, index) => (
				<div key={index} className="mr-1 px-1 relative group">
					<span>
						{decoration({
							type: tag.tag.icon,
							color: tag.tag.category.color,
							size: 'xl',
						})}
					</span>
					{/* Tooltip that appears on hover */}
					<div className="absolute left-1/2 -translate-x-1/2 bottom-full mb-1 px-2 py-1 bg-gray-800 text-white text-xs rounded opacity-0 group-hover:opacity-100 transition-opacity whitespace-nowrap z-10">
						{tag.tag.title}
					</div>
				</div>
			))}

			{/* Show +X if there are more than 2 tags */}
			{additionalCount > 0 && <span className="text-sm text-gray-500">+{additionalCount}</span>}
		</div>
	);
};

const TagList = ({ onPress, tags, open, ref }) => {
	return (
		<div onClick={onPress}>
			{open && (
				<PopUp show={open} anchorElement={ref} selfClose={() => {}}>
					<ul className="list-unstyled m-0">
						{tags.map((tag, index) => {
							return (
								<li key={index}>
									<span>
										{decoration({ type: tag.icon, color: tag.category.color, size: 'xl' })}
										<span style={{ margin: 10, marginLeft: 8 }}>{tag.title}</span>
									</span>
								</li>
							);
						})}
					</ul>
				</PopUp>
			)}
		</div>
	);
};
