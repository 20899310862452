import clsx from 'clsx';
import Slider from 'rc-slider';
import Tooltip from 'rc-tooltip';
import React, { useState } from 'react';
import { Controller } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import Select from 'react-select';
import { PhoneNumber } from 'src/components/input';

import useModal from '../../../../../components/modal/useModal';
import { useDialQuery } from '../../../../../reducers/contactApiSlice';
import { useGetCuisineQuery, useGetStoreTraitsQuery, useGetStoreTypesQuery } from '../../../../../reducers/stores/storeApiSlice';
import { optionsCategory, optionsCuisine, optionsCurrency, optionsPayment, optionsTags, optionsTimezone } from './StoreOptions';

const StoreDetailsInformation = (props) => {
	const { register, control, watch, errors, setValue, animatedComponents, customStyles } = props;
	const { id } = useModal();
	const { data: traits } = useGetStoreTraitsQuery({ path: { id } });
	const { data: categories } = useGetStoreTypesQuery({ path: { id } });
	const { data: cuisine } = useGetCuisineQuery({ path: { id } });
	const { data: dialCodes, isLoading: isLoadingDial } = useDialQuery();
	const categoriesData = watch('store_type');
	const currencySymbol = watch('currency.symbol');
	const intl = useIntl();
	const getToolTipText = (value) => {
		switch (value) {
			case 1:
				return currencySymbol ? currencySymbol : '€';
			case 2:
				return currencySymbol ? currencySymbol.repeat(2) : '€€';
			case 3:
				return currencySymbol ? currencySymbol.repeat(3) : '€€€';
			case 4:
				return currencySymbol ? currencySymbol.repeat(4) : '€€€€';
			default:
				return '€';
		}
	};

	return (
		<>
			<div className="row py-5">
				<div className="col-12 col-sm-6">
					<div className="input-group">
						<label htmlFor="location-email" className="form-label pb-3 mb-0 fs-7 fw-bold required">
							<FormattedMessage id="STORE.MODAL.DETAILS.EMAIL" />
						</label>
						<input
							type="email"
							className="form-control fs-6 p-4"
							id="stores-email"
							name="stores-email"
							aria-labelledby="location-email"
							placeholder={intl.formatMessage({ id: 'STORE.MODAL.DETAILS.EMAIL_ADDRESS' })}
							{...register('email')}
						/>
						{errors?.email && (
							<div className="fv-plugins-message-container">
								<div className="fv-help-block">
									<span role="alert">{errors.email.message}</span>
								</div>
							</div>
						)}
					</div>
				</div>

				<div className="col-12 col-sm-6">
					<PhoneNumber
						control={control}
						setValue={setValue}
						watch={watch}
						name={'phone'}
						errors={errors}
						register={register}
						title={intl.formatMessage({ id: 'PLACEHOLDER.PHONE_NUMBER' })}
					/>
				</div>
			</div>
			<div className="row pb-5">
				<Controller
					name="currency.code"
					control={control}
					render={({ field }) => (
						<div className="col-12 col-sm-6">
							<div className="pb-3 fs-7 fw-bold">
								<FormattedMessage id="STORE.MODAL.DETAILS.CURRENCY" />
							</div>
							<div className="select-container">
								<select
									className="fs-6 fw-bold text-capitalize disabled"
									aria-label="Select example"
									{...register('currency.code')}
									disabled
								>
									{optionsCurrency.map((currencyOption, index) => (
										<option value={currencyOption.code} key={index}>
											{optionsCurrency[index].label}
										</option>
									))}
								</select>
								<i className="fa-solid fa-chevron-down arrow-icon"></i>
							</div>
						</div>
					)}
				/>
				<Controller
					name="timezone"
					control={control}
					render={({ field }) => (
						<div className="col-12 col-sm-6">
							<div className="pb-3 pt-5 pt-sm-0 fs-7 fw-bold">
								<FormattedMessage id="STORE.MODAL.DETAILS.TIMEZONE" />
							</div>
							<div className="select-container">
								<select
									className="fs-6 fw-bold text-capitalize"
									aria-label="Select example"
									{...register('timezone')}
									defaultValue={'timezone'}
								>
									{optionsTimezone.map((timezone, index) => (
										<option value={timezone.value ?? optionsTimezone[0].label} key={index} className="">
											{optionsTimezone[index].label}
										</option>
									))}
								</select>
								<i className="fa-solid fa-chevron-down arrow-icon"></i>
							</div>

							{errors?.timezone && (
								<div className="fv-plugins-message-container">
									<div className="fv-help-block">
										<span role="alert">{errors.timezone.message}</span>
									</div>
								</div>
							)}
						</div>
					)}
				/>
			</div>
			<div className="row pb-5">
				<div className="col-12 col-sm-6 d-flex justify-content-between flex-column">
					<div className="pb-3 mb-0 fs-7 fw-bold">
						<FormattedMessage id="STORE.MODAL.DETAILS.PRICE_RANGE" />
					</div>

					<Controller
						name="price_rating"
						control={control}
						render={({ field: { value, onChange } }) => (
							<>
								<Slider
									onChange={onChange}
									value={value}
									handleRender={(node, handleProps) => {
										return (
											<Tooltip
												overlayInnerStyle={{ minHeight: 'auto' }}
												overlay={getToolTipText(handleProps.value)}
												placement="top"
											>
												{node}
											</Tooltip>
										);
									}}
									min={1}
									max={4}
									handleStyle={{
										borderColor: '#40798C',
										backgroundColor: '#40798C',
										boxShadow: '#40798C',
									}}
									trackStyle={{ backgroundColor: '#40798C' }}
									railStyle={{ backgroundColor: '#f9f9f9' }}
								/>
								<p>{getToolTipText(value)}</p>
							</>
						)}
					/>
				</div>
				<div className="col-12 col-sm-6">
					<div className="input-group">
						<div className="pb-3 pt-5 pt-sm-0 mb-0 fs-7 fw-bold required">
							<FormattedMessage id="STORE.MODAL.DETAILS.CATEGORY" />
						</div>
						<Controller
							control={control}
							name="store_type"
							render={({ field: { value, name, onChange, ref } }) => (
								<Select
									components={{
										// DropdownIndicator: () => null,
										// IndicatorSeparator: () => null,
										animatedComponents,
									}}
									inputRef={ref}
									closeMenuOnSelect={false}
									isMulti
									onChange={onChange}
									options={categories ? categories : optionsCategory}
									styles={customStyles}
									value={value}
									name={name}
									ref={ref}
									placeholder={intl.formatMessage({ id: 'PLACEHOLDER.SELECT' })}
									className={clsx({
										'is-invalid': errors?.store_type,
									})}
								/>
							)}
						/>
						{errors?.store_type ? (
							<div className="fv-plugins-message-container">
								<div className="fv-help-block">
									<span role="alert">{errors.store_type.message}</span>
								</div>
							</div>
						) : null}
					</div>
				</div>
			</div>
			<div className="row pb-5">
				<div className="col-12 col-sm-6">
					<div className="input-group">
						<div className="pb-3 mb-0 fs-7 fw-bold">
							<FormattedMessage id="STORE.MODAL.DETAILS.TAGS" />
						</div>
						<Controller
							control={control}
							name="traits.tags"
							render={({ field: { value, name, onChange, ref } }) => (
								<Select
									components={{
										// DropdownIndicator: () => null,
										// IndicatorSeparator: () => null,
										animatedComponents,
									}}
									inputRef={ref}
									closeMenuOnSelect={false}
									isMulti
									options={traits ? traits.filter((i) => i.type === 'tags') : optionsTags}
									styles={customStyles}
									onChange={onChange}
									value={value}
									name={name}
									ref={ref}
									placeholder={intl.formatMessage({ id: 'PLACEHOLDER.SELECT' })}
								/>
							)}
						/>
					</div>
				</div>
				<div className="col-12 col-sm-6">
					<div className="input-group">
						<div className=" pb-3 pt-5 pt-sm-0 fs-7 fw-bold">
							<FormattedMessage id="STORE.MODAL.DETAILS.PAYMENTS" />
						</div>
						<Controller
							control={control}
							name="traits.paymentMethods"
							render={({ field: { value, name, onChange, ref } }) => (
								<Select
									components={{
										// DropdownIndicator: () => null,
										// IndicatorSeparator: () => null,
										animatedComponents,
									}}
									inputRef={ref}
									closeMenuOnSelect={false}
									isMulti
									onChange={onChange}
									options={traits ? traits.filter((i) => i.type === 'payment_methods') : optionsPayment}
									styles={customStyles}
									value={value}
									name={name}
									ref={ref}
									placeholder={intl.formatMessage({ id: 'PLACEHOLDER.SELECT' })}
								/>
							)}
						/>
					</div>
				</div>
			</div>
			<div className="row">
				<div className="col-12">
					<div className="input-group">
						<div className=" pb-3 mb-0 fs-7 fw-bold required">
							<FormattedMessage id="STORE.MODAL.DETAILS.CUISINE" />
						</div>
						<Controller
							control={control}
							name="cuisine"
							render={({ field: { value, name, onChange, ref } }) => (
								<Select
									components={{
										// DropdownIndicator: () => null,
										// IndicatorSeparator: () => null,
										animatedComponents,
									}}
									inputRef={ref}
									defaultValue={categoriesData ? categoriesData : ''}
									closeMenuOnSelect={false}
									isMulti
									onChange={onChange}
									options={cuisine ? cuisine : optionsCuisine}
									styles={customStyles}
									value={value}
									name={name}
									ref={ref}
									placeholder={intl.formatMessage({ id: 'PLACEHOLDER.SELECT' })}
									className={clsx({
										'is-invalid': errors?.cuisine,
									})}
								/>
							)}
						/>
						{errors?.cuisine ? (
							<div className="fv-plugins-message-container">
								<div className="fv-help-block">
									<span role="alert">{errors.cuisine.message}</span>
								</div>
							</div>
						) : null}
					</div>
				</div>
			</div>
		</>
	);
};

export default StoreDetailsInformation;
