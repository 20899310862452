import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Dropdown } from 'react-bootstrap';

import { getToolbarActions } from '../../../../reducers/toolbarSlice';

const FilterAction = ({ filtersMenu }) => {
	const intl = useIntl();

	const filters = filtersMenu();
	return (
		<div className="m-0">
			<Dropdown>
				<Dropdown.Toggle as="a" className="secondary-button fs-7 fw-bold">
					{intl.formatMessage({ id: 'GENERAL.FILTERS' })}
				</Dropdown.Toggle>

				<Dropdown.Menu
					className="menu menu-sub menu-sub-dropdown w-250px w-md-300px"
				>
					{filters}
				</Dropdown.Menu>
			</Dropdown>
		</div>
	);
};

const ToolbarActions = () => {
	const toolbarActions = useSelector(getToolbarActions);

	return (
		<>
			{toolbarActions.map((action) => {
				if (action.type === 'filterAction') {
					return <FilterAction key={action?.id} filtersMenu={action?.filtersMenu} />;
				}
				return (
					<button key={action.id} type="button" className={action?.className} onClick={action.onClick} disabled={action.disabled}>
						{action?.icon}
						{action.label}
					</button>
				);
			})}
		</>
	);
};

export default ToolbarActions;
