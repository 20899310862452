import React, { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

import { dayOptions, timeOptions } from './openingHoursOptions';

const LocationDayHoursDropdowns = (props) => {
	const { register, control, setValue, resetField, day, setGlobalDay } = props;
	const handleDayChange = (val) => {
		setGlobalDay(val.toLowerCase().slice(0, 3));
		resetField('opening_hours_temp');
		setValue(`opening_hours_temp[${val.toLowerCase().slice(0, 3)}].day`, val);
	};

	return (
		<>
			<div className="row">
				<div className="col-12">
					<div className="separator my-5" />
				</div>
			</div>
			<div className="row pb-5">
				<Controller
					name={`opening_hours_temp[${day}].day`}
					control={control}
					render={() => (
						<div className="col-12">
							<div className="pb-3 fs-7 fw-bold">
								<FormattedMessage id="LOCATION.OPENING_HOURS.DAY" />
							</div>
							<div className="select-container">
								<select
									className="fs-6 fw-bold text-capitalize"
									aria-label="Select example"
									{...register(`opening_hours_temp[${day}].day`)}
									onChange={(e) => handleDayChange(e.target.value)}
								>
									{dayOptions.map((dayOption) => (
										<option key={`day-${dayOption.value}`} value={dayOption.label}>
											{dayOption.label}
										</option>
									))}
								</select>
								<i className="fa-solid fa-chevron-down arrow-icon"></i>
							</div>
						</div>
					)}
				/>
			</div>
			<div className="row pb-5">
				<Controller
					name={`opening_hours_temp[${day}].start_time`}
					control={control}
					render={({ field: { value, name, ref } }) => (
						<div className="col-12 col-sm-6">
							<div className="pb-3 fs-7 fw-bold">
								<FormattedMessage id="LOCATION.OPENING_HOURS.FROM" />
							</div>
							<div className="select-container">
								<select
									className="fs-6 fw-bold text-capitalize"
									aria-label="Select example"
									{...register(`opening_hours_temp[${day}].start_time`)}
								>
									{timeOptions.map((time) => (
										<option key={`time-start-${time.value}`} value={time.label}>
											{time.label}
										</option>
									))}
								</select>
								<i className="fa-solid fa-chevron-down arrow-icon"></i>
							</div>
						</div>
					)}
				/>

				<Controller
					name={`opening_hours_temp[${day}].end_time`}
					control={control}
					render={({ field: { value, name, ref } }) => (
						<div className="col-12 col-sm-6">
							<div className="pb-3 fs-7 fw-bold">
								<FormattedMessage id="LOCATION.OPENING_HOURS.TO" />
							</div>
							<div className="select-container">
								<select
									className="fs-6 fw-bold text-capitalize"
									aria-label="Select example"
									{...register(`opening_hours_temp[${day}].end_time`)}
								>
									{timeOptions.map((time) => (
										<option key={`time-end-${time.value}`} value={time.label}>
											{time.label}
										</option>
									))}
								</select>
								<i className="fa-solid fa-chevron-down arrow-icon"></i>
							</div>
						</div>
					)}
				/>
			</div>
			<div className="row">
				<div className="col-12">
					<p className="fw-bold fs-6 mb-0 pb-3">
						<FormattedMessage id="LOCATION.OPENING_HOURS.LAST_BOOKABLE" />
					</p>
					<p className="mb-0 secondary-text fs-7">
						<FormattedMessage id="LOCATION.OPENING_HOURS.INTERVAL_DURATION_TXT" />
					</p>
				</div>
			</div>
		</>
	);
};

export default LocationDayHoursDropdowns;
