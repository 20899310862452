import React, { useCallback, useState } from 'react';
import { Controller } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

import { BUSINESS_LANGUAGES } from '../../../../../common/constants';
import LanguageSelector from '../../../../../components/languageSelector/LanguageSelector';
import { chargeTypeOptions, ruleTypeOptions } from '../paymentsComponents/PaymentsOptions';
import { socialList } from './StoreOptions';

const Details = (props) => {
	const { register, control, watch, paymentData, isEditMode, defaultLocale } = props;
	const defaultLocaleIdx = BUSINESS_LANGUAGES.findIndex((language) => language?.code === defaultLocale);
	const [teamNotesLang, setTeamNotesLang] = useState(BUSINESS_LANGUAGES[defaultLocaleIdx ?? 0]?.code ?? 'el');
	const handleLanguageChange = (language) => setTeamNotesLang(language);
	const paymentRules = watch('payment_rules') ?? [];
	const paymentRulesTemp = watch('payment_rules_temp') ?? [];
	const rules = paymentRules.length > 0 ? paymentRules : paymentRulesTemp;
	const getIndex = useCallback(() => {
		return rules.length >= 1 ? rules.findIndex((i) => i?._id === paymentData?._id) ?? 0 : rules.length++ ?? 0;
	}, []);
	return (
		<>
			<div className="row">
				<div className="col-12">
					<div className="separator my-5" />
				</div>
			</div>

			<div className="row pb-5">
				<div className="col-12">
					<div className="mb-0 fs-2 fw-bold">
						<FormattedMessage id="LOCATION.DETAILS.HEADER" />
					</div>
				</div>
			</div>

			<div className="row">
				<div className="col-12 col-sm-6">
					<div className="p-3 generic-border">
						<LanguageSelector language={teamNotesLang} onLanguageChange={handleLanguageChange} />
						<div className="row pt-5">
							<div className="col-12">
								<div className="input-group">
									<label htmlFor="details-name" className="form-label pb-3 mb-0 fs-7 fw-bold">
										<FormattedMessage id="LOCATION.DETAILS.NAME" />
									</label>
									{isEditMode ? (
										<input
											type="text"
											className="form-control fs-6 fw-bold"
											id="details-name"
											name="details-name"
											aria-labelledby="details-name"
											placeholder="Please enter a name."
											{...register(`payment_rules[${getIndex()}].translations.${teamNotesLang}.title`)}
											value={watch(`payment_rules[${getIndex()}].translations.${teamNotesLang}.title`) ?? ''}
										/>
									) : (
										<input
											type="text"
											className="form-control fs-6 fw-bold"
											id="details-name"
											name="details-name"
											aria-labelledby="details-name"
											placeholder="Please enter a name."
											{...register(`payment_rules_temp[0].translations.${teamNotesLang}.title`)}
											value={watch(`payment_rules_temp[0].translations.${teamNotesLang}.title`) ?? ''}
										/>
									)}
								</div>
							</div>
						</div>
						<div className="row pt-5">
							<div className="col-12">
								<label htmlFor="details-terms" className="form-label pb-3 mb-0 fs-7 fw-bold">
									<FormattedMessage id="LOCATION.DETAILS.TERMS" />
								</label>
								{isEditMode ? (
									<textarea
										id="details-terms"
										name="details-terms"
										aria-labelledby="details-terms"
										className="full-width-textarea p-4 fs-6"
										rows="4"
										cols="50"
										placeholder="Please enter details."
										{...register(`payment_rules[${getIndex()}].translations.${teamNotesLang}.terms`)}
										value={watch(`payment_rules[${getIndex()}].translations.${teamNotesLang}.terms`) ?? ''}
									/>
								) : (
									<textarea
										id="details-terms"
										name="details-terms"
										aria-labelledby="details-terms"
										className="full-width-textarea p-4 fs-6"
										rows="4"
										cols="50"
										placeholder="Please enter details."
										{...register(`payment_rules_temp[0].translations.${teamNotesLang}.terms`)}
										value={watch(`payment_rules_temp[0].translations.${teamNotesLang}.terms`) ?? ''}
									/>
								)}
							</div>
						</div>
					</div>
				</div>
				<div className="col-12 col-sm-6">
					<div className="row pb-5 pt-5 pt-sm-0">
						<Controller
							name={isEditMode ? `payment_rules[${getIndex()}].rule_type` : 'payment_rules_temp[0].rule_type'}
							control={control}
							defaultValue={'per_booking'}
							render={({ field }) => (
								<div className="col-12">
									<div className="pb-3 fs-7 fw-bold">
										<FormattedMessage id="LOCATION.DETAILS.RULE_TYPE" />
									</div>
									{isEditMode ? (
										<div className="select-container">
											<select
												className="fs-6 fw-bold text-capitalize disabled"
												aria-label="Select example"
												{...register(`payment_rules[${getIndex()}].rule_type`)}
												disabled>
												{ruleTypeOptions.map((ruleTypeOption) => (
													<option
														key={ruleTypeOption.value}
														value={ruleTypeOption.value}
														defaultValue={ruleTypeOption.value}>
														{ruleTypeOption.label}
													</option>
												))}
											</select>
											<i className="fa-solid fa-chevron-down arrow-icon"></i>
										</div>
									) : (
										<div className="select-container">
											<select
												className="fs-6 fw-bold text-capitalize disabled"
												aria-label="Select example"
												{...register('payment_rules_temp[0].rule_type')}
												disabled>
												{ruleTypeOptions.map((ruleTypeOption) => (
													<option
														key={ruleTypeOption.value}
														value={ruleTypeOption.value}
														defaultValue={ruleTypeOption.value}>
														{ruleTypeOption.label}
													</option>
												))}
											</select>
											<i className="fa-solid fa-chevron-down arrow-icon"></i>
										</div>
									)}
								</div>
							)}
						/>
					</div>
					<div className="row pb-5">
						<Controller
							name={isEditMode ? `payment_rules[${getIndex()}].charge_type` : 'payment_rules_temp[0].charge_type'}
							defaultValue={'pre_payment'}
							control={control}
							render={({ field }) => (
								<div className="col-12">
									<div className="pb-3 fs-7 fw-bold">
										<FormattedMessage id="LOCATION.DETAILS.CHARGE_TYPE" />
									</div>
									{isEditMode ? (
										<div className="select-container">
											<select
												className="fs-6 fw-bold text-capitalize disabled"
												aria-label="Select example"
												{...register(`payment_rules[${getIndex()}].charge_type`)}
												disabled>
												{chargeTypeOptions.map((chargeTypeOption) => (
													<option
														key={chargeTypeOption.value}
														value={chargeTypeOption.value}
														defaultValue={chargeTypeOption.value}>
														{chargeTypeOption.label}
													</option>
												))}
											</select>
											<i className="fa-solid fa-chevron-down arrow-icon"></i>
										</div>
									) : (
										<div className="select-container">
											<select
												className="fs-6 fw-bold text-capitalize disabled"
												aria-label="Select example"
												{...register('payment_rules_temp[0].charge_type')}
												disabled>
												{chargeTypeOptions.map((chargeTypeOption) => (
													<option
														key={chargeTypeOption.value}
														value={chargeTypeOption.value}
														defaultValue={chargeTypeOption.value}>
														{chargeTypeOption.label}
													</option>
												))}
											</select>
											<i className="fa-solid fa-chevron-down arrow-icon"></i>
										</div>
									)}
								</div>
							)}
						/>
					</div>
					{/*<div className="row">*/}
					{/*	<Controller*/}
					{/*		name="selectedAutoCancelOption"*/}
					{/*		control={control}*/}
					{/*		render={({ field }) => (*/}
					{/*			<div className="col-12">*/}
					{/*				<div className="pb-3 fs-7 fw-bold">*/}
					{/*					<FormattedMessage id="LOCATION.DETAILS.AUTOCANCEL_PERIOD" />*/}
					{/*				</div>*/}
					{/*				<div*/}
					{/*					className={clsx('custom-select', {*/}
					{/*						open: openDropdown === 'autoCancel',*/}
					{/*					})}>*/}
					{/*					<div className="select-wrapper">*/}
					{/*						<div className="select-header p-4" onClick={() => toggleDropdown('autoCancel')} ref={autoCancelRef}>*/}
					{/*							<span className="selected-option fs-6 fw-bold">{field.value || autoCancelOptions[0].label}</span>*/}
					{/*							<i className="fa-solid fa-chevron-down arrow-icon" />*/}
					{/*						</div>*/}
					{/*						<ul*/}
					{/*							className={clsx('select-options fs-6 fw-bold', {*/}
					{/*								'd-none': openDropdown !== 'autoCancel',*/}
					{/*							})}>*/}
					{/*							{autoCancelOptions.map((autoCancelOption) => (*/}
					{/*								<li*/}
					{/*									key={autoCancelOption.value}*/}
					{/*									className={clsx('option', {*/}
					{/*										active: field.value === autoCancelOption.label,*/}
					{/*									})}*/}
					{/*									onClick={() => {*/}
					{/*										field.onChange(autoCancelOption.label);*/}
					{/*										setOpenDropdown('');*/}
					{/*									}}>*/}
					{/*									{autoCancelOption.label}*/}
					{/*								</li>*/}
					{/*							))}*/}
					{/*						</ul>*/}
					{/*					</div>*/}
					{/*				</div>*/}
					{/*			</div>*/}
					{/*		)}*/}
					{/*	/>*/}
					{/*</div>*/}
				</div>
			</div>

			<div className="row">
				<div className="col-12">
					<div className="separator my-5" />
				</div>
			</div>
		</>
	);
};

export default Details;
