import { DevTool } from '@hookform/devtools';
import { yupResolver } from '@hookform/resolvers/yup';
import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link, useNavigate } from 'react-router-dom';
import { getSessionStorage } from 'src/common/storage';
import { scrollOnError } from 'src/helpers/utils';
import { useRegisterMutation } from 'src/reducers/auth/api';
import { devMode } from 'src/reducers/services/store';

import { RegistrationFormInputs, registrationSchema } from '../schema';

export const Registration = () => {
	const intl = useIntl();
	const navigate = useNavigate();

	const selectedENLang = getSessionStorage('language') === 'en';

	const [showPassword, setShowPassword] = useState(false);
	const [showConfirmPassword, setShowConfirmPassword] = useState(false);
	const togglePasswordVisibility = () => setShowPassword((prev) => !prev);
	const toggleConfirmPasswordVisibility = () => setShowConfirmPassword((prev) => !prev);

	const {
		reset,
		control,
		register,
		handleSubmit,
		formState: { errors, isValid, isSubmitting, touchedFields },
	} = useForm<RegistrationFormInputs>({
		mode: 'all',
		resolver: yupResolver(registrationSchema(intl)),
	});

	const [registration, { isSuccess, isLoading, isError }] = useRegisterMutation();

	const onSubmit: SubmitHandler<RegistrationFormInputs> = (formData) => {
		const inputs = { body: formData };
		registration(inputs);
	};

	useEffect(() => {
		if (isSuccess) {
			reset();
			setTimeout(() => {
				navigate('/auth/login');
			}, 5000);
		}
	}, [isSuccess, navigate, reset]);

	return (
		<>
			<div className="card card-custom overlay min-h-125px shadow-none">
				<div className="card-body d-flex flex-column p-10">
					<form
						className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework"
						noValidate
						id="kt_login_signup_form"
						onSubmit={handleSubmit(onSubmit, scrollOnError)}
					>
						<div className="text-center mb-11">
							<h1 className="text-dark fw-bolder mb-3">
								<FormattedMessage id="AUTH.REGISTER.TITLE" />
							</h1>
						</div>

						{isError && (
							<div className="mb-lg-10 alert alert-danger">
								<div className="alert-text fw-bold">
									<FormattedMessage id="AUTH.REGISTER.INCORRECT.DETAILS" />
								</div>
							</div>
						)}

						<div className="fv-row mb-8">
							<label className="form-label fw-bolder text-dark fs-6">
								<FormattedMessage id="AUTH.INPUT.FNAME" />
							</label>
							<input
								type="text"
								placeholder={intl.formatMessage({ id: 'AUTH.INPUT.FNAME' })}
								autoComplete="off"
								{...register('fname')}
								className={clsx(
									'form-control bg-transparent',
									{ 'is-invalid': touchedFields.fname && errors.fname },
									{ 'is-valid': touchedFields.fname && !errors.fname }
								)}
							/>
							{errors.fname && (
								<div className="fv-plugins-message-container">
									<div className="fv-help-block">
										<span role="alert">{errors.fname.message}</span>
									</div>
								</div>
							)}
						</div>

						<div className="fv-row mb-8">
							<label className="form-label fw-bolder text-dark fs-6">
								<FormattedMessage id="AUTH.INPUT.LNAME" />
							</label>
							<input
								type="text"
								placeholder={intl.formatMessage({ id: 'AUTH.INPUT.LNAME' })}
								autoComplete="off"
								{...register('lname')}
								className={clsx(
									'form-control bg-transparent',
									{ 'is-invalid': touchedFields.lname && errors.lname },
									{ 'is-valid': touchedFields.lname && !errors.lname }
								)}
							/>
							{errors.lname && (
								<div className="fv-plugins-message-container">
									<div className="fv-help-block">
										<span role="alert">{errors.lname.message}</span>
									</div>
								</div>
							)}
						</div>

						<div className="fv-row mb-8">
							<label className="form-label fw-bolder text-dark fs-6">
								<FormattedMessage id="BUSINESS.MODAL.BUSINESS_NAME" />
							</label>
							<input
								type="text"
								placeholder={intl.formatMessage({ id: 'BUSINESS.MODAL.BUSINESS_NAME' })}
								autoComplete="off"
								{...register('business_title')}
								className={clsx(
									'form-control bg-transparent',
									{ 'is-invalid': touchedFields.business_title && errors.business_title },
									{ 'is-valid': touchedFields.business_title && !errors.business_title }
								)}
							/>
							{errors.business_title && (
								<div className="fv-plugins-message-container">
									<div className="fv-help-block">
										<span role="alert">{errors.business_title.message}</span>
									</div>
								</div>
							)}
						</div>

						<div className="fv-row mb-8">
							<label className="form-label fw-bolder text-dark fs-6">
								<FormattedMessage id="AUTH.INPUT.EMAIL" />
							</label>
							<input
								type="email"
								placeholder={intl.formatMessage({ id: 'AUTH.INPUT.EMAIL' })}
								autoComplete="off"
								{...register('username')}
								className={clsx(
									'form-control bg-transparent',
									{ 'is-invalid': touchedFields.username && errors.username },
									{ 'is-valid': touchedFields.username && !errors.username }
								)}
							/>
							{errors.username && (
								<div className="fv-plugins-message-container">
									<div className="fv-help-block">
										<span role="alert">{errors.username.message}</span>
									</div>
								</div>
							)}
						</div>

						<div className="fv-row mb-8">
							<label className="form-label fw-bolder text-dark fs-6">
								<FormattedMessage id="AUTH.INPUT.PASSWORD" />
							</label>
							<div className="position-relative">
								<input
									type={showPassword ? 'text' : 'password'}
									autoComplete="off"
									placeholder="********"
									{...register('password')}
									className={clsx(
										'form-control bg-transparent pe-5',
										{ 'is-invalid': touchedFields.password && errors.password },
										{ 'is-valid': touchedFields.password && !errors.password }
									)}
								/>
								<button
									type="button"
									className={clsx('btn position-absolute end-0 top-50 translate-middle-y', {
										'me-4': (touchedFields.password && errors.password) || (touchedFields.password && !errors.password),
									})}
									onClick={togglePasswordVisibility}
								>
									<i className={showPassword ? 'far fa-eye' : 'far fa-eye-slash'} />
								</button>
							</div>
							{errors.password && (
								<div className="fv-plugins-message-container">
									<div className="fv-help-block">
										<span role="alert">{errors.password.message}</span>
									</div>
								</div>
							)}
						</div>

						<div className="fv-row mb-8">
							<label className="form-label fw-bolder text-dark fs-6">
								<FormattedMessage id="AUTH.INPUT.CONFIRM_PASSWORD" />
							</label>
							<div className="position-relative">
								<input
									type={showConfirmPassword ? 'text' : 'password'}
									autoComplete="off"
									placeholder="********"
									{...register('confirm_password')}
									className={clsx(
										'form-control bg-transparent',
										{ 'is-invalid': touchedFields.confirm_password && errors.confirm_password },
										{ 'is-valid': touchedFields.confirm_password && !errors.confirm_password }
									)}
								/>
								<button
									type="button"
									className={clsx('btn position-absolute end-0 top-50 translate-middle-y', {
										'me-4': (touchedFields.password && errors.password) || (touchedFields.password && !errors.password),
									})}
									onClick={toggleConfirmPasswordVisibility}
								>
									<i className={showConfirmPassword ? 'far fa-eye' : 'far fa-eye-slash'} />
								</button>
							</div>
							{errors.confirm_password && (
								<div className="fv-plugins-message-container">
									<div className="fv-help-block">
										<span role="alert">{errors.confirm_password.message}</span>
									</div>
								</div>
							)}
						</div>

						<div className="fv-row mb-8">
							<label className="form-check form-check-inline" htmlFor="kt_login_toc_agree">
								<input type="checkbox" id="kt_login_toc_agree" {...register('accept_terms')} className="form-check-input" />
								<span>
									{intl.formatMessage({
										id: 'AUTH.INPUT.I_ACCEPT_THE',
										defaultMessage: selectedENLang ? 'I have read and accept the' : 'Έχω διαβάσει και αποδέχομαι',
									})}
									<a
										href={
											selectedENLang
												? 'https://tabol.io/assets/files/terms-of-use-en.pdf'
												: 'https://tabol.io/assets/files/terms-of-use-gr.pdf'
										}
										target="_blank"
										rel="noreferrer"
										className="ms-1 link-primary fw-bold"
									>
										{intl.formatMessage({
											id: 'AUTH.INPUT.TERMS',
											defaultMessage: selectedENLang ? 'Terms of Use' : 'Όροι Χρήσης',
										})}
									</a>
									{' &'}
									<a
										href={
											selectedENLang
												? 'https://tabol.io/assets/files/privacy-policy-en.pdf'
												: 'https://tabol.io/assets/files/privacy-policy-gr.pdf'
										}
										target="_blank"
										rel="noreferrer"
										className="ms-1 link-primary fw-bold"
									>
										{intl.formatMessage({
											id: 'AUTH.INPUT.TERMS',
											defaultMessage: selectedENLang ? 'Privacy Policy' : 'Πολιτική Απορρήτου',
										})}
									</a>
								</span>
							</label>

							{errors.accept_terms && (
								<div className="fv-plugins-message-container">
									<div className="fv-help-block">
										<span role="alert">{errors.accept_terms.message}</span>
									</div>
								</div>
							)}
						</div>

						{isSuccess && (
							<div className="mb-lg-10 alert alert-success">
								<div className="succes-text fw-bold">
									<FormattedMessage id="AUTH.INPUT.SUCCESS" />
								</div>
							</div>
						)}

						<div className="text-center">
							<button
								type="submit"
								id="kt_sign_up_submit"
								className="btn btn-lg btn-primary w-100 mb-5"
								disabled={isSubmitting || !isValid}
							>
								{isLoading ? (
									<span className="indicator-progress" style={{ display: 'block' }}>
										<FormattedMessage id="AUTH.LOGIN.LOADING" />
										<span className="spinner-border spinner-border-sm align-middle ms-2"></span>
									</span>
								) : (
									<span className="indicator-label">
										<FormattedMessage id="AUTH.GENERAL.SUBMIT_BUTTON" />
									</span>
								)}
							</button>
							<Link to="/auth/login">
								<button type="button" className="generic-button w-100 fw-bold me-3 me-sm-5 fs-5">
									<FormattedMessage id="GENERAL.CANCEL" />
								</button>
							</Link>
						</div>
					</form>
				</div>
			</div>
			{devMode && <DevTool control={control} />}
		</>
	);
};
