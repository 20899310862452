import React, { useState } from 'react';
import { Controller } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

import { BUSINESS_LANGUAGES } from '../../../../../common/constants';
import LanguageSelector from '../../../../../components/languageSelector/LanguageSelector';
import { bookingOptions } from './openingHoursOptions';

const LocationBookingGuestsDropdown = (props) => {
	const { day, register, control, watch, defaultLocale } = props;
	const defaultLocaleIdx = BUSINESS_LANGUAGES.findIndex((language) => language?.code === defaultLocale);
	const [openingHoursLang, setOpeningHoursLang] = useState(BUSINESS_LANGUAGES[defaultLocaleIdx ?? 0]?.code ?? 'el');
	const handleLanguageChange = (language) => setOpeningHoursLang(language);
	const translations = BUSINESS_LANGUAGES.reduce((acc, lang) => {
		acc[lang?.code] = {
			notes: watch(`opening_hours_temp[${day}].translations[${lang?.code}].notes`),
		};
		return acc;
	}, {});

	return (
		<>
			<div className="row">
				<div className="col-12">
					<div className="separator my-5" />
				</div>
			</div>
			<div className="p-3 generic-border">
				<LanguageSelector language={openingHoursLang} onLanguageChange={handleLanguageChange} />
				<div className="row pt-5">
					<div className="col-12">
						<label htmlFor="opening-hours-notes" className="form-label pb-3 mb-0 fs-7 fw-bold">
							<FormattedMessage id="LOCATION.OPENING_HOURS.NOTES" />
						</label>
						<textarea
							id="opening-hours-notes"
							name="opening-hours-notes"
							aria-labelledby="opening-hours-notes"
							className="full-width-textarea fs-6 p-4"
							rows="4"
							cols="50"
							{...register(`opening_hours_temp[${day}].translations.${openingHoursLang}.notes` ?? `opening_hours_temp[${day}].notes`)}
							value={watch(`opening_hours_temp[${day}].translations.${openingHoursLang}.notes`) ?? ''}
						/>
						<p className="mb-0 secondary-text fs-7 pt-2">
							<FormattedMessage id="LOCATION.OPENING_HOURS.NOTES_TXT" />
						</p>
					</div>
				</div>
			</div>

			<div className="row">
				<div className="col-12">
					<div className="separator my-5" />
				</div>
			</div>

			<div className="row pb-5">
				<Controller
					name={`opening_hours_temp[${day}].max_bookings`}
					control={control}
					render={({ field }) => (
						<div className="col-12 col-sm-6">
							<div className="pb-3 fs-7 fw-bold">
								<FormattedMessage id="LOCATION.OPENING_HOURS.MAX_BOOKINGS" />
							</div>
							<div className="select-container">
								<select
									className="fs-6 fw-bold text-capitalize"
									aria-label="Select example"
									{...register(`opening_hours_temp[${day}].max_bookings`)}
								>
									{bookingOptions.map((bookingOption) => (
										<option key={bookingOption.value} value={bookingOption.value} defaultValue={bookingOption.value}>
											{bookingOption.label}
										</option>
									))}
								</select>
								<i className="fa-solid fa-chevron-down arrow-icon"></i>
							</div>
						</div>
					)}
				/>
				<Controller
					name={`opening_hours_temp[${day}].max_guests`}
					control={control}
					render={({ field }) => (
						<div className="col-12 col-sm-6">
							<div className="pb-3 pt-5 pt-sm-0 fs-7 fw-bold">
								<FormattedMessage id="LOCATION.OPENING_HOURS.MAX_GUESTS" />
							</div>
							<div className="select-container">
								<select
									className="fs-6 fw-bold text-capitalize"
									aria-label="Select example"
									{...register(`opening_hours_temp[${day}].max_guests`)}
								>
									{bookingOptions.map((maxGuestsOption) => (
										<option key={maxGuestsOption.value} value={maxGuestsOption.value} defaultValue={maxGuestsOption.value}>
											{maxGuestsOption.label}
										</option>
									))}
								</select>
								<i className="fa-solid fa-chevron-down arrow-icon"></i>
							</div>
						</div>
					)}
				/>
				<div className="col-12">
					<p className="mb-0 secondary-text fs-7 pt-2">
						<FormattedMessage id="LOCATION.OPENING_HOURS.MAX_GUESTS_TXT" />
					</p>
				</div>
			</div>

			<div className="row pb-5">
				<Controller
					name={`opening_hours_temp[${day}].max_interval_bookings`}
					control={control}
					render={({ field }) => (
						<div className="col-12 col-sm-6">
							<div className="pb-3 fs-7 fw-bold">
								<FormattedMessage id="LOCATION.OPENING_HOURS.MAX_BOOKINGS_INTERVAL" />
							</div>
							<div className="select-container">
								<select
									className="fs-6 fw-bold text-capitalize"
									aria-label="Select example"
									{...register(`opening_hours_temp[${day}].max_interval_bookings`)}
								>
									{bookingOptions.map((bookingOption) => (
										<option key={bookingOption.value} value={bookingOption.value} defaultValue={bookingOption.value}>
											{bookingOption.label}
										</option>
									))}
								</select>
								<i className="fa-solid fa-chevron-down arrow-icon"></i>
							</div>
						</div>
					)}
				/>
				<Controller
					name={`opening_hours_temp[${day}].max_interval_guests`}
					control={control}
					render={({ field }) => (
						<div className="col-12 col-sm-6">
							<div className="pb-3 pt-5 pt-sm-0 fs-7 fw-bold">
								<FormattedMessage id="LOCATION.OPENING_HOURS.MAX_GUESTS_INTERVAL" />
							</div>
							<div className="select-container">
								<select
									className="fs-6 fw-bold text-capitalize"
									aria-label="Select example"
									{...register(`opening_hours_temp[${day}].max_interval_guests`)}
								>
									{bookingOptions.map((maxGuestsOption) => (
										<option key={maxGuestsOption.value} value={maxGuestsOption.value} defaultValue={maxGuestsOption.value}>
											{maxGuestsOption.label}
										</option>
									))}
								</select>
								<i className="fa-solid fa-chevron-down arrow-icon"></i>
							</div>
						</div>
					)}
				/>
				<div className="col-12">
					<p className="mb-0 secondary-text fs-7 pt-2">
						<FormattedMessage id="LOCATION.OPENING_HOURS.MAX_GUESTS_INTERVAL_TXT" />
					</p>
				</div>
			</div>

			<div className="row pb-5">
				<div className="col-12">
					<div className="form-check form-switch form-check-custom form-check-solid">
						<input
							className="form-check-input"
							type="checkbox"
							id="counOnlineBookings"
							name="counOnlineBookings"
							aria-labelledby="counOnlineBookings"
							onChange={() => {}}
							{...register(`opening_hours_temp[${day}].online_only_limits`)}
						/>
						<label className="form-check-label secondary-text fs-7 fw -bold" htmlFor="count-online-bookings">
							<FormattedMessage id="LOCATION.OPENING_HOURS.COUNT_ONLINE_BOOKINGS" />
						</label>
					</div>
					<p className="mb-0 secondary-text fs-7 pt-2">
						<FormattedMessage id="LOCATION.OPENING_HOURS.COUNT_ONLINE_BOOKINGS_TXT" />
					</p>
				</div>
			</div>
		</>
	);
};

export default LocationBookingGuestsDropdown;
