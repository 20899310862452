import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import useConfirmationModal from 'src/components/modal/useConfirmationModal';
import Table from 'src/components/table/Table';

const GuestsTable = ({ onEditGuest, onDeleteGuest }) => {
	const [confirmAction, confirmationModal] = useConfirmationModal();

	return (
		<div className="mb-5 mb-xl-8">
			<div className="card-body py-2 px-4">
				<Table>
					<Table.Column field="firstname">
						<FormattedMessage id="RESERVATION.GUEST_TABLE.FIRSTNAME" />
					</Table.Column>
					<Table.Column field="lastname">
						<FormattedMessage id="RESERVATION.GUEST_TABLE.LASTNAME" />
					</Table.Column>
					<Table.Column field="email">
						<FormattedMessage id="RESERVATION.GUEST_TABLE.EMAIL" />
					</Table.Column>
					<Table.Column field="phoneNumber">
						<FormattedMessage id="RESERVATION.GUEST_TABLE.PHONE" />
					</Table.Column>
					<Table.Column
						field="action"
						body={(row) => {
							return (
								<Dropdown>
									<Dropdown.Toggle variant="light" size="sm" id="store_actions_dropdown">
										<i className="bi bi-three-dots-vertical fs-4" />
									</Dropdown.Toggle>
									<Dropdown.Menu
										renderOnMount
										popperConfig={{
											strategy: 'fixed',
											modifiers: [
												{
													name: 'offset',
													options: {
														offset: [-15, -10],
													},
												},
											],
										}}
									>
										<Dropdown.Item
											className="btn btn-sm primary-text"
											key={`${row.id}-edit`}
											icon="/media/icons/duotune/art/art005.svg"
											onClick={() => onEditGuest(row.id)}
										>
											<FormattedMessage id="GENERAL.EDIT" />
										</Dropdown.Item>
										<Dropdown.Item
											className="btn btn-sm error-text"
											key={`${row.id}-delete`}
											onClick={() => confirmAction(() => onDeleteGuest(row.id))}
										>
											<FormattedMessage id="GENERAL.DELETE" />
										</Dropdown.Item>
									</Dropdown.Menu>
								</Dropdown>
							);
						}}
					></Table.Column>
				</Table>
			</div>
			{confirmationModal}
		</div>
	);
};

export default GuestsTable;
