import * as Yup from 'yup';

import { SOCIAL } from '../../../../../common/constants';
import { capitalizeFirstLetter } from '../../../../../components/utils/Capitilizer';

export const optionsCurrency = [
	{ value: 'EUR, Euro (€)', label: 'EUR, Euro (€)' },
	{ value: 'USA, Dollar ($)', label: 'USA, Dollar ($)' },
	{ value: 'UK, Pound (£)', label: 'UK, Pound (£)' },
];

export const optionsTimezone = [
	{ value: 'Europe/Athens', label: 'Europe/Athens' },
	{ value: 'Europe/Paris', label: 'Europe/Paris' },
	{ value: 'US/California', label: 'US/California' },
];

export const optionsTags = [
	{ value: 'Prepayment', label: 'Prepayment' },
	{ value: 'Tabol', label: 'Tabol' },
];

export const optionsCategory = [
	{ value: 'restaurant', label: 'Restaurant' },
	{ value: 'bar', label: 'Bar' },
	{ value: 'club', label: 'Club' },
	{ value: 'diner', label: 'Diner' },
];

export const optionsPayment = [
	{ value: 'Cash', label: 'Cash' },
	{ value: 'Debit Card', label: 'Debit Card' },
	{ value: 'Paypal', label: 'Paypal' },
];

export const optionsCuisine = [
	{ value: 'Italian', label: 'Italian' },
	{ value: 'Pizza', label: 'Pizza' },
];

export const optionsSuggested = [
	{ value: 'Families', label: 'Families' },
	{ value: 'Business Meetings', label: 'Business Meetings' },
	{ value: 'Couples', label: 'Couples' },
];

export const optionsServices = [
	{ value: 'Wifi', label: 'Wifi' },
	{ value: 'Live Music', label: 'Live Music' },
	{ value: 'Personal Waiter', label: 'Personal Waiter' },
	{ value: 'Cocktail Bar', label: 'Cocktail Bar' },
];

export const optionsEnvironment = [
	{ value: 'with_garden', label: 'With Garden' },
	{ value: 'with_pool', label: 'With Pool' },
	{ value: 'seaside', label: 'Seaside' },
];

export const optionsPreferences = [
	{ value: 'indoor', label: 'Indoor' },
	{ value: 'outdoor', label: 'Outdoor' },
	{ value: 'smoker', label: 'Smoker' },
	{ value: 'nonsmoker', label: 'Non - Smoker' },
	{ value: 'vegan', label: 'Vegan' },
	{ value: 'table_inside', label: 'Table inside' },
	{ value: 'table_outside', label: 'Table outside' },
	{ value: 'booth', label: 'Booth view' },
	{ value: 'baby_chair', label: 'Baby chair' },
	{ value: 'wheelchair_access', label: 'Wheelchair accessibility' },
	{ value: 'childcare', label: 'Childcare Services' },
];

export const optionsCancellationPolicy = [
	{ value: '0', label: 'Always full refund' },
	{ value: '1', label: '1 hour' },
	...Array.from({ length: 22 }, (_, i = 2) => ({
		value: `${i + 2}`,
		label: `${i + 2} hours`,
	})),
	{ label: '1 day (24 hours)', value: '24' },
	{ label: '2 days (48 hours)', value: '48' },
	{ label: '3 days (72 hours)', value: '72' },
	{ label: '4 days (96 hours)', value: '96' },
	{ label: '5 days (120 hours)', value: '120' },
	{ label: '6 days (144 hours)', value: '144' },
	{ label: '7 days (168 hours)', value: '168' },
	{ label: 'Non refundable', value: '-1' },
];

export const socialList = ['Fax', 'Web', 'Facebook', 'YouTube', 'Instagram', 'LinkeIn', 'X', 'Tik Tok', 'Tripadvisor', 'Google Business Profile'].map(
	(social) => ({
		type: social,
		value: social.toLowerCase().replace(/\s+/g, '_'),
	})
);
