import prepareURL from 'src/common/prepareURL';
import { getSessionStorage } from 'src/common/storage';
import { injectTags, injectTagsOpt } from 'src/pages/analytics/tag/api/tagApiSlice';
import apiSlice from 'src/reducers/services/api';

import { setAvailabilityState } from './availabilitySlice';

const basePath = '/reservations';

const reservationApiSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		reservation: builder.query({
			query: (args) => prepareURL(basePath, args),
			providesTags: [{ type: 'Reservation' }],
			async transformResponse(response, meta, args) {
				if (!args?.query?.sort) {
					response.result = response?.result.sort((a, b) => new Date(b.date) - new Date(a.date));
					try {
						let x = await injectTags({ items: response.result, api: response._api, exterOptions: response._exterOptions });
					} catch (error) {
						return response;
					}
					return response;
				}
				return response;
			},
		}),
		getReservation: builder.query({
			query: (args) => ({
				method: 'GET',
				url: prepareURL(`${basePath}/${args.path.id}`, args),
			}),
			providesTags: ['ReservationDetails'],
		}),
		getStoreAvailability: builder.query({
			query: (args) => {
				const sid = JSON.parse(getSessionStorage('act_str'))?._id;
				return prepareURL(
					basePath +
						`/store/${args.path.id ?? sid}/availability?people=${args.people ?? 2}${
							args.reservationId ? `&reservation_id=${args?.reservationId}&` : '&'
						}date=${args.date ?? new Date()}&source=${args.source ?? 'walk-in'}${args.time ? `&time=${args.time}&` : '&'}duration_mins=${
							args.duration ?? 120
						}`,
					args
				);
			},
			async onQueryStarted(arg, { dispatch, queryFulfilled }) {
				try {
					const { data } = await queryFulfilled;
					dispatch(setAvailabilityState(data));
				} catch (err) {
					// `onError`
				}
			},
		}),
		createReservation: builder.mutation({
			query: (args) => ({
				url: basePath,
				method: 'POST',
				body: args.body,
			}),
			invalidatesTags: [{ type: 'Reservation' }],
		}),
		editReservation: builder.mutation({
			query: (args) => ({
				url: prepareURL(`${basePath}/${args.path.id}`, args),
				method: 'PUT',
				body: args.body,
			}),
			invalidatesTags: [{ type: 'Reservation' }, 'ReservationDetails'],
		}),
		deleteReservation: builder.mutation({
			query: (args) => ({
				url: prepareURL(`${basePath}/${args.path.id}`, args),
				method: 'DELETE',
			}),
			invalidatesTags: [{ type: 'Reservation' }, 'ReservationDetails'],
		}),
		refundPayment: builder.mutation({
			query: (args) => ({
				url: prepareURL(`${basePath}/${args.path.id}/refund-payments`, args),
				method: 'PUT',
				body: args.body,
			}),
			invalidatesTags: [{ type: 'Reservation' }, 'ReservationDetails'],
		}),
	}),
});

export const {
	slice: reservationSlice,
	useReservationQuery,
	useGetReservationQuery,
	useGetStoreAvailabilityQuery,
	useCreateReservationMutation,
	useEditReservationMutation,
	useDeleteReservationMutation,
	useRefundPaymentMutation,
} = reservationApiSlice;
export default reservationApiSlice;
