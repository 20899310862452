import { getTimeIntervalOptions } from '../../utils/options';

export const managingTablesOptions = [
	{ value: 'manual', label: 'LOCATION.SETTINGS.MANUALLY_ADD_TABLES' },
	{ value: 'auto', label: 'LOCATION.SETTINGS.AUTOMATICALLY_ADD_TABLES' },
];

export const acceptingBookingOptions = [
	{ value: 'manual', label: 'LOCATION.SETTINGS.MANUALLY_ACCEPT_BOOKINGS' },
	{ value: 'auto', label: 'LOCATION.SETTINGS.AUTOMATICALLY_ACCEPT_BOOKINGS' },
];

export const bookingPeopleOnlineOptions = Array.from({ length: 100 }, (_, i) => ({
	value: (i + 1).toString(),
	label: (i + 1).toString(),
}));

export const timeIntervalOptions = getTimeIntervalOptions('00:30', '06:00', 5);
export const defaultDurationOptions = getTimeIntervalOptions('00:30', '12:00', 30);
export const minTimeInAdvanceOptions = [
	{ value: '0', label: 'No restrictions' },
	...getTimeIntervalOptions('00:30', '23:30', 30),
	{ value: '1440', label: '24:00' },
	{ value: '2880', label: '48:00' },
];

export const maxTimeInAdvanceOptions = [
	{ value: '7', label: '1 week' },
	{ value: '14', label: '2 weeks' },
	{ value: '21', label: '3 weeks' },
	{ value: '30', label: '1 month' },
	...Array.from({ length: 11 }, (_, i = 2) => ({
		value: `${(i + 2) * 30}`,
		label: `${i + 2} months`,
	})),
];

export const maxSimilarBookingsOptions = [
	{ value: '0', label: 'Disabled ' },
	{ value: '1', label: '1 (only unique bookings) ' },
	...Array.from({ length: 9 }, (_, i) => ({
		value: `${i + 2}`,
		label: `${i + 2}`,
	})),
	{ value: '15', label: '15 ' },
	{ value: '20', label: '20 ' },
	{ value: '25', label: '25 ' },
	{ value: '50', label: '50' },
	{ value: '100', label: '100' },
	{ value: '200', label: '200' },
	{ value: '500', label: '500' },
	{ value: '1000', label: '1000' },
];

export const paymentRuleSelectOptions = [{ value: 'Select', label: 'Select' }];
