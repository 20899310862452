import { ErrorMessage } from '@hookform/error-message';
import React from 'react';
import { Control, Controller } from 'react-hook-form';

import { KTSVG } from '../../_metronic/helpers';
import File, { MediaItem, MediaResponse } from './File';

type Props = {
	control: Control<any>;
	name: string;
	disabled?: boolean;
	errors?: any;
	limit?: number;
	allowedTypes?: string[];
	onUpload?: (media: MediaResponse[]) => void;
	onRemove?: (media: MediaItem) => void;
};

const FileControl: React.FC<Props> = ({
	control,
	name,
	limit = 1,
	allowedTypes = ['image/jpg', 'image/jpeg', 'image/png', 'application/pdf'],
	disabled = false,
	errors = {},
}) => {
	return (
		<Controller
			name={name}
			control={control}
			defaultValue={[]}
			render={({ field: { value, onChange } }) => {
				return (
					<>
						<File
							disabled={disabled}
							files={value}
							limit={limit}
							allowedTypes={allowedTypes}
							onRemove={(media) => {
								value = value.filter(({ _id }) => _id !== media._id);
								onChange(value);
							}}
							onUpload={(media) => {
								value.push(media);
								onChange(value);
							}}
						/>
						{errors && (
							<ErrorMessage
								errors={errors}
								name={name}
								render={({ message }) => (
									<div className="fv-plugins-message-container">
										<div className="fv-help-block">
											<span role="alert">{message}</span>
										</div>
									</div>
								)}
							/>
						)}
					</>
				);
			}}
		/>
	);
};

export default FileControl;
