export enum HostViewEnum {
	Events = 'events',
	WaitList = 'waitlist',
	Reservation = 'reservations',
}

export enum EventTypeEnum {
	Seated = 'Seated',
	Upcoming = 'Upcoming',
}
export const getEventTypeLabel = (eventType: EventTypeEnum, intl: any): string => {
	switch (eventType) {
		case EventTypeEnum.Seated:
			return intl.formatMessage({ id: 'HOSTVIEW.SIDEBAR.SEATED' });
		case EventTypeEnum.Upcoming:
			return intl.formatMessage({ id: 'HOSTVIEW.SIDEBAR.UPCOMING' });
		default:
			return eventType;
	}
};
export enum BtnClassEnum {
	Generic = 'generic-button',
	Primary = 'primary-button',
	Secondary = 'secondary-button',
	Delete = 'delete-button',
	White = 'white-button',
	Black = 'black-button',
	Gray = 'gray-button',
}

export enum StatusEnum {
	Available = 'Available', // NoShow, Pending, Rejected, Canceled, Completed, StoreCanceled
	Reserved = 'Reserved', // Confirmed, Editing,
	Occupied = 'Occupied', // Arrived
}
export const getStatusLabel = (status: StatusEnum, intl: any): string => {
	switch (status) {
		case StatusEnum.Available:
			return intl.formatMessage({ id: 'HOSTVIEW.OVERVIEW.AVAILABLE' });
		case StatusEnum.Reserved:
			return intl.formatMessage({ id: 'HOSTVIEW.OVERVIEW.RESERVED' });
		case StatusEnum.Occupied:
			return intl.formatMessage({ id: 'HOSTVIEW.OVERVIEW.OCCUPIED' });

		default:
			return status;
	}
};
export enum ResStatusEnum {
	Pending = 'pending',
	Confirmed = 'confirmed',
	Rejected = 'rejected',
	Arrived = 'arrived',
	Completed = 'completed',
	Canceled = 'canceled',
	NoShow = 'noshow',
	Editing = 'editing',
	StoreCanceled = 'store_canceled',
	ClientCanceled = 'client_canceled',
}

export const resStatusToHostViewStatusMap: { [key in ResStatusEnum]?: StatusEnum } = {
	[ResStatusEnum.NoShow]: StatusEnum.Available,
	[ResStatusEnum.Rejected]: StatusEnum.Available,
	[ResStatusEnum.Canceled]: StatusEnum.Available,
	[ResStatusEnum.Completed]: StatusEnum.Available,
	[ResStatusEnum.StoreCanceled]: StatusEnum.Available,
	[ResStatusEnum.Pending]: StatusEnum.Reserved,
	[ResStatusEnum.Editing]: StatusEnum.Reserved,
	[ResStatusEnum.Confirmed]: StatusEnum.Reserved,
	[ResStatusEnum.Arrived]: StatusEnum.Occupied,
};

export enum GeneralClassEnum {
	BG_White = 'bg-white',
	White_Text = 'white-text',
	White_Background = 'white-background',
	Green_Theme_Text = 'green-theme-text',
	Light_Green_Background = 'light-green-theme-background',
	Primary_Text = 'primary-text',
	Error_Text = 'error-message',
	Dark_Text = 'dark-text',
}

export enum InputType {
	Text = 'text',
	Password = 'password',
	Email = 'email',
	Number = 'number',
	Tel = 'tel',
	Url = 'url',
	Search = 'search',
	Date = 'date',
	DateTimeLocal = 'datetime-local',
	Month = 'month',
	Week = 'week',
	Time = 'time',
	Color = 'color',
	Checkbox = 'checkbox',
	Radio = 'radio',
	Range = 'range',
	File = 'file',
	Hidden = 'hidden',
	Button = 'button',
	Submit = 'submit',
	Reset = 'reset',
	Image = 'image',
}
