/* eslint-disable react/jsx-first-prop-new-line */
/* eslint-disable react/jsx-max-props-per-line */
import clsx from 'clsx';
import React, { useState } from 'react';
import { Controller } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

import { acceptingBookingOptions, managingTablesOptions } from './settingsOptions';

const OnlineBookingsSettings = (props) => {
	const { register, onFieldChange, openDropdown, setOpenDropdown, control, managingTables, acceptingOnline, toggleDropdown } = props;

	const [isDisabled, setDisabledState] = useState(false);

	return (
		<>
			<div className="row pb-5">
				<div className="col-12">
					<div className="mb-0 fs-2 fw-bold">
						<FormattedMessage id="LOCATION.SETTINGS.NEW_BOOKINGS" />
					</div>
				</div>
			</div>

			<div className="row pb-5">
				<Controller
					name="online_booking_settings.assign_tables"
					control={control}
					render={({ field }) => (
						<div className="col-12 col-sm-6">
							<div className="pb-3 fs-7 fw-bold">
								<FormattedMessage id="LOCATION.SETTINGS.MANAGING_TABLES" />
							</div>
							<div className="select-container">
								<select className="fs-6 fw-bold" aria-label="Select example" {...register('online_booking_settings.assign_tables')}>
									{managingTablesOptions.map((tableOption) => (
										<option key={tableOption.value} value={tableOption.value} defaultValue={tableOption.value}>
											<FormattedMessage id={tableOption.label} />
										</option>
									))}
								</select>
								<i className="fa-solid fa-chevron-down arrow-icon"></i>
							</div>
							<p className="mb-0 pt-2 secondary-text fs-7">
								<FormattedMessage id="LOCATION.SETTINGS.MANAGING_TABLES_TXT" />
							</p>
						</div>
					)}
				/>
				<Controller
					name="online_booking_settings.accept_bookings"
					control={control}
					render={({ field }) => (
						<div className="col-12 col-sm-6">
							<div className="pb-3 pt-5 pt-sm-0 fs-7 fw-bold">
								<FormattedMessage id="LOCATION.SETTINGS.ACCEPT_BOOKINGS" />
							</div>
							<div className="select-container">
								<select className="fs-6 fw-bold" aria-label="Select example" {...register('online_booking_settings.accept_bookings')}>
									{acceptingBookingOptions.map((acceptingOption) => (
										<option key={acceptingOption.value} value={acceptingOption.value} defaultValue={acceptingOption.value}>
											<FormattedMessage id={acceptingOption.label} />
										</option>
									))}
								</select>
								<i className="fa-solid fa-chevron-down arrow-icon"></i>
							</div>
							<p className="mb-0 pt-2 secondary-text fs-7">
								<FormattedMessage id="LOCATION.SETTINGS.ACCEPT_BOOKINGS_TXT" />
							</p>
						</div>
					)}
				/>
			</div>
			<div className="row">
				<div className="col-12">
					<div className="form-check form-switch form-check-custom form-check-solid">
						<input
							className="form-check-input"
							type="checkbox"
							onClick={() => setDisabledState(!isDisabled)}
							id="disable-booking-widget"
							name="disable-booking-widget"
							aria-labelledby="disable-booking-widget"
							{...register('online_booking_settings.disabled')}
						/>
						<label className="form-check-label secondary-text fs-6 fw-bold" htmlFor="disable-booking-widget">
							<FormattedMessage id="LOCATION.SETTINGS.DISABLE_BOOKING_PAGE" />
						</label>
					</div>
					<p className="mb-0 secondary-text fs-7 pt-2">
						<FormattedMessage id="LOCATION.SETTINGS.DISABLE_BOOKING_PAGE_TXT" />
					</p>
				</div>
			</div>

			<div className="row">
				<div className="col-12">
					<div className="separator my-5" />
				</div>
			</div>
		</>
	);
};

export default OnlineBookingsSettings;
