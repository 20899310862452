import { DevTool } from '@hookform/devtools';
import { yupResolver } from '@hookform/resolvers/yup';
import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { scrollOnError } from 'src/helpers/utils';
import { useResetPasswordMutation, useValidateTokenQuery } from 'src/reducers/auth/api';
import { devMode } from 'src/reducers/services/store';

import { ResetPasswordFormInputs, resetPasswordSchema } from '../schema';

export const ResetPassword = () => {
	const intl = useIntl();
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();

	const token = searchParams.get('token');

	const [showPassword, setShowPassword] = useState(false);
	const [showConfirmPassword, setShowConfirmPassword] = useState(false);
	const togglePasswordVisibility = () => setShowPassword((prev) => !prev);
	const toggleConfirmPasswordVisibility = () => setShowConfirmPassword((prev) => !prev);

	const {
		reset,
		control,
		register,
		handleSubmit,
		formState: { errors, isValid, isSubmitting, touchedFields },
	} = useForm<ResetPasswordFormInputs>({
		mode: 'all',
		resolver: yupResolver(resetPasswordSchema(intl)),
	});

	const [resetPassword, { isSuccess, isLoading, isError }] = useResetPasswordMutation();
	const { isSuccess: isValidatedSuccess, isError: isValidationError } = useValidateTokenQuery({ query: { token } }, { skip: !token });

	const onSubmit: SubmitHandler<ResetPasswordFormInputs> = (formData) => {
		const inputs = { body: { ...formData, token } };
		resetPassword(inputs);
	};

	useEffect(() => {
		if (isSuccess) {
			reset();
			setTimeout(() => {
				navigate('/auth/login');
			}, 5000);
		}
	}, [isSuccess, navigate, reset]);

	return (
		<>
			<div className="card card-custom overlay min-h-125px shadow-none">
				<div className="card-body d-flex flex-column p-10">
					<form
						id="kt_login_password_reset_form"
						noValidate
						onSubmit={handleSubmit(onSubmit, scrollOnError)}
						className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework"
					>
						<div className="text-center mb-10">
							<h1 className="text-dark fw-bolder mb-3">
								<FormattedMessage id="AUTH.FORGOT.TITLE" />
							</h1>
							<div className="text-gray-500 fw-semibold fs-6">
								<FormattedMessage id="AUTH.FORGOT.DESC" />
							</div>
						</div>

						{(isValidationError || !token) && (
							<div className="mb-lg-10 alert alert-danger">
								<div className="alert-text fw-bold">
									<FormattedMessage id="AUTH.RESET.INVALID_TOKEN" defaultMessage="The reset token is invalid or expired." />
								</div>
							</div>
						)}

						{isError && (
							<div className="mb-lg-10 alert alert-danger">
								<div className="alert-text fw-bold">
									<FormattedMessage id="AUTH.FORGOT.ERROR" />
								</div>
							</div>
						)}

						{isSuccess && (
							<div className="mb-lg-10 alert alert-success">
								<div className="success-text fw-bold">
									<FormattedMessage id="AUTH.FORGOT.RESET.PASSWORD.SUCCESS" />
								</div>
							</div>
						)}

						<div className="fv-row mb-8">
							<label className="form-label fw-bolder text-dark fs-6">
								<FormattedMessage id="AUTH.INPUT.PASSWORD" />
							</label>
							<div className="position-relative">
								<input
									type={showPassword ? 'text' : 'password'}
									placeholder="********"
									autoComplete="off"
									{...register('password')}
									disabled={!isValidatedSuccess}
									className={clsx(
										'form-control bg-transparent',
										{ 'is-invalid': touchedFields.password && errors.password },
										{ 'is-valid': touchedFields.password && !errors.password }
									)}
								/>
								<button
									type="button"
									className={clsx('btn position-absolute end-0 top-50 translate-middle-y', {
										'me-4': (touchedFields.password && errors.password) || (touchedFields.password && !errors.password),
									})}
									onClick={togglePasswordVisibility}
								>
									<i className={showPassword ? 'far fa-eye' : 'far fa-eye-slash'} />
								</button>
							</div>
							{errors.password && (
								<div className="fv-plugins-message-container">
									<div className="fv-help-block">
										<span role="alert">{errors.password.message}</span>
									</div>
								</div>
							)}
						</div>

						<div className="fv-row mb-8">
							<label className="form-label fw-bolder text-dark fs-6">
								<FormattedMessage id="AUTH.INPUT.CONFIRM_PASSWORD" />
							</label>
							<div className="position-relative">
								<input
									type={showConfirmPassword ? 'text' : 'password'}
									autoComplete="off"
									placeholder="********"
									{...register('confirm_password')}
									disabled={!isValidatedSuccess}
									className={clsx(
										'form-control bg-transparent',
										{ 'is-invalid': touchedFields.confirm_password && errors.confirm_password },
										{ 'is-valid': touchedFields.confirm_password && !errors.confirm_password }
									)}
								/>
								<button
									type="button"
									className={clsx('btn position-absolute end-0 top-50 translate-middle-y', {
										'me-4': (touchedFields.password && errors.password) || (touchedFields.password && !errors.password),
									})}
									onClick={toggleConfirmPasswordVisibility}
								>
									<i className={showConfirmPassword ? 'far fa-eye' : 'far fa-eye-slash'} />
								</button>
							</div>
							{errors.confirm_password && (
								<div className="fv-plugins-message-container">
									<div className="fv-help-block">
										<span role="alert">{errors.confirm_password.message}</span>
									</div>
								</div>
							)}
						</div>

						<div className="d-flex flex-wrap justify-content-center pb-lg-0">
							<button
								type="submit"
								id="kt_password_reset_submit"
								className="btn btn-primary me-4"
								disabled={isSubmitting || !isValid || !isValidatedSuccess}
							>
								<span className="indicator-label">
									<FormattedMessage id="AUTH.GENERAL.SUBMIT_BUTTON" />
								</span>
								{isLoading && (
									<span className="indicator-progress">
										<FormattedMessage id="AUTH.LOGIN.LOADING" />
										<span className="spinner-border spinner-border-sm align-middle ms-2" />
									</span>
								)}
							</button>
							<Link to="/auth/login">
								<button
									type="button"
									id="kt_login_password_reset_form_cancel_button"
									className="generic-button fw-bold me-3 me-sm-5 fs-5"
								>
									<FormattedMessage id="GENERAL.CANCEL" />
								</button>
							</Link>
						</div>
					</form>
				</div>
			</div>
			{devMode && <DevTool control={control} />}
		</>
	);
};
