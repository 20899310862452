import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

library.add(fas);
const defaultIconClass = 'bi bi-star-fill fs-2';
const defaultMultiRowsClass = 'fw-lighter text-start';
const defaultBadgeClass = 'badge badge-light-success fs-6 fw-bold';

const decoration = (item) => {
	let element = '';

	switch (item.type) {
		case 'icon':
			element = <i className={item.class || defaultIconClass} />;
			break;
		case 'badge':
			element = <span className={item.class || defaultBadgeClass}>{item.payload}</span>;
			break;
		//allign to the right
		case 'currency':
			element = item.payload ? item.payload + ' €' : '0 €';
			break;
		case 'multiRows':
			element = (
				<>
					<div>{item.payload}</div>
					<div style={{ fontSize: '0.8em' }} className={item.class || defaultMultiRowsClass}>
						{item.extraInfo}
					</div>
				</>
			);
			break;

		default:
			try {
				element = <FontAwesomeIcon icon={item.type} color={item.color || '#000000'} size={item.size} />;
			} catch (error) {
				element = <div>{element?.payload}</div>;
			}

			break;
	}

	return element;
};

export default decoration;
