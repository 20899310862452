import clsx from 'clsx';
import { Children, useContext, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import ConfirmationModal from 'src/_metronic/partials/modals/ConfirmationModal';
import { TableTagContent, TagPicker } from 'src/pages/analytics/tag/components/TagPickers';

import { KTSVG } from '../../_metronic/helpers';
import { PopUp } from '../Popup';
import TableContext from './context';

var lastChildField = null;

const TableBody = ({ children, onRowClick }) => {
	const { items, isSuccess, loading, selectedRows, tagConfig } = useContext(TableContext);
	const [popup, setPopup] = useState(false);
	const [tagRef, setTagRef] = useState(null);
	const [selectedRowId, setSelectedRowId] = useState(null);

	const childrenArray = Children.toArray(children);
	lastChildField = childrenArray[childrenArray.length - 1]?.props?.field;
	let body;
	if (loading) {
		body = (
			<TableContentAlert>
				<img src="/media/logos/Loader-table.svg" alt="Tabol logo" />
			</TableContentAlert>
		);
	} else if (!loading && !isSuccess) {
		body = (
			<TableContentAlert>
				<div className="d-flex justify-content-center align-items-center py-5">
					<img src="/media/logos/Tabol-Logo.png" alt="Tabol Logo" className="img-fluid max-height-200" />
				</div>
				<div className="text-muted fw-bold">
					<FormattedMessage id="TABLE.BODY.ERROR" />
				</div>
			</TableContentAlert>
		);
	} else if (!items || items.length === 0) {
		body = (
			<TableContentAlert>
				<div className="d-flex justify-content-center flex-column align-items-center py-5">
					<img src="/media/logos/Tabol-Logo.png" alt="Tabol Logo" className="img-fluid max-height-200" />
				</div>
				<div className="text-muted fw-bold">
					<FormattedMessage id="TABLE.BODY.NODATA" />
				</div>
			</TableContentAlert>
		);
	} else {
		body = items.map((item) => (
			<TableRow
				onClick={(e) => {
					if (
						e.target.className == 'form-check-input widget-13-check cursor-pointer' ||
						e.target.classname == 'bi bi-three-dots-vertical fs-4'
					) {
						return;
					} else {
						if (!onRowClick) {
							return;
						}
						if (e.target.className === 'bi bi-three-dots-vertical fs-4') {
							return;
						}
						onRowClick(item);
					}
				}}
				key={item._id}
			>
				<TableRowCheck id={item._id} />
				{children.map(({ props: { type, field, formatValue, body, capitalize, hidden, truncate, sticky } }) => (
					<TableCell
						key={field}
						onRowClick={() => {
							if (!onRowClick) {
								return;
							}

							onRowClick(item);
						}}
						onMouseEnter={(e) => {
							tagConfig && setTagRef(e.target);
							tagConfig && setSelectedRowId([item._id]);
							setPopup(true);
						}}
						onMouseLeave={() => {
							tagConfig && setTagRef(null);
							tagConfig && setSelectedRowId(null);
							setPopup(true);
						}}
						hidden={hidden}
						type={type}
						field={field}
						formatValue={formatValue}
						body={body}
						capitalize={capitalize}
						truncate={truncate}
						sticky={sticky}
					>
						{item}
					</TableCell>
				))}
				{tagConfig && popup && (
					<PopUp show={popup} anchorElement={tagRef} selfClose={() => setPopup(false)}>
						<TagPicker
							// individualRowId={'65abd26ddaab6765dc8aaceb'}
							selectedRows={selectedRows || selectedRowId}
							references={null}
							referenceModel={'contact'}
							section={tagConfig.section}
							refModel={'contact'}
							tagLocation
							onApply={() => {
								setSelectedRowId(null);
								setPopup(false);
							}}
						/>
					</PopUp>
				)}
			</TableRow>
		));
	}

	return <tbody>{body}</tbody>;
};

export const TableContentAlert = ({ children }) => {
	return (
		<tr>
			<td colSpan="12" style={{ textAlign: 'center' }}>
				{children}
			</td>
		</tr>
	);
};

const TableRow = ({ children, onClick }) => {
	return (
		<tr className={clsx({ 'clickable-row': onClick })} onClick={onClick}>
			{children}
		</tr>
	);
};

const TableRowCheck = ({ id }) => {
	const { selectable, selectedRows, selectRows } = useContext(TableContext);
	if (!selectable) return;

	return (
		<td className="align-middle">
			<div className="form-check form-check-sm form-check-custom form-check-solid">
				<input
					className="form-check-input widget-13-check cursor-pointer"
					type="checkbox"
					name={id}
					checked={selectedRows.includes(id)}
					onChange={() => selectRows([id])}
				/>
			</div>
		</td>
	);
};

const TableCell = ({ children, type, field, formatValue, body, capitalize, hidden, truncate, sticky, onRowClick }) => {
	if (hidden) return;
	let values = getValuesByDotNotation(children, field);
	let value = '';
	if (typeof formatValue === 'function') {
		value = formatValue(...values);
	} else if (typeof body === 'function') {
		value = body(children);
	} else if (field === 'tags') {
		value = <TableTagContent item={children} />;
	} else {
		value = values.map((v) => v);
	}

	let cell;
	switch (type) {
		case 'href':
			cell = children && (
				<a className={clsx('text-dark fw-bold text-hover-primary fs-6', { 'text-capitalize': capitalize })} href={value}>
					{value}
				</a>
			);
			break;
		case 'custom':
			cell = value;
			break;
		case 'span':
		default:
			cell = (
				<span className={clsx('text-dark fw-bold fs-6', { 'text-capitalize': capitalize }, { 'truncate-table-text': truncate })}>
					{value ?? ''}
				</span>
			);
	}

	return (
		<td className={clsx('align-middle text-nowrap', { 'text-end sticky-column': sticky && lastChildField === field })}>
			<div
				onClick={(e) => {
					if ((field !== 'action' || e.target.className !== 'bi bi-three-dots-vertical fs-4') && onRowClick) {
						onRowClick(children);
						e.stopPropagation();
						e.preventDefault();
					} else {
						if (e.target.className === 'bi bi-three-dots-vertical fs-4') {
							return;
						}
					}
				}}
			>
				{cell}
			</div>
		</td>
	);
};

const TableCellActions = ({ children }) => {
	return <td className="align-middle text-nowrap text-end">{children}</td>;
};

const TableCellActionItems = ({ icon, action, confirmAction }) => {
	const [show, setShow] = useState(false);

	const handleActionConfirmation = (confirmed) => {
		setShow(false);

		confirmed && action();
	};

	const handleOnClick = () => {
		if (confirmAction) {
			setShow(true);
		} else {
			action();
		}
	};

	return (
		<>
			{show && <ConfirmationModal onConfirm={handleActionConfirmation} />}
			<span className="btn btn-icon btn-bg-light btn-sm me-1 btn-color-primary" onClick={handleOnClick}>
				<KTSVG path={icon} className="svg-icon-3" />
			</span>
		</>
	);
};

function getValuesByDotNotation(object, dotNotation = '') {
	// Split the dot notation into an array of field notations
	const fields = dotNotation.split(',');
	let values = [];

	for (const field of fields) {
		// Split the field dot notation into an array of keys
		const keys = field.split('.');

		// Initialize a variable to store the current value
		let value = object;

		// Iterate through the keys and check for undefined at each level
		for (const key of keys) {
			if (value && typeof value === 'object' && key in value) {
				// If the value is not undefined and the key exists in the current object,
				// update the value for the next iteration
				value = value[key];
			} else {
				// If the value is undefined or the key doesn't exist, set value to undefined and break
				value = undefined;
				break;
			}
		}
		values.push(value);
	}

	return values;
}

export { TableBody, TableCell, TableCellActionItems, TableCellActions, TableRow, TableRowCheck };
