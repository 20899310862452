import React, { useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { KTSVG } from '../../_metronic/helpers';
import { useDeleteImageMutation, useUploadImageMutation } from '../../reducers/fileApiSlice';
import { API_ROOT } from '../../reducers/services/api';

export type MediaResponse = MediaItem & Record<string, unknown>;

type Props = {
	onUpload?: (media: MediaResponse[]) => void;
	onRemove?: (media: MediaItem) => void;
	disabled?: boolean;
	files: MediaItem[];
	limit?: number;
	allowedTypes?: string[];
};

export type MediaItem = {
	_id: string;
	title: string;
	url_sm: string;
	url?: string;
};

const File: React.FC<Props> = ({
	onUpload,
	onRemove,
	allowedTypes = ['image/jpg, image/jpeg', 'application/pdf'],
	files = [],
	limit = 10,
	disabled = false,
}) => {
	const [uploadImage] = useUploadImageMutation();
	const [deleteImage] = useDeleteImageMutation();
	const [media, setMedia] = useState<MediaItem[]>(files);
	const [isUploading, setIsUploading] = useState(false);
	const id = useMemo(() => 'file' + new Date().getTime(), []);
	const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
		const fileInput = e.target;
		const file = fileInput.files?.[0];

		if (file && !isUploading) {
			try {
				setIsUploading(true);
				const formData = new FormData();
				formData.append('image', file);

				const response = await uploadImage(formData);
				if ('data' in response) {
					const newMedia: MediaItem = {
						_id: response.data.media._id,
						title: response.data.media.title,
						url_sm: response.data.media.url_sm,
					};
					onUpload && onUpload(response.data.media); //MediaResponse
				}
			} catch (error) {
				console.error('File upload failed:', error);
			} finally {
				setIsUploading(false);
				// Reset the input value to ensure onChange triggers for the same file
				fileInput.value = '';
			}
		}
	};

	return (
		<div>
			{files &&
				files.length > 1 &&
				files.map((media) => (
					<div
						key={media._id}
						className="d-inline-flex align-items-center  w-100 p-4 fs-5 fw-bold"
						onClick={async () => {
							const removed = files.find(({ _id }) => _id == media._id);
							await deleteImage({ id: removed?._id });
							removed && onRemove && onRemove(removed);
						}}
					>
						<span className="fs-7 text-primary">{media.title}</span>
						<KTSVG className="svg-icon-2x svg-icon-primary px-2" path="/media/icons/duotune/general/gen040.svg" />
					</div>
				))}
			<div className="d-flex align-items-center">
				<div className="uploader-container fs-5 fw-bold">
					<div className="uploader-wrapper p-4">
						<input
							type="file"
							id={id}
							className="custom-checkbox w-100 h-100"
							accept={allowedTypes.join(',')}
							onChange={handleChange}
							disabled={disabled || isUploading}
						/>
						<label htmlFor={id} className="uploader">
							{files && files.length > 0 ? (
								files.map((m) => (
									<div key={m._id} className="d-inline-flex align-items-center me-3 mb-2">
										<img
											src={API_ROOT + '/' + (m.url_sm ?? m.url)}
											alt={m.title}
											className="me-2"
											onClick={() => {
												onUpload && onUpload(media.filter(({ _id }) => _id !== m._id));
											}}
											style={{ width: '40px', height: '40px', objectFit: 'cover' }}
										/>
									</div>
								))
							) : (
								<KTSVG className="svg-icon-4x svg-icon-primary" path="/media/icons/duotune/files/fil009.svg" />
							)}

							<div className="uploader-text">
								<span className="fs-bold">
									<FormattedMessage id="FILES.UPLOAD" />
								</span>
								<span className="fs-7 text-primary">
									<FormattedMessage id="FILES.DRAG.DROP" />
								</span>
							</div>
						</label>
					</div>
				</div>
				{files && files.length > 0 && files.length <= 1 ? (
					<div
						className="ms-3 btn btn-icon btn-light-danger"
						style={{ width: '40px', height: '40px' }}
						onClick={async (e) => {
							// handleDeletion(`translations[${fileLang}].menu`);
							files?.[0] && files?.[0]._id ? await deleteImage({ id: files[0]._id }) : setMedia([]);
							onRemove && onRemove(files[0]);
						}}
					>
						<KTSVG className="svg-icon-2" path="/media/icons/duotune/general/gen040.svg" />
					</div>
				) : null}
			</div>
		</div>
	);
};

export default File;
