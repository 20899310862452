import clsx from 'clsx';
import { useContext } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import TableContext from './context';

const TableSelection = ({ children, className }) => {
	const intl = useIntl();
	const { selectedRows } = useContext(TableContext);

	if (!selectedRows.length) {
		return;
	}

	return (
		<div className={clsx('card-header justify-content-end', className)}>
			<div className="d-flex justify-content-end">
				<div className="px-0 d-flex align-items-center">
					<span className="fw-bold me-3">
						<FormattedMessage id="TABLE.SELECTED" /> : {selectedRows.length}
					</span>
					{children}
				</div>
			</div>
		</div>
	);
};

export default TableSelection;
