import { useFieldArray } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

import { KTSVG } from '../../../_metronic/helpers';
import { PhoneNumber, SelectControl, Text } from '..';

const commTypeOptions = [
	{ value: 'viber', label: 'Viber' },
	{ value: 'sms', label: 'SMS' },
	{ value: 'whatsapp', label: 'Whatsapp' },
	{ value: 'email', label: 'Email' },
];

const MediaInfo = ({ control, register, name, errors, watch, disabled = false }) => {
	const { fields, append, remove } = useFieldArray({
		control,
		name,
	});

	const appendItem = () => append({ type: commTypeOptions[0].value });

	return (
		<>
			{fields.map((field, index) => {
				const type = watch(`${name}.${index}.type`) || commTypeOptions[0].value;
				return (
					<div key={field.id} className="row pb-5">
						<div className="col-12 col-sm-3">
							<SelectControl
								control={control}
								title="Type"
								name={`${name}.${index}.type`}
								options={commTypeOptions}
								defaultValue={commTypeOptions[0]}
								isDisabled={disabled}
							/>
						</div>
						<div className="col-12 col-sm-4 pt-5 pt-sm-0">
							<div className="input-group">
								{['viber', 'whatsapp', 'sms'].includes(type) && (
									<PhoneNumber
										register={register}
										control={control}
										name={`${name}.${index}.value`}
										title={'\u00A0'}
										errors={errors}
										disabled={disabled}
									/>
								)}
								{['email'].includes(type) && (
									<Text register={register} name={`${name}.${index}.value`} title={'\u00A0'} errors={errors} disabled={disabled} />
								)}
							</div>
						</div>
						{!disabled && (
							<div className="col-12 col-sm-2 pt-5 pt-sm-0 d-flex align-items-end">
								<button
									onClick={() => remove(index)}
									type="button"
									className="btn  btn-light-danger btn-color-danger w-100 d-flex justify-content-center align-items-center"
								>
									<KTSVG className="svg-icon-1" path="/media/icons/duotune/general/gen027.svg" />
									<div>
										<FormattedMessage id="GENERAL.DELETE" />
									</div>
								</button>
							</div>
						)}
					</div>
				);
			})}

			{!disabled && (
				<div className="row ">
					<div className="col-12 col-sm-3">
						<button type="button" className="generic-button fw-bold fs-6 w-100 p-4" onClick={appendItem}>
							<FormattedMessage id="GENERAL.ADD" />
						</button>
					</div>
				</div>
			)}
		</>
	);
};

export default MediaInfo;
