import jwtDecode from 'jwt-decode';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { isEmptyObj } from 'src/common/misc';
import { getLocalStorage } from 'src/common/storage';

import { AuthLayout } from './AuthLayout';
import { ForgotPassword } from './components/ForgotPassword';
import Login from './components/Login';
import { Registration } from './components/Registration';
import { ResetPassword } from './components/ResetPassword';

const validPaths = ['/auth/login', '/auth/registration', '/auth/forgot-password', '/auth/password/reset'];

const AuthPage = () => {
	const location = useLocation();
	const token = getLocalStorage('accessToken');
	const jwt = token ? jwtDecode(token) : {};

	if (jwt && !isEmptyObj(jwt)) {
		return <Navigate to="/reservations/list" replace />;
	}

	const isValidPath =
		validPaths.some((path) => new RegExp(`^${path.replace(/:[^\s/]+/g, '[^/]+')}$`).test(location.pathname)) ?? location.pathname === '/';

	if (!isValidPath) {
		return <Navigate to="/login" replace />;
	}

	return (
		<Routes>
			<Route element={<AuthLayout />}>
				<Route path="login" element={<Login />} />
				<Route path="registration" element={<Registration />} />
				<Route path="forgot-password" element={<ForgotPassword />} />
				<Route path="password/reset" element={<ResetPassword />} />

				<Route index element={<Login />} />
			</Route>
		</Routes>
	);
};

export default AuthPage;
