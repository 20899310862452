import clsx from 'clsx';
import { getLocalStorage } from 'src/common/storage';

const HeaderUser = () => {
	const currentUser = JSON.parse(getLocalStorage('user') || '{}') as { name: string } | null;

	const getInitials = (name: string | undefined): string => {
		if (!name) return '';

		// Split the name into words
		const words = name.trim().split(/\s+/);

		if (words.length === 1) {
			// For single names, return first two letters uppercase
			return words[0].slice(0, 2).toUpperCase();
		} else {
			// For multiple names, return first letter of first two words
			return (words[0][0] + words[1][0]).toUpperCase();
		}
	};

	const acronym = getInitials(currentUser?.name);

	return (
		<div
			className={clsx('circle-border d-flex align-items-center justify-content-center cursor-pointer bg-primary symbol-35px symbol-md-40px')}
			data-kt-menu-trigger="{default: 'click'}"
			data-kt-menu-attach="parent"
			data-kt-menu-placement="bottom-end"
		>
			<span className="text-white fw-bold fs-4">{acronym}</span>
		</div>
	);
};

export { HeaderUser };
