import { createSlice } from '@reduxjs/toolkit';
import { removeLocalStorage } from 'src/common/storage';

import { IAuthState } from './types';

const initialState: IAuthState = {
	user: null,
};

const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		logOut: (state) => {
			state.user = null;
			removeLocalStorage('user');
			removeLocalStorage('accessToken');
			removeLocalStorage('refreshToken');
		},
		setUser: (state, action) => {
			state.user = action.payload;
		},
	},
});

export const selectUser = (state) => state.auth.user;
export const { logOut, setUser } = authSlice.actions;

export default authSlice.reducer;
