import { DevTool } from '@hookform/devtools';
import { yupResolver } from '@hookform/resolvers/yup';
import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { mainCustomStyles } from 'src/common/inputStyles';
import { removeLocalStorage, removeSessionStorage } from 'src/common/storage';
import { SelectControl } from 'src/components/input';
import { scrollOnError } from 'src/helpers/utils';
import { useGetOwnersQuery, useLoginMutation } from 'src/reducers/auth/api';
import { UserRoleEnum } from 'src/reducers/auth/types';
import { devMode } from 'src/reducers/services/store';

import { LoginFormInputs, loginSchema } from '../schema';

const Login = () => {
	const intl = useIntl();
	const location = useLocation();
	const navigate = useNavigate();

	const [login, { data, isSuccess, isLoading, isError }] = useLoginMutation();

	const [showPassword, setShowPassword] = useState(false);
	const togglePasswordVisibility = () => setShowPassword(!showPassword);

	const isSuAdmin = data?.role === UserRoleEnum.SUADMIN;
	const pathname = (location.state as { from?: { pathname?: string } })?.from?.pathname;

	const { data: owners, isSuccess: isOwnersSuccess, isLoading: isOwnersLoading } = useGetOwnersQuery(undefined, { skip: !isSuAdmin });

	const {
		watch,
		control,
		register,
		handleSubmit,
		formState: { errors, isValid, isSubmitting, touchedFields },
	} = useForm<LoginFormInputs>({
		mode: 'all',
		resolver: yupResolver(loginSchema(isSuAdmin, intl)),
	});

	const onSubmit: SubmitHandler<LoginFormInputs> = (formData) => login(formData);

	useEffect(() => {
		if (isSuAdmin && isOwnersSuccess && !watch('owner')) {
			removeLocalStorage('user');
			removeLocalStorage('accessToken');
			removeLocalStorage('refreshToken');
		}
	}, [isSuAdmin, isOwnersSuccess]);

	useEffect(() => {
		const handleRedirect = () => {
			removeSessionStorage('act_bsn');
			removeSessionStorage('act_str');
			removeSessionStorage('language');

			navigate(pathname ?? '/reservations/list', { replace: true });
			document.body.classList.remove('app-auth-default');
		};

		if (isSuccess && data && !isSuAdmin) {
			handleRedirect();
		}

		if (isSuccess && data && isSuAdmin && isOwnersSuccess && watch('owner')) {
			handleRedirect();
		}
	}, [isSuccess, data, isSuAdmin, isOwnersSuccess, touchedFields.owner, navigate, pathname]);

	return (
		<>
			<div className="card card-custom overlay min-h-125px shadow-none">
				<div className="card-body d-flex flex-column pt-10">
					<form className="form w-100" noValidate id="kt_login_signin_form" onSubmit={handleSubmit(onSubmit, scrollOnError)}>
						<div className="text-center mb-11">
							<h1 className="text-dark fw-bolder mb-3">
								<FormattedMessage id="AUTH.LOGIN.TITLE" />
							</h1>
						</div>

						{isError && (
							<div className="mb-lg-15 alert alert-danger">
								<div className="alert-text font-weight-bold">
									<FormattedMessage id="AUTH.LOGIN.ERROR" />
								</div>
							</div>
						)}

						<div className="fv-row mb-8">
							<label className="form-label fs-6 fw-bolder text-dark">
								<FormattedMessage id="AUTH.INPUT.EMAIL" />
							</label>
							<input
								type="text"
								placeholder={intl.formatMessage({ id: 'AUTH.INPUT.EMAIL' })}
								{...register('username')}
								className={clsx(
									'form-control bg-transparent',
									{ 'is-invalid': touchedFields.username && errors.username },
									{ 'is-valid': touchedFields.username && !errors.username }
								)}
								autoComplete="off"
							/>
							{errors.username && (
								<div className="fv-plugins-message-container">
									<div className="fv-help-block">
										<span role="alert">{errors.username.message}</span>
									</div>
								</div>
							)}
						</div>

						<div className="fv-row mb-8">
							<label className="form-label fw-bolder text-dark fs-6">
								<FormattedMessage id="AUTH.INPUT.PASSWORD" />
							</label>
							<div className="position-relative">
								<input
									type={showPassword ? 'text' : 'password'}
									{...register('password')}
									className={clsx(
										'form-control bg-transparent pe-5',
										{ 'is-invalid': touchedFields.password && errors.password },
										{ 'is-valid': touchedFields.password && !errors.password }
									)}
									autoComplete="off"
									placeholder="********"
								/>
								<button
									type="button"
									className={clsx('btn position-absolute end-0 top-50 translate-middle-y', {
										'me-4': (touchedFields.password && errors.password) || (touchedFields.password && !errors.password),
									})}
									onClick={togglePasswordVisibility}
								>
									<i className={showPassword ? 'far fa-eye' : 'far fa-eye-slash'} />
								</button>
							</div>
							{errors.password && (
								<div className="fv-plugins-message-container">
									<div className="fv-help-block">
										<span role="alert">{errors.password.message}</span>
									</div>
								</div>
							)}
						</div>

						{isSuAdmin && (
							<div className="fv-row mb-3">
								<SelectControl
									name="owner"
									title={intl.formatMessage({ id: 'HEADER.MENU.USER' })}
									control={control}
									options={owners ?? []}
									isDisabled={isLoading || isOwnersLoading}
									styles={mainCustomStyles}
									loginTitle
								/>
							</div>
						)}

						<div className="d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8">
							<div />
							<Link to="/auth/forgot-password" className="link-primary">
								<FormattedMessage id="AUTH.LOGIN.FORGOT_PASSWORD" />
							</Link>
						</div>

						<div className="d-grid mb-10">
							<button
								type="submit"
								id="kt_sign_in_submit"
								className="btn btn-primary"
								disabled={isSubmitting || !isValid || (isSuAdmin && isOwnersLoading)}
							>
								{!isLoading && !isOwnersLoading && (
									<span className="indicator-label">
										<FormattedMessage id="AUTH.LOGIN.BUTTON" />
									</span>
								)}
								{(isLoading || isOwnersLoading) && (
									<span className="indicator-progress" style={{ display: 'block' }}>
										<FormattedMessage id="AUTH.LOGIN.LOADING" />
										<span className="spinner-border spinner-border-sm align-middle ms-2" />
									</span>
								)}
							</button>
						</div>

						<div className="text-gray-500 text-center fw-semibold fs-6">
							<FormattedMessage id="AUTH.LOGIN.NOT_A_MEMBER" />
							<Link to="/auth/registration" className="link-primary ms-1">
								<FormattedMessage id="AUTH.GENERAL.SIGNUP_BUTTON" />
							</Link>
						</div>
					</form>
				</div>
			</div>
			{devMode && <DevTool control={control} />}
		</>
	);
};

export default Login;
