import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

import { KTSVG } from '../../../../../_metronic/helpers';
import { BUSINESS_LANGUAGES, SOCIAL } from '../../../../../common/constants';
import LanguageSelector from '../../../../../components/languageSelector/LanguageSelector';
import useConfirmationModal from '../../../../../components/modal/useConfirmationModal';
import { optionsCancellationPolicy, socialList } from './StoreOptions';

const LocationSocialTextFields = (props) => {
	const { register, control, watch, trigger, errors, handleVatCheckboxChange, setValue, disableVat, setDisableVat, defaultLocale } = props;
	const [confirmAction, confirmationModal] = useConfirmationModal();
	const defaultLocaleIdx = BUSINESS_LANGUAGES.findIndex((language) => language?.code === defaultLocale ?? 0);
	const [prepaymentLang, setPrepaymentLang] = useState(BUSINESS_LANGUAGES[defaultLocaleIdx ?? 0]?.code ?? 'el');
	const [cancellationLang, setCancellationLang] = useState(BUSINESS_LANGUAGES[defaultLocaleIdx ?? 0]?.code ?? 'el');
	const [notesLang, setNotesLang] = useState(BUSINESS_LANGUAGES[defaultLocaleIdx ?? 0]?.code ?? 'el');
	const translations = BUSINESS_LANGUAGES.reduce((acc, lang) => {
		acc[lang?.code] = {
			prepayment_policy: watch(`translations[${lang?.code}].prepayment_policy`),
			cancelation_policy: watch(`translations[${lang?.code}].cancelation_policy`),
			notes: watch(`translations[${lang?.code}].notes`),
		};
		return acc;
	}, {});
	const handlePrepaymentLanguageChange = (language) => setPrepaymentLang(language);
	const handleCancellationLanguageChange = (language) => setCancellationLang(language);
	const handleNotesLanguageChange = (language) => setNotesLang(language);

	// Social

	const { control: formControl, register: formRegister } = useForm({
		defaultValues: {
			contact_info: { social: [] },
		},
	});

	const { fields, append, remove } = useFieldArray({
		control,
		name: 'contact_info.social',
	});
	// const social = watch('contact_info.social');

	const removeSocial = (index) => {
		remove(index);
	};
	const [availableSocials, setAvailableSocials] = useState(SOCIAL.slice(1));

	const appendSocial = () => {
		append({ type: availableSocials[0], val: '' });
		trigger('contact_info.social');
	};
	useEffect(() => {
		const subscription = watch(({ social }) => {
			const filteredSocials = SOCIAL.filter((option) => social && !social.some((obj) => obj.type === option));
			setAvailableSocials(filteredSocials);
		});

		return () => subscription.unsubscribe();
	}, [watch]);

	// VAT
	const isVatVisible = false; // TOD0 - Placeholder to hide VAT
	const handleVatCheckbox = (event) => {
		setDisableVat(!disableVat);
		setValue('vatNumber', '', disableVat === true);
		const isVatDisabled = event.target.checked;
		handleVatCheckboxChange(isVatDisabled);
	};
	return (
		<>
			<div className="row">
				<div className="col-12">
					<div className="separator my-5" />
				</div>
			</div>

			<div className="row pb-5">
				<div className="col-12">
					<div className="mb-0 fs-2 fw-bold">
						<FormattedMessage id="STORE.MODAL.DETAILS.SOCIAL" />
					</div>
				</div>
			</div>

			{fields.map((field, index) => (
				<div className="row pb-5 g-5" key={index}>
					<div className="col-12 col-sm-3">
						<div className="select-container">
							<select
								className="fs-6 fw-bold text-capitalize"
								aria-label="Select example"
								{...register(`contact_info.social[${index}].type`)}
								defaultValue={field.type ?? socialList[0].value}
							>
								{socialList.map((socialOption) => (
									<option key={socialOption.type} value={socialOption.value} defaultValue={socialOption.type}>
										{socialOption.type}
									</option>
								))}
							</select>
							<i className="fa-solid fa-chevron-down arrow-icon"></i>
						</div>
					</div>
					<div className="col-12 col-sm-7">
						<div className="input-group">
							<input
								type="text"
								name={`social[${index}].val`}
								{...register(`contact_info.social[${index}].val`)}
								className={clsx('form-control form-control-solid p-4', {
									'is-invalid': errors?.contact_info?.social && errors?.contact_info.social[index]?.val,
								})}
							/>
							{errors?.contact_info?.social && errors?.contact_info?.social[index] ? (
								<div className="fv-plugins-message-container">
									<div className="fv-help-block">
										<span role="alert">{errors?.contact_info?.social[index].val.message}</span>
									</div>
								</div>
							) : null}
						</div>
					</div>
					<div className="col-12 col-sm-2 d-flex align-items-start">
						<button
							type="button"
							onClick={() => confirmAction(() => removeSocial(index))}
							className="btn btn-light-danger btn-color-danger w-100 d-flex justify-content-center align-items-center p-4"
						>
							<KTSVG className="svg-icon-1" path="/media/icons/duotune/general/gen027.svg" />
							<div className="fs-7">
								<FormattedMessage id="GENERAL.DELETE" />
							</div>
						</button>
					</div>
				</div>
			))}
			<div className="row">
				<div className="col-12 col-sm-4">
					<button type="button" onClick={() => appendSocial()} className="generic-button fs-6 w-100 fw-bold">
						<FormattedMessage id="STORE.MODAL.DETAILS.ADD_SOCIAL" />
					</button>
				</div>
			</div>
			<Controller
				name="use_business_social"
				control={control}
				render={({ field: { value, onChange, ref, name } }) => (
					<div className="row pt-3">
						<div className="col-12">
							<div className="form-check form-check-custom form-check-solid pe-3 pt-md-0 pt-3">
								<input
									ref={ref}
									name={name}
									className="form-check-input"
									type="checkbox"
									id={'business-social-checkbox'}
									aria-labelledby={`business-social-checkbox`}
									onChange={onChange}
									checked={value}
									value={value}
								/>
								<label className="form-check-label fs-7" htmlFor="business-social">
									<FormattedMessage id="STORE.MODAL.DETAILS.USE_SOCIAL" />
								</label>
							</div>
							{errors?.use_business_social && (
								<div className="fv-plugins-message-container">
									<div className="fv-help-block">
										<span role="alert">{errors.use_business_social.message}</span>
									</div>
								</div>
							)}
						</div>
					</div>
				)}
			/>

			{isVatVisible ? (
				<>
					<div className="row">
						<div className="col-12">
							<div className="separator my-5" />
						</div>
					</div>

					<div className="row pb-5">
						<div className="col-12">
							<div className="mb-0 fs-2 fw-bold">
								<FormattedMessage id="STORE.MODAL.DETAILS.VAT" />
							</div>
						</div>
					</div>

					<div className="row pb-5">
						<div className="col-12">
							<div className="input-group">
								<label htmlFor="location-vat" className="form-label pb-3 mb-0 fs-7 fw-bold required">
									<FormattedMessage id="STORE.MODAL.DETAILS.VAT" />
								</label>
								<input
									type="text"
									className="form-control p-4"
									id="location-vat"
									name="location-vat"
									aria-describedby="businessTitle"
									placeholder="EL"
									{...register('vatNumber')}
									disabled={disableVat === true}
								/>
								{errors?.vatNumber && (
									<div className="fv-plugins-message-container">
										<div className="fv-help-block">
											<span role="alert">{errors.vatNumber.message}</span>
										</div>
									</div>
								)}
							</div>
							<p className="mb-0 pt-3">
								<FormattedMessage id="GENERAL.VAT.NUMBER.MSG" />
								<a href="https://en.wikipedia.org/wiki/VAT_identification_number" target="_blank" rel="noopener noreferrer">
									<FormattedMessage id="GENERAL.VAT.NUMBER.COUNTRIES.LIST" />
								</a>
							</p>
						</div>
					</div>

					<div className="row pb-5">
						<div className="col-12">
							<div className="form-check form-check-custom form-check-solid pe-3 pt-md-0 pt-3">
								<input
									className="form-check-input"
									type="checkbox"
									value=""
									id="vat-option"
									name="vat-option"
									onChange={handleVatCheckbox}
								/>
								<label className="form-check-label fs-7" htmlFor="vat-option">
									<FormattedMessage id="STORE.MODAL.DETAILS.NOT_HAVE_VAT" />
								</label>
							</div>
						</div>
					</div>
				</>
			) : null}

			<div className="row">
				<div className="col-12">
					<div className="separator my-5" />
				</div>
			</div>

			<div className="row pb-5">
				<div className="col-12">
					<div className="mb-0 fs-2 fw-bold">
						<FormattedMessage id="STORE.MODAL.DETAILS.PREPAYMENT" />
					</div>
				</div>
			</div>

			<div className="p-3 generic-border">
				<LanguageSelector language={prepaymentLang} onLanguageChange={handlePrepaymentLanguageChange} />
				<div className="row pt-5">
					<div className="col-12">
						<label htmlFor="prepayment" className="form-label pb-3 mb-0 fs-7 fw-bold" />
						<textarea
							id="prepayment"
							name="prepayment"
							className="full-width-textarea"
							rows="4"
							cols="50"
							{...register(`translations[${prepaymentLang}].prepayment_policy`)}
							value={translations[prepaymentLang]?.prepayment_policy ?? ''}
						/>
					</div>
				</div>
			</div>

			<div className="row">
				<div className="col-12">
					<div className="separator my-5" />
				</div>
			</div>

			<div className="row pb-5">
				<div className="col-12">
					<div className="mb-0 fs-2 fw-bold">
						<FormattedMessage id="STORE.MODAL.DETAILS.CANCELLATION_POLICY" />
					</div>
				</div>
			</div>

			<div className="p-3 generic-border">
				<LanguageSelector language={cancellationLang} onLanguageChange={handleCancellationLanguageChange} />
				<div className="row pt-5">
					<div className="col-12">
						<label htmlFor="cancellation" className="form-label pb-3 mb-0 fs-7 fw-bold" />
						<textarea
							id="cancellation"
							name="cancellation"
							className="full-width-textarea"
							rows="4"
							cols="50"
							{...register(`translations[${cancellationLang}].cancelation_policy`)}
							defaultValue={translations[cancellationLang]?.cancelation_policy}
							value={translations[cancellationLang]?.cancelation_policy ?? ''}
						/>
					</div>
				</div>
			</div>

			<div className="row pt-5">
				<div className="col-12">
					<div className="pb-3 mb-0 fs-7 fw-bold">
						<FormattedMessage id="STORE.MODAL.DETAILS.CANCELLATION_POLICY_TIME" />
					</div>
				</div>
				<Controller
					name="cancel_max_time_hrs"
					control={control}
					render={({ field }) => (
						<div className="col-12 col-sm-6">
							<select className="form-select form-select-solid" aria-label="Select example" {...register('cancel_max_time_hrs')}>
								{optionsCancellationPolicy.map((policy, index) => (
									<option value={policy.value} key={index}>
										{policy.label}
									</option>
								))}
							</select>
						</div>
					)}
				/>
				<div className="col-12">
					<p className="mb-0 secondary-text fs-7 pt-2">
						<FormattedMessage id="STORE.MODAL.DETAILS.CANCELLATION_POLICY_TXT" />
					</p>
				</div>
			</div>

			<div className="row">
				<div className="col-12">
					<div className="separator my-5" />
				</div>
			</div>

			<div className="row pb-5">
				<div className="col-12">
					<div className="mb-0 fs-2 fw-bold">
						<FormattedMessage id="STORE.MODAL.DETAILS.NOTES" />
					</div>
				</div>
			</div>

			<div className="p-3 generic-border">
				<LanguageSelector language={notesLang} onLanguageChange={handleNotesLanguageChange} />
				<div className="row pt-5">
					<div className="col-12">
						<label htmlFor="notes" className="form-label pb-3 mb-0 fs-7 fw-bold" />
						<textarea
							id="notes"
							name="notes"
							className="full-width-textarea"
							rows="4"
							cols="50"
							{...register(`translations[${notesLang}].notes`)}
							defaultValue={translations[notesLang]?.notes}
							value={translations[notesLang]?.notes ?? ''}
						/>
					</div>
				</div>
			</div>
			<div className="row">
				<div className="col-12">
					<div className="separator my-5" />
				</div>
			</div>

			<div className="row">
				<div className="col-12">
					<Controller
						name="published"
						control={control}
						render={({ field: { value, onChange, ref, name } }) => (
							<div className="row pt-3">
								<div className="col-12">
									<div className="form-check form-check-custom form-check-solid pe-3 pt-md-0 pt-3">
										<input
											ref={ref}
											name={name}
											className="form-check-input"
											type="checkbox"
											id={'published-checkbox'}
											aria-labelledby={`published-checkbox`}
											onChange={onChange}
											checked={value}
											value={value}
										/>
										<label className="form-check-label fs-7" htmlFor="published-checkbox">
											<FormattedMessage id="STORE.TABLE.PUBLIC_LOWERCASE" />
										</label>
									</div>
								</div>
							</div>
						)}
					/>
				</div>
			</div>
			{confirmationModal}
		</>
	);
};

export default LocationSocialTextFields;
