import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { KTSVG } from 'src/_metronic/helpers';
import useModal from 'src/components/modal/useModal';

import { BUSINESS_LANGUAGES } from '../../../../common/constants';
import LanguageSelector from '../../../../components/languageSelector/LanguageSelector';
import { useUploadStoreFilesMutation, useUploadStoreImageMutation } from '../../../../reducers/stores/storeApiSlice';

const TabFiles = ({ setValue, watch }) => {
	const { ref } = useModal();
	const [fileLang, setFileLanguage] = useState(BUSINESS_LANGUAGES[0].code ?? 'en');
	const [uploadImage] = useUploadStoreImageMutation([]);
	const [uploadFile] = useUploadStoreFilesMutation([]);
	const menuFile = watch(`translations[${fileLang}].menu` ?? 'menu');
	const bannerFile = watch('banner');
	const mediaFile = watch('media');
	const getFileName = (file) => {
		if (file.title) {
			return file.title;
		}
		if (file.name) {
			return file.name;
		}
		if (file.url) {
			return file.url;
		}
		return 'Unnamed File';
	};

	const handleUploadFile = async (e) => {
		const formData = new FormData();
		const type = e?.target.files[0]?.type;
		const locale = fileLang;
		const input = e?.target;
		const registry = e?.target.id;
		const files = input.files;

		if (type === 'image/jpg' || type === 'image/jpeg' || type === 'image/png') {
			formData.append('image', files[0]);
			const response = await uploadImage(formData);
			const { id, media } = response.data;

			setValue(registry, [{ _id: id, title: media.title, type: 'image', url: media.url }]);
			return response.data;
		}
		if (type === 'application/pdf') {
			formData.append('file', files[0]);
			formData.append('private_file', true);
			formData.append('locale', fileLang);
			const response = await uploadFile(formData);
			const { id, media } = response.data;
			setValue(registry, [{ _id: id, type: 'file', url: media.url }]);
			return response.data;
		}
	};

	const handleLanguageChange = (language) => setFileLanguage(language);
	const handleDeletion = (file) => setValue(file, []);

	return (
		<div className="modal-content">
			<form data-np-autofill-type="identity" data-np-checked="1" data-np-watching="1" ref={ref}>
				<div className="row pb-5">
					<div className="col-12">
						<div className="mb-0 fs-2 fw-bold">
							<FormattedMessage id="LOCATION.FILES_TAB.MENU_FILES" />
						</div>
					</div>
				</div>
				<div className="p-3 generic-border">
					<LanguageSelector language={fileLang} onLanguageChange={handleLanguageChange} />
					<div className="row mt-5">
						<div className="col-12">
							<div className="d-flex align-items-center">
								<div className="uploader-container fs-5 fw-bold flex-grow-1">
									<div className="uploader-wrapper p-4">
										<input
											type="file"
											id={`translations[${fileLang}].menu`}
											className="custom-checkbox w-100 h-100"
											onChange={handleUploadFile}
											accept="application/pdf, image/jpg, image/jpeg, image/png"
										/>
										<label htmlFor={`translations[${fileLang}].menu`} className="uploader">
											<KTSVG className="svg-icon-4x svg-icon-primary" path="/media/icons/duotune/files/fil009.svg" />
											<div className="uploader-text">
												<span className="fs-bold">
													<FormattedMessage id="FILES.UPLOAD" />
												</span>
												{menuFile && menuFile.length > 0 ? (
													<div className="d-inline-flex align-items-center">
														<span className="fs-7 text-primary">{getFileName(menuFile[0])}</span>
													</div>
												) : (
													<span className="fs-7 text-primary">
														<FormattedMessage id="FILES.DRAG.DROP" />
													</span>
												)}
											</div>
										</label>
									</div>
								</div>

								{menuFile && menuFile.length > 0 ? (
									<div
										className="ms-3 btn btn-icon btn-light-danger"
										style={{ width: '40px', height: '40px' }}
										onClick={(e) => {
											handleDeletion(`translations[${fileLang}].menu`);
										}}
									>
										<KTSVG className="svg-icon-2" path="/media/icons/duotune/general/gen040.svg" />
									</div>
								) : null}
							</div>
						</div>
					</div>
				</div>

				<div className="row">
					<div className="col-12">
						<div className="separator my-5" />
					</div>
				</div>

				<div className="row pb-5">
					<div className="col-12">
						<div className="mb-0 fs-2 fw-bold">
							<FormattedMessage id="LOCATION.FILES_TAB.BANNER" />
						</div>
					</div>
				</div>

				<div className="row">
					<div className="col-12">
						<div className="d-flex align-items-center">
							<div className="uploader-container fs-5 fw-bold flex-grow-1">
								<div className="uploader-wrapper p-4">
									<input
										type="file"
										id={`banner`}
										className="custom-checkbox w-100 h-100"
										onChange={handleUploadFile}
										accept="application/pdf, image/jpg, image/jpeg, image/png"
									/>
									<label htmlFor={`banner`} className="uploader">
										<KTSVG className="svg-icon-4x svg-icon-primary" path="/media/icons/duotune/files/fil009.svg" />
										<div className="uploader-text">
											<span className="fs-bold">
												<FormattedMessage id="FILES.UPLOAD" />
											</span>
											{bannerFile && bannerFile.length > 0 ? (
												<div className="d-inline-flex align-items-center">
													<span className="fs-7 text-primary">{getFileName(bannerFile[0])}</span>
												</div>
											) : (
												<span className="fs-7 text-primary">
													<FormattedMessage id="FILES.DRAG.DROP" />
												</span>
											)}
										</div>
									</label>
								</div>
							</div>

							{bannerFile && bannerFile.length > 0 ? (
								<div
									className="ms-3 btn btn-icon btn-light-danger"
									style={{ width: '40px', height: '40px' }}
									onClick={(e) => {
										handleDeletion(`banner`);
									}}
								>
									<KTSVG className="svg-icon-2" path="/media/icons/duotune/general/gen040.svg" />
								</div>
							) : null}
						</div>
					</div>
				</div>

				<div className="row">
					<div className="col-12">
						<div className="separator my-5" />
					</div>
				</div>

				<div className="row pb-5">
					<div className="col-12">
						<div className="mb-0 fs-2 fw-bold">
							<FormattedMessage id="LOCATION.FILES_TAB.MEDIA_FILES" />
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-12">
						<div className="d-flex align-items-center">
							<div className="uploader-container fs-5 fw-bold flex-grow-1">
								<div className="uploader-wrapper p-4">
									<input
										type="file"
										id={`media`}
										className="custom-checkbox w-100 h-100"
										onChange={handleUploadFile}
										accept="application/pdf, image/jpg, image/jpeg, image/png"
									/>
									<label htmlFor={`media`} className="uploader">
										<KTSVG className="svg-icon-4x svg-icon-primary" path="/media/icons/duotune/files/fil009.svg" />
										<div className="uploader-text">
											<span className="fs-bold">
												<FormattedMessage id="FILES.UPLOAD" />
											</span>
											{mediaFile && mediaFile.length > 0 ? (
												<div className="d-inline-flex align-items-center">
													<span className="fs-7 text-primary">{getFileName(mediaFile[0])}</span>
												</div>
											) : (
												<span className="fs-7 text-primary">
													<FormattedMessage id="FILES.DRAG.DROP" />
												</span>
											)}
										</div>
									</label>
								</div>
							</div>

							{mediaFile && mediaFile.length > 0 ? (
								<div
									className="ms-3 btn btn-icon btn-light-danger"
									style={{ width: '40px', height: '40px' }}
									onClick={(e) => {
										handleDeletion(`media`);
									}}
								>
									<KTSVG className="svg-icon-2" path="/media/icons/duotune/general/gen040.svg" />
								</div>
							) : null}
						</div>
					</div>
				</div>
			</form>
		</div>
	);
};

export default TabFiles;
