import { Tab } from 'bootstrap';
import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { selectUser } from 'src/reducers/auth/slice';

import {
	DrawerComponent,
	MenuComponent,
	ScrollComponent,
	ScrollTopComponent,
	StickyComponent,
	SwapperComponent,
	ToggleComponent,
} from '../assets/ts/components';
import { ThemeModeComponent } from '../assets/ts/layout';
import { useLayout } from './core';

export function MasterInit() {
	const { config } = useLayout();
	const isFirstRun = useRef(true);
	const user = useSelector(selectUser);

	const pluginsInitialization = () => {
		isFirstRun.current = false;
		setTimeout(() => {
			ThemeModeComponent.init();
			ToggleComponent.bootstrap();
			ScrollTopComponent.bootstrap();
			DrawerComponent.bootstrap();
			StickyComponent.bootstrap();
			ScrollComponent.bootstrap();
			SwapperComponent.bootstrap();
			document.querySelectorAll('[data-bs-toggle="tab"]').forEach((tab) => {
				Tab.getOrCreateInstance(tab);
			});
			MenuComponent.bootstrap();
		}, 1000);
	};

	useEffect(() => {
		if (isFirstRun.current) {
			isFirstRun.current = false;
			pluginsInitialization();
		}
	}, [config]);

	useEffect(() => {
		if (user) {
			pluginsInitialization();
		}
	}, [user]);

	return <></>;
}
