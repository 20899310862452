import { ErrorMessage } from '@hookform/error-message';
import clsx from 'clsx';
import React, { useEffect, useRef } from 'react';
import { Control, Controller, DeepMap, FieldError, FieldValues, UseFormWatch } from 'react-hook-form';
import { UseFormSetValue } from 'react-hook-form/dist/types/form';
import { useIntl } from 'react-intl';
import { customStyles } from 'src/common/inputStyles';

import { useDialQuery } from '../../reducers/contactApiSlice';
import Select from './Select';

type Props = {
	register?: (name: string, RegisterOptions?) => { onChange; onBlur; name; ref };
	watch?: UseFormWatch<any>;
	control: Control<any>;
	setValue?: UseFormSetValue<any>;
	name: string;
	title?: string;
	errors: DeepMap<FieldValues, FieldError>;
	disabled?: boolean;
	horizontalPhone?: boolean;
};

const PhoneNumber: React.FC<Props> = ({ watch, control, setValue, name, title, errors, disabled = false, horizontalPhone = false, register }) => {
	// @ts-ignore TODO - Query to be moved to generic API slice (in TS format) and set the Return and Argument type in the builder query
	const { data: dialCodes, isLoading: isLoadingDial } = useDialQuery();

	const number = useRef('');
	const code = useRef('+30');

	const phoneValue = watch ? watch(name) : '';

	useEffect(() => {
		if (!isLoadingDial && phoneValue && dialCodes) {
			const matchingCode = dialCodes.find((item: { value: string }) =>
				phoneValue.startsWith(item.value)
			)?.value;

			if (matchingCode) {
				number.current = phoneValue.slice(matchingCode.length);
				code.current = matchingCode;

				if (setValue) {
					setValue(name, formatPhoneNumber());
				}
			} else {
				number.current = '';
				code.current = '+30';
			}
		}
	}, [isLoadingDial, phoneValue]);

	const intl = useIntl();

	const formatPhoneNumber = () => {
		if (!number.current) {
			return '';
		}

		return `${code.current}${number.current}`;
	};

	return (
		<Controller
			name={name}
			control={control}
			render={({ field: { value, onChange } }) => {
				return (
					<>
						<div className={clsx({ 'row align-items-center': horizontalPhone })}>
							<div className={clsx({ 'col-12 col-sm-3': horizontalPhone })}>
								<div className="form-label pb-3 mb-0 fw-bold fs-7">{title}</div>
							</div>
							<div className={clsx({ 'col-12 col-sm-9': horizontalPhone })}>
								<div className="input-group">
									<div className="row gx-3">
										<div className="col-5">
											<Select
												value={{ value: code.current ?? '+30', label: code.current ?? '+30' }}
												onChange={(e) => {
													code.current = e.value;
													if (setValue) {
														setValue(name, formatPhoneNumber());
													}
												}}
												options={dialCodes}
												defaultValue={{ value: code.current, label: code.current }}
												isDisabled={disabled}
												horizontalPhone={horizontalPhone}
												styles={customStyles}
											/>
										</div>

										<div className="col-7">
											<input
												type="text"
												className="form-control fs-6 fw-bold p-4"
												placeholder={intl.formatMessage({ id: 'PLACEHOLDER.PHONE_NUMBER' })}
												value={number.current}
												onChange={(e) => {
													number.current = e.target.value;
													if (setValue) {
														setValue(name, formatPhoneNumber());
													}
												}}
												disabled={disabled}
												style={disabled ? { background: '#e0e2e2' } : undefined}
											/>
										</div>
										<input
											type="hidden"
											{...(register ? register(name) : [])}
											defaultValue={number.current !== '' ? formatPhoneNumber() : ''}
										/>
										{errors && (
											<ErrorMessage
												errors={errors}
												name={name}
												render={({ message }) => (
													<div className="fv-plugins-message-container">
														<div className="fv-help-block">
															<span role="alert">{message}</span>
														</div>
													</div>
												)}
											/>
										)}
									</div>
								</div>
							</div>
						</div>
					</>
				);
			}}
		/>
	);
};

export default PhoneNumber;
