/* eslint-disable prefer-template */
import apiSlice, { API_ROOT } from './services/api';

const basePath = '/files';

const fileApiSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		files: builder.query({
			query: (id) => {
				let url = API_ROOT;
				if (id) {
					url += `/files/${id}`;
				} else {
					url += '/files';
				}
				return url;
			},
			providesTags: ['Files'],
		}),
		uploadFiles: builder.mutation({
			queryFn: (formData) => ({
				url: API_ROOT + basePath + '/upload',
				method: 'POST',
				body: formData,
			}),
		}),
		uploadImage: builder.mutation({
			query: (formData) => ({
				url: API_ROOT + basePath + '/upload-image',
				method: 'POST',
				body: formData,
			}),
		}),
		deleteImage: builder.mutation({
			query: (args) => ({
				url: API_ROOT + basePath + '/' + args.id,
				method: 'DELETE',
			}),
		}),
	}),
});

const { useFilesQuery, useDeleteImageMutation, useUploadFilesMutation, useUploadImageMutation } = fileApiSlice;

export { useDeleteImageMutation, useFilesQuery, useUploadFilesMutation, useUploadImageMutation };
