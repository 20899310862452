import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { useFieldArray } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import ReactSelect, { components, OptionProps, PlaceholderProps } from 'react-select';
import { customStyles } from 'src/common/inputStyles';

import customer from '../../../pages/analytics/customer/Customer';
import { selectActiveStore } from '../../../reducers/business/businessSlice';

type StoresListFields = {
	_id?: string;
	type?: string;
	init_type?: string;
	edited?: Boolean;
	wasntCustomer?: Boolean;
};

const StoresList = ({ control, name, title, stores, disabled = false }) => {
	const [initStoreList, setInitStoreList] = useState({});
	const { fields, remove, update, append } = useFieldArray({
		control,
		name,
		shouldUnregister: true,
	});

	const intl = useIntl();

	const { _id: sid } = useSelector(selectActiveStore);

	const storelistFields = fields as Array<StoresListFields>;
	const options = stores.map(({ _id, title }) => {
		const fieldIdx = storelistFields.findIndex((field) => field._id === _id);
		const store = storelistFields[fieldIdx];

		// store to state initial values
		if (store && !store.edited && !initStoreList[_id]) {
			initStoreList[_id] = store.type;
			setInitStoreList(initStoreList);
		}
		const isLead = store?.type === 'lead' || (!store?.type && _id === sid && window.location.pathname === '/contacts/leads');
		const isCustomer = store?.type === 'customer' || (!store?.type && _id === sid && window.location.pathname === '/contacts/customers');
		const wasLead = initStoreList[_id] === 'lead';
		const wasCustomer = initStoreList[_id] === 'customer';

		return { value: _id, label: title, fieldIdx, isCustomer, isLead, wasLead, wasCustomer };
	});

	const Placeholder = (props: PlaceholderProps<StoreOption>) => {
		const selected = options.find((s) => s.value === sid);
		return (
			<components.Placeholder {...props}>
				<div className=" d-flex justify-content-start">
					<div className="ms-2 me-auto">
						<div className="fw-bold">{selected?.label}</div>
					</div>
					<div className="me-1">
						{/* <span className="badge badge-light-success fs-6 fw-bold"> */}
						<span className="primary-text fs-6 fw-bold">
							{selected?.isLead && intl.formatMessage({ id: 'CONTACTS.LEAD' })}
							{selected?.isCustomer && intl.formatMessage({ id: 'CONTACTS.CUSTOMER' })}
						</span>
					</div>
				</div>
			</components.Placeholder>
		);
	};

	const Option = ({ isDisabled, innerProps, ...props }: OptionProps<StoreOption>) => {
		const { value, label, isLead, isCustomer, fieldIdx, wasCustomer, wasLead } = props.data;
		return (
			<div>
				<components.Option innerProps={{}} isDisabled={true} {...props}>
					<div onClick={(e) => e.stopPropagation()}>
						<div className="list-group-item d-flex justify-content-between align-items-center">
							<div className="ms-2 me-auto">
								<div className="fw-bold">{label}</div>
							</div>
							{/* if initial value was customer, show customer badge */}
							{wasCustomer && (
								<span className="primary-text fs-6 fw-bold me-2">
									<FormattedMessage id="CONTACTS.CUSTOMER" />
								</span>
							)}
							{/* if initial value was lead, show lead badge */}
							{wasLead && (
								<span className="primary-text fs-6 fw-bold me-2">
									<FormattedMessage id="CONTACTS.LEAD" />
								</span>
							)}

							{/* if not disabled and initial value wasn't lead or customer, show "to lead" button */}
							{!disabled && !wasLead && !wasCustomer && (
								<button
									className={clsx('px-3 fs-6 text-nowrap py-2 me-2', { 'primary-button': isLead, 'generic-button': !isLead })}
									type="button"
									onClick={() => {
										if (isLead) remove(fieldIdx);
										else if (fieldIdx >= 0) update(fieldIdx, { _id: value, type: 'lead', edited: true });
										else append({ _id: value, type: 'lead', edited: true });
									}}>
									<FormattedMessage id="CONTACTS.LEAD" />
								</button>
							)}

							{/* if not disabled and initial value wasn't customer, show "to customer" button */}
							{!disabled && !wasCustomer && (
								<button
									className={clsx('px-3 fs-6 text-nowrap py-2 me-2', {
										'primary-button': isCustomer,
										'generic-button': !isCustomer,
									})}
									type="button"
									onClick={() => {
										if (isCustomer) remove(fieldIdx);
										else if (fieldIdx >= 0) update(fieldIdx, { _id: value, type: 'customer', edited: true });
										else append({ _id: value, type: 'customer', edited: true });
									}}>
									<FormattedMessage id="CONTACTS.CUSTOMER" />
								</button>
							)}
						</div>
					</div>
				</components.Option>
			</div>
		);
	};

	return (
		<>
			<label className="form-label pb-3 mb-0 fs-7 fw-bold">{title}</label>
			<ReactSelect
				placeholder="placeholder"
				components={{ Option, Placeholder }}
				options={options}
				styles={customStyles}
				closeMenuOnSelect={false}
				isSearchable={true}
			/>
		</>
	);
};

type StoreOption = {
	value: string;
	label: string;
	fieldIdx: number;
	isCustomer: Boolean;
	isLead: Boolean;
	wasLead: Boolean;
	wasCustomer: Boolean;
};

export default StoresList;
