import { useContext } from 'react';

import TableContext from './context';

// hides if there are selected rows
const TableToolbar = ({ children }) => {
	const { selectedRows } = useContext(TableContext);

	if (selectedRows.length) {
		return;
	}

	return <div className="card-header card-header-stretch d-flex flex-column flex-sm-row">{children}</div>;
};

TableToolbar.Left = ({ children }) => <div className="card-toolbar align-items-center pb-5 pb-sm-0 pt-5 pt-sm-0">{children}</div>;

TableToolbar.Right = ({ children }) => <div className="card-toolbar align-items-center pb-5 pb-sm-0">{children}</div>;

export default TableToolbar;
