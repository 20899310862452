import React, { useEffect, useRef } from 'react';

// Helper function to check if an object or array is empty
export const isEmpty = (data: string | { [s: string]: unknown } | ArrayLike<unknown> | object) => {
	return typeof data === 'object'
		? Object.keys(data).length === 0 || Object.values(data).some((element) => element === undefined)
		: Array.isArray(data) && data.length === 0;
};

export const useEscKey = (enable: boolean, callback: () => void) => {
	useEffect(() => {
		const handleKeyDown = (event) => {
			if (event.key === 'Enter') {
				event.preventDefault();
				event.stopPropagation();
				callback();
				window.removeEventListener('keydown', handleKeyDown);
			}
		};

		if (enable) {
			return window.addEventListener('keydown', handleKeyDown);
		} else {
			window.removeEventListener('keydown', handleKeyDown);
		}

		return () => {
			window.removeEventListener('keydown', handleKeyDown);
		};
	}, [enable]);
};

const flattenErrors = (obj, prefix = '') => {
	let result = {};

	for (const key in obj) {
		if (typeof obj[key] === 'object' && obj[key] !== null && !obj[key].message) {
			// Handle nested objects, including arrays
			if (Array.isArray(obj[key])) {
				// Handle array fields (from useFieldArray)
				obj[key].forEach((item, index) => {
					if (item && typeof item === 'object') {
						const nestedErrors = flattenErrors(item, `${prefix}${key}.${index}.`);
						result = { ...result, ...nestedErrors };
					}
				});
			} else {
				// Handle regular nested objects
				const nestedErrors = flattenErrors(obj[key], `${prefix}${key}.`);
				result = { ...result, ...nestedErrors };
			}
		} else {
			// Handle the error object itself
			result[`${prefix}${key}`] = obj[key];
		}
	}
	return result;
};

export const scrollOnError = (errors) => {
	// Flatten nested errors including fieldArray errors
	const flatErrors = flattenErrors(errors);
	const errorFieldNames = Object.keys(flatErrors);

	if (errorFieldNames.length > 0) {
		const firstErrorKey = errorFieldNames[0];
		let selector;

		// Handle field array format (like experience.0.company)
		if (firstErrorKey.includes('.')) {
			const parts = firstErrorKey.split('.');
			if (parts.length === 3) {
				// Format: arrayName.index.fieldName
				const [arrayName, index, fieldName] = parts;
				selector = `${arrayName}[${index}].text`;
			} else {
				selector = firstErrorKey;
			}
		} else {
			selector = firstErrorKey;
		}
		// Try to find element by name attribute
		const firstErrorField = document.querySelector(`[name="${selector}"]`);

		if (firstErrorField?.parentElement) {
			// Scroll to the element with smooth behavior
			firstErrorField.parentElement.scrollIntoView({
				behavior: 'smooth',
				block: 'center',
			});

			// Optional: focus on the field with error
			firstErrorField.parentElement.focus();
		}
	}
};
