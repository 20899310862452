import { getSessionStorage } from 'src/common/storage';
import { AppSection } from 'src/pages/analytics/tag/api/tagApiSlice';
import apiSlice from 'src/reducers/services/api';

import { Language, LanguagesResponse } from './types';

const { REACT_APP_API_X_LOCALE: X_LOCALE } = process.env;

const appSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		getAppSections: builder.query({
			keepUnusedDataFor: 60 * 60 * 1000, // 1 hour
			query: () => `app/app-sections`,
			transformResponse: (response: AppSection[]) => {
				return response;
			},
			providesTags: ['AppSections'],
		}),
		getVisibleApps: builder.query({
			keepUnusedDataFor: 60 * 60 * 1000, // 1 hour
			query: () => ({
				url: 'app/visible-apps',
			}),
			transformResponse: (response: { apps?: { paths: { path: string; access: any }[] }[] }) => {
				if (response?.apps) {
					let apps = {};
					for (const app of response.apps) {
						for (const path of app.paths) {
							apps[path.path.slice(1)] = path.access;
						}
					}

					return apps;
				}

				return response;
			},
		}),
		getTranslations: builder.query({
			query: (args) => {
				return {
					url: 'app/translation-keys',
					method: 'GET',
					params: args,
				};
			},
			transformResponse: (response: { translations: any }) => {
				const language = getSessionStorage('language');
				const translation: { lang: any; translations: any; lastFetched?: number } = {
					lang: language ?? X_LOCALE,
					translations: response.translations,
				};
				translation.lastFetched = Date.now();
				localStorage.setItem('translations', JSON.stringify(translation));
				return translation;
			},
		}),
		getTranslationLangs: builder.query<Language[], Record<string, unknown>>({
			query: () => {
				return {
					method: 'GET',
					url: 'app/translation-languages',
				};
			},
			transformResponse: (response: LanguagesResponse) => {
				return response.languages;
			},
		}),
	}),
});

export const { useGetAppSectionsQuery, useGetVisibleAppsQuery, useGetTranslationsQuery, useGetTranslationLangsQuery } = appSlice;
