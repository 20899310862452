import { useEffect, useMemo, useState } from 'react';

import { API_DEFAULT_SIZE } from '../../common/constants';
import Filters from '../filters/Filters';
import TableContext from './context';
import usePaging from './hooks/usePaging';

const stripLocalFields = (obj) => {
	const newObj = { ...obj };
	if (newObj.sort) {
		delete newObj.sort.localSort;
		delete newObj.sort.direction;
		delete newObj.sort.field;
	}

	return newObj;
};

const useTable = ({
	title,
	tableName,
	selectable,
	filterOptions,
	content,
	useQuery,
	tagConfig = {},
	additionalContextValues,
	useQueryArgs = {},
	onRowClick,
}) => {
	const [filters, setFilters] = useState({});
	const [tableControls, setTableControls] = useState({});
	const [search, setSearch] = useState();
	const [view, setView] = useState({});
	const [selectedRows, setSelectedRows] = useState([]);
	const args = {
		query: {
			...useQueryArgs.query, // arguments coming as useTable params
			...filters, // filters coming from filter menu
			...(tableControls.sort?.localSort ? {} : stripLocalFields(tableControls)), //remove unnecessary fields for API
			search, //search rows term
			...view, // table view settings coming from TableContext content component
		},
		path: { ...useQueryArgs.path },
		body: { ...useQueryArgs.body },
		variation: useQueryArgs.variation,
		reference: useQueryArgs.reference,
		b: useQueryArgs.b,
		tagConfig: { ...tagConfig }, // tagConfig coming as useTable params
	};
	const { data = [], isLoading, isSuccess, isFetching } = useQuery(args);
	const items = tableName ? data[tableName] : data; // Use data when there is no tableName
	const totalRows = data?.count ?? 0;
	const getNestedValue = (obj, path) => {
		return path.split('.').reduce((current, key) => {
			return current?.[key];
		}, obj);
	};

	const sortedItems = useMemo(() => {
		if (!tableControls.sort?.localSort || !items?.length) {
			return items;
		}

		const { field, direction } = tableControls.sort;

		if (typeof tableControls.sort?.localSort === 'function') {
			return tableControls.sort.localSort([...items], direction);
		}
		return [...items].sort((a, b) => {
			let aValue = getNestedValue(a, field);
			let bValue = getNestedValue(b, field);

			// Handle different data types
			if (typeof aValue === 'string') {
				return direction === 'asc' ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
			}

			if (typeof aValue === 'number' || typeof bValue === 'number') {
				if (isNaN(aValue)) {
					aValue = -Infinity;
				}
				if (isNaN(bValue)) {
					bValue = -Infinity;
				}

				return direction === 'asc' ? aValue - bValue : bValue - aValue;
			}

			// Handle dates
			if (aValue instanceof Date) {
				return direction === 'asc' ? aValue.getTime() - bValue.getTime() : bValue.getTime() - aValue.getTime();
			}

			// Default comparison for other types

			return direction === 'asc' ? String(aValue).localeCompare(String(bValue)) : String(bValue).localeCompare(String(aValue));
		});
	}, [items, tableControls.sort, data]);

	const [showing, paging, showingItems] = usePaging(sortedItems, totalRows);

	// Automatically go one page less if the current page has no data
	useEffect(() => {
		if (showingItems.length === 0 && paging.currentPage > 1) {
			paging.actions.goTo(paging.currentPage - 1);
			handleTableControls({ ...tableControls, page: paging.currentPage - 1 });
		}
	}, [showingItems, paging]);

	const goToFirstPage = paging?.actions?.goToFirst;

	const handleSetFilters = (newFilters) => {
		goToFirstPage();
		setSearch();
		setTableControls({});
		setFilters(newFilters);
	};

	const handleSetSearch = (newSearch) => {
		goToFirstPage();
		setSearch(newSearch);
		setTableControls({});
	};

	const handleSetView = (newView) => {
		goToFirstPage();
		setView(newView);
		setSearch();
		setTableControls({});
	};

	const manualSelectRows = (rowIds) => {
		setSelectedRows(rowIds);
	};

	const handleTableControls = (newControls) => {
		setTableControls(newControls);
	};

	const handleSelectRows = (rowIds) => {
		setSelectedRows((prevSelected) => {
			rowIds.forEach((rowId) => {
				const isSelected = prevSelected.includes(rowId);

				if (isSelected) {
					// If the element is already selected, remove it from the array
					prevSelected = prevSelected.filter((item) => item !== rowId);
				} else {
					// If the element is not selected, add it to the array
					prevSelected = [...prevSelected, rowId];
				}
			});

			return prevSelected;
		});
	};

	const filtersMenu = () => filterOptions && <Filters filters={filterOptions} onApply={handleSetFilters} onReset={handleSetFilters} />;
	const tableContextValues = {
		title,
		items: showingItems,
		totalRows,
		selectable,
		isLoading,
		isSuccess,
		onRowClick,
		showing,
		paging,
		querySize: API_DEFAULT_SIZE,
		tableControls: tableControls,
		onTableControlsChange: handleTableControls,
		search,
		setSearch: handleSetSearch,
		view,
		onViewChange: handleSetView,
		manualSelectRows,
		selectedRows,
		loading: isLoading || isFetching,
		selectRows: handleSelectRows,
		filterOptions,
		tagConfig,
		...additionalContextValues,
	};

	const table = <TableContext.Provider value={tableContextValues}>{content}</TableContext.Provider>;

	return {
		table,
		loading: isLoading || isFetching,
		filtersMenu,
	};
};

export default useTable;
