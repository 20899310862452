import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import jwtDecode from 'jwt-decode';

import { isEmptyObj } from '../../common/misc';
import { getLocalStorage, getSessionStorage, isExpiredJWT, setLocalStorage } from '../../common/storage';
import { logOut, setUser } from '../auth/slice';

export const {
	REACT_APP_API_URL: API_ROOT,
	REACT_APP_API_X_LOCALE: X_LOCALE,
	REACT_APP_VERSION: APP_VERSION,
	REACT_APP_API_VERSION: API_VERSION,
} = process.env;

const B2BBasePath = '/b2b';
const baseUrl = API_ROOT + B2BBasePath;

const defaultHeaders = (headers) => {
	X_LOCALE && headers.set('X-Locale', X_LOCALE);
	API_VERSION && headers.set('X-API-Version', API_VERSION);
	APP_VERSION && headers.set('X-APP-Version', APP_VERSION);

	return headers;
};

export const baseQuery = fetchBaseQuery({
	baseUrl,
	prepareHeaders: (headers, { getState, endpoint }) => {
		defaultHeaders(headers);

		const language = getSessionStorage('language');
		if (language) {
			headers.set('X-Locale', language);
		}
		// Login should not send back bearer token
		const bearerExcluded = ['getTranslations', 'login'];
		if (!bearerExcluded.includes(endpoint)) {
			const accessToken = getLocalStorage('accessToken');
			if (accessToken) {
				headers.set('Authorization', `Bearer ${accessToken}`);
			}
		}

		const currentUser = JSON.parse(getLocalStorage('user'));
		const activeBusiness = getState().business.activeBusiness;
		if (activeBusiness && currentUser) {
			headers.set('X-Business-Id', activeBusiness._id);
		}

		return headers;
	},
});

const refreshTokenBaseQuery = fetchBaseQuery({
	baseUrl,
	prepareHeaders: (headers) => {
		defaultHeaders(headers);

		const language = getSessionStorage('language');
		if (language) {
			headers.set('X-Locale', language);
		}
		const refreshToken = getLocalStorage('refreshToken');
		if (refreshToken) {
			headers.set('Authorization', `Bearer ${refreshToken}`);
		}

		return headers;
	},
});

let isRefreshing = false;
let refreshPromise = null;

export const baseQueryWithReauth = async (args, api, extraOptions) => {
	const AUTH_PATH = '/auth/';
	const pathname = window.location?.pathname || '/';
	// First attempt
	let result = await baseQuery(args, api, extraOptions);

	// Add metadata if needed
	if (result?.data) {
		result.data._api = api;
		result.data._extraOptions = extraOptions;
	}

	// Handle 401 error (Unauthorized)
	if (result?.error?.status === 401 && !pathname?.includes(AUTH_PATH)) {
		const refreshToken = getLocalStorage('refreshToken');
		const jwt = refreshToken ? jwtDecode(refreshToken) : {};

		// Check if refresh token is valid
		if ((isEmptyObj(jwt) || isExpiredJWT(jwt)) && !pathname?.includes(AUTH_PATH)) {
			api.dispatch(logOut());
			return result; // Return the original error result
		}

		// Start token refresh or wait for existing refresh
		try {
			let refreshResult;

			if (!isRefreshing) {
				// Start a new refresh process
				isRefreshing = true;
				refreshPromise = refreshTokenBaseQuery(
					{
						url: '/auth/refresh',
						method: 'POST',
					},
					api,
					extraOptions
				);
				refreshResult = await refreshPromise;
			} else {
				// Wait for the existing refresh process
				refreshResult = await refreshPromise;
			}
			// Handle refresh error
			if (refreshResult?.error) {
				if (!pathname?.includes(AUTH_PATH)) {
					api.dispatch(logOut());
				}
				return result; // Return the original error result
			}

			// Store new tokens
			const user = refreshResult.data;
			const { access_token: access, refresh_token: refresh } = user;
			setLocalStorage('user', JSON.stringify(user));
			setLocalStorage('accessToken', access);
			setLocalStorage('refreshToken', refresh);
			api.dispatch(setUser(user));

			// Retry the original request with new token
			// You may need to update the authorization header in args if needed

			try {
				if (args.headers) {
					args.headers.Authorization = `Bearer ${access}`;
				} else {
					args.headers = { Authorization: `Bearer ${access}` };
				}
			} catch {
				let config = { url: args, headers: { ...(args.headers || {}), Authorization: `Bearer ${access}` } };
				return await baseQuery(config, api, extraOptions);
			}

			// Retry the original request with the new token
			return await baseQuery(args, api, extraOptions);
		} catch (error) {
			return result; // Return the original error result on any exception
		} finally {
			// Only the refresh initiator should reset these flags
			if (isRefreshing && refreshPromise) {
				isRefreshing = false;
				refreshPromise = null;
			}
		}
	}

	return result;
};

const apiSlice = createApi({
	baseQuery: baseQueryWithReauth,
	keepUnusedDataFor: 0,
	endpoints: (builder) => ({}),
	tagTypes: [
		'Areas',
		'AreaTables',
		'Blocking',
		'Business',
		'Reservation',
		'ReservationDetails',
		'ReservationPreferences',
		'Location',
		'Files',
		'Tags',
		'TagCategories',
		'assignedTags',
		'Contact',
		'ElenxisSettings',
		'Stores',
		'StoreTraits',
		'StoreDetails',
		'TranslationLanguages',
		'Events',
		'Tables',
		'Tickets',
		'Ticket-Types',
		'Feedback',
		'AppSections',
	],
});

export default apiSlice;
