import { skipToken } from '@reduxjs/toolkit/dist/query';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { PhoneNumber } from 'src/components/input';
import { scrollOnError } from 'src/helpers/utils';

import { useDebounce } from '../../../_metronic/helpers';
import { useSearchContactsQuery } from '../../../reducers/contactApiSlice';

const AddGuests = ({ onAddGuest, people, guestList, openDropdownValue, onOpenDropdownChange, editingGuest, setEditingGuest }) => {
	const [showInputFields, setShowInputFields] = useState(false);
	const [guestId, setGuestId] = useState('');
	const [guestsType, setGuestsType] = useState('');
	const [guestSearch, setGuestSearch] = useState('');
	const debouncedSearchTerm = useDebounce(guestSearch, 500);

	const intl = useIntl();

	const {
		control,
		watch,
		register,
		handleSubmit,
		setValue,
		formState: { errors },
		reset: resetGuest,
	} = useForm({
		shouldFocusError: false,
		shouldUseNativeValidation: false,
	});
	const firstnameValue = watch('firstname');
	const lastnameValue = watch('lastname');
	const emailValue = watch('email');

	const isAddDisabled = !(firstnameValue || lastnameValue || emailValue);

	const handleGuestSearchChange = (event) => {
		setGuestSearch(event.target.value);
	};

	useEffect(() => {
		onOpenDropdownChange(openDropdownValue);
	}, [onOpenDropdownChange, openDropdownValue]);

	useEffect(() => {
		if (editingGuest !== null) {
			setShowInputFields(true);
			setValue('firstname', editingGuest.firstname);
			setValue('lastname', editingGuest.lastname);
			setValue('email', editingGuest.email);
			setValue('phoneNumber', editingGuest.phoneNumber);
		}
	}, [editingGuest, setValue, showInputFields]);

	const togglePhoneNumberDropdown = () => {
		onOpenDropdownChange(openDropdownValue === 'hiddenPhoneNumber' ? '' : 'hiddenPhoneNumber');
	};

	const handleAddGuestsButton = () => {
		setShowInputFields(true);
	};

	const handleCancelGuestsButton = () => {
		setShowInputFields(false);
		setEditingGuest(null);
		resetGuest({
			firstname: '',
			lastname: '',
			email: '',
			phoneNumber: '',
		});
	};

	const onSubmit = (data) => {
		if (editingGuest) {
			data.id = editingGuest.id;
			onAddGuest(data, true);
			setEditingGuest(null);
		} else {
			data.id = guestId !== null ? guestId : data.phoneNumber;
			data.type = guestsType === '' ? 'new_contact' : guestsType;
			onAddGuest(data, false);
		}
		setShowInputFields(false);
		setGuestId('');
		setGuestsType('');
		resetGuest({
			firstname: '',
			lastname: '',
			email: '',
			phoneNumber: '',
		});
	};

	const args = debouncedSearchTerm.length >= 3 ? { query: { search: debouncedSearchTerm } } : skipToken;
	const { data: availableGuests = [], isSuccess: isContactSuccess } = useSearchContactsQuery(args);

	const handleGuestContactClick = (contact) => {
		setValue('firstname', contact.fname);
		setValue('lastname', contact.lname);
		setValue('email', contact.email);
		setValue('phoneNumber', contact.phone);
		setGuestSearch('');
		setGuestId(contact.id);
		setGuestsType('contact');
	};

	const handleGuestUserClick = (user) => {
		setValue('firstname', user.fname);
		setValue('lastname', user.lname);
		setValue('email', user.email);
		setValue('phoneNumber', user.mobile);
		setGuestSearch('');
		setGuestId(user.id);
		setGuestsType('tabol_user');
	};

	return (
		<>
			{showInputFields && (
				<>
					<div className="row pb-5">
						{editingGuest === null && (
							<div className="col-12">
								<div className="input-group icon-input guest-search">
									<div className="custom-input-icon icon-left d-flex align-items-center px-6 fw-bold">
										<i className="fa-solid fa-magnifying-glass search-icon" />
									</div>
									<input
										type="text"
										className="form-control fs-6 p-4 ms-0 w-auto"
										data-role="tagsinput"
										id="search-other-guests"
										name="search-other-guests"
										aria-labelledby="search-other-guests"
										placeholder={intl.formatMessage({ id: 'PLACEHOLDER.SEARCH_GUEST' })}
										value={guestSearch}
										onChange={handleGuestSearchChange}
									/>
								</div>
							</div>
						)}

						{isContactSuccess && (
							<div className="row mt-2">
								{availableGuests.contacts.length > 0 && (
									<div className="list-group">
										<h3>
											<FormattedMessage id="RESERVATION.ADD_GUEST.CONTACTS" />
										</h3>
										{availableGuests.contacts.map((contact) => (
											<div
												key={contact.id}
												className="list-group-item list-group-item-action"
												onClick={() => handleGuestContactClick(contact)}
											>
												{contact.name} - {contact.phone}
											</div>
										))}
									</div>
								)}
								{availableGuests.users.length > 0 && (
									<div className="list-group">
										<div className="my-5 horizontal-separator" />
										<h3>
											<FormattedMessage id="RESERVATION.ADD_GUEST.USERS" />
										</h3>
										{availableGuests.users.map((user) => (
											<div
												key={user.id}
												className="list-group-item list-group-item-action"
												onClick={() => handleGuestUserClick(user)}
											>
												{user.fname} {user.lname} - {user.mobile}
											</div>
										))}
									</div>
								)}
							</div>
						)}
					</div>

					<div className="row pb-5">
						<div className="col-12 col-sm-6">
							<div className="input-group">
								<label htmlFor="other-guest-firstname" className="form-label pb-3 mb-0 fs-7 fw-bold required">
									<FormattedMessage id="RESERVATION.ADD_GUEST.FIRSTNAME" />
								</label>
								<input
									type="text"
									id="other-guest-firstname"
									name="other-guest-firstname"
									aria-labelledby="otherguest-name"
									className="form-control fs-6 p-4"
									placeholder={intl.formatMessage({ id: 'PLACEHOLDER.FIRST_NAME' })}
									data-np-autofill-type="other-guest-firstname"
									{...register('firstname')}
								/>
								{errors?.firstname && (
									<div className="fv-plugins-message-container">
										<div className="fv-help-block">
											<span role="alert">{errors.firstname.message}</span>
										</div>
									</div>
								)}
							</div>
						</div>
						<div className="col-12 col-sm-6">
							<div className="input-group">
								<label htmlFor="other-guest-lastname" className="form-label pb-3 pt-5 pt-sm-0 mb-0 fs-7 fw-bold">
									<FormattedMessage id="RESERVATION.ADD_GUEST.LASTNAME" />
								</label>
								<input
									type="text"
									className="form-control fs-6 p-4"
									id="other-guest-lastname"
									name="other-guest-lastname"
									aria-labelledby="other-guest-lastname"
									placeholder={intl.formatMessage({ id: 'PLACEHOLDER.LAST_NAME' })}
									data-np-autofill-type="other-guest-lastname"
									{...register('lastname')}
								/>
								{errors?.lastname && (
									<div className="fv-plugins-message-container">
										<div className="fv-help-block">
											<span role="alert">{errors.lastname.message}</span>
										</div>
									</div>
								)}
							</div>
						</div>
					</div>
					<div className="row pb-5">
						<div className="col-12 col-sm-6">
							<div className="input-group">
								<label htmlFor="other-guest-email" className="form-label pb-3 mb-0 fs-7 fw-bold">
									<FormattedMessage id="RESERVATION.ADD_GUEST.EMAIL" />
								</label>
								<input
									type="email"
									id="other-guest-email"
									name="other-guest-email"
									className="form-control fs-6 p-4"
									aria-labelledby="other-guest-email"
									placeholder={intl.formatMessage({ id: 'PLACEHOLDER.EMAIL' })}
									{...register('email')}
								/>
								{errors?.email && (
									<div className="fv-plugins-message-container">
										<div className="fv-help-block">
											<span role="alert">{errors.email.message}</span>
										</div>
									</div>
								)}
							</div>
						</div>
						<div className="col-12 col-sm-6">
							<div className="input-group">
								<PhoneNumber
									control={control}
									setValue={setValue}
									watch={watch}
									name="phoneNumber"
									errors={errors}
									register={register}
									title={intl.formatMessage({ id: 'RESERVATION.ADD_GUEST.PHONENUMBER' })}
								/>
							</div>
						</div>
					</div>

					<div className="row">
						<div className="col-12">
							<div className="d-flex justify-content-end">
								<button
									type="submit"
									disabled={isAddDisabled || (guestList.length === people && editingGuest === null)}
									className="secondary-button fw-bold fs-6 px-5 py-3 me-5"
									onClick={handleSubmit(onSubmit, scrollOnError)}
								>
									{editingGuest === null ? (
										<FormattedMessage id="RESERVATION.ADD_GUEST.ADD" />
									) : (
										<FormattedMessage id="REPORTS.SAVE" />
									)}
								</button>

								<button type="button" onClick={handleCancelGuestsButton} className="delete-button fw-bold px-5 py-3 fs-6">
									<FormattedMessage id="GENERAL.CANCEL" />
								</button>
							</div>
						</div>
					</div>
				</>
			)}

			{!showInputFields && (
				<>
					<div className="row">
						<div className="col-12 col-sm-3">
							<button
								type="button"
								onClick={handleAddGuestsButton}
								disabled={guestList.length === people}
								className={`secondary-button fw-bold fs-6 w-100 ${guestList === people && 'disabled'}`}
							>
								<FormattedMessage id="RESERVATION.ADD_GUEST.ADD_GUEST" />
							</button>
						</div>
					</div>
				</>
			)}
		</>
	);
};

export default AddGuests;
