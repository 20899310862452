import clsx from 'clsx';
import { Fragment, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { setShowModal } from 'src/reducers/modalSlice';

import { useGetBusinessByRoleQuery } from '../../../../../reducers/business/businessApiSlice';
import {
	selectActiveBusiness,
	selectActiveStore,
	selectBusinesses,
	setActiveBusiness,
	setActiveBusinessStore,
	setActiveStore,
} from '../../../../../reducers/business/businessSlice';
import { MenuButton } from '../../header/header-menus/MenuButton';
import { MenuInnerWithSub } from '../../header/header-menus/MenuInnerWithSub';
import { MenuItem } from '../../header/header-menus/MenuItem';
import SidebarMenuMain from './SidebarMenuMain';

const SidebarMenu = () => {
	const intl = useIntl();
	const dispatch = useDispatch();

	const [selectedBusiness, setSelectedBusiness] = useState(null);
	const [openDropdownStores, setOpenDropdownStores] = useState(false);

	const dropDownRef = useRef<HTMLDivElement>(null);
	const openDropdownStoresRef = useRef(openDropdownStores);

	const businesses = useSelector(selectBusinesses);
	const activeStore = useSelector(selectActiveStore);
	const activeBusiness = useSelector(selectActiveBusiness);

	const { isSuccess } = useGetBusinessByRoleQuery({ skip: businesses?.length > 0 });

	const handleChangeStore = () => {
		const selectedBusiness = businesses.find((b) => b._id === activeBusiness._id);
		if (selectedBusiness) {
			const hasStore = selectedBusiness.stores?.length ?? 0 > 0;
			if (!hasStore) {
				dispatch(
					setActiveBusiness({
						business: {
							_id: selectedBusiness._id!,
							title: selectedBusiness.title,
							logo: selectedBusiness.logo,
						},
					})
				);
				dispatch(setActiveStore(null));
				return;
			}
			const selectedStore = selectedBusiness.stores[0];

			dispatch(
				setActiveBusinessStore({
					store: {
						_id: selectedStore._id!,
						title: selectedStore.title,
					},
					business: {
						_id: selectedBusiness._id!,
						title: selectedBusiness.title,
						logo: selectedBusiness.logo,
					},
				})
			);
		}
	};

	const handleChangeActiveBusiness = (business) => {
		setSelectedBusiness(business);

		dispatch(
			setActiveBusiness({
				business: {
					_id: business._id!,
					title: business.title,
					logo: business.logo,
				},
			})
		);
		dropDownRef.current && dropDownRef!.current!.click();
	};

	useEffect(() => {
		if (selectedBusiness && activeBusiness && (selectedBusiness as any)?._id === activeBusiness._id) {
			handleChangeStore();
		}

		return () => {
			setSelectedBusiness(null);
		};
	}, [activeBusiness, selectedBusiness]);

	useEffect(() => {
		openDropdownStoresRef.current = openDropdownStores;
	}, [openDropdownStores]);

	useEffect(() => {
		const listener = (e: MouseEvent) => {
			if (openDropdownStoresRef.current) {
				const target = e.target as HTMLElement;
				if (!target.closest('.menu-item')) {
					const trigger = document.querySelector('.app-sidebar-business-menu > .menu-item > .menu-link') as HTMLElement;
					const menuSub = document.querySelector('.app-sidebar-business-menu > .menu-item > .menu-sub') as HTMLElement;

					if (trigger && menuSub && menuSub.classList.contains('show')) {
						trigger.click();
					}

					setOpenDropdownStores(false);
				}
			}
		};

		document.addEventListener('click', listener);
		return () => document.removeEventListener('click', listener);
	}, [activeBusiness]);

	return (
		<>
			<div
				ref={dropDownRef}
				className="app-sidebar-business-menu menu menu-column menu-rounded menu-sub-indention px-3 my-2"
				id="#kt_app_sidebar_menu"
				data-kt-menu="true"
				data-kt-menu-expand="false"
			>
				{isSuccess && businesses?.length > 0 ? (
					<MenuInnerWithSub
						title={`${activeBusiness.title} (${activeStore.title ?? intl.formatMessage({ id: 'MENU.BUSINESSES.NO_LOCATIONS' })})`}
						onClick={() => setOpenDropdownStores((prevState) => !prevState)}
						to="/business"
						menuPlacement="bottom-start"
						menuTrigger="click"
						classname="sidebar-button my-2 btn btn-primary px-3"
						image={activeBusiness?.logo?.url}
					>
						{
							<>
								{businesses.map((business) => (
									<Fragment key={business._id}>
										<div className="sidebar-business-container">
											<MenuButton
												title={business.title}
												classname={clsx({ active: business._id === activeBusiness._id })}
												onClick={() => handleChangeActiveBusiness(business)}
											/>
										</div>
									</Fragment>
								))}

								<div onClick={() => dispatch(setShowModal({ type: 'business_new' }))}>
									<MenuItem
										title={intl.formatMessage({ id: 'MENU.BUSINESSES.ADD_ORGANIZATION' })}
										to="#"
										fontIcon="bi-plus-square-fill"
									/>
								</div>
							</>
						}
					</MenuInnerWithSub>
				) : (
					<div onClick={() => dispatch(setShowModal({ type: 'business_new' }))}>
						<MenuItem title={intl.formatMessage({ id: 'MENU.BUSINESSES.ADD_ORGANIZATION' })} to="#" fontIcon="bi-plus-square-fill" />
					</div>
				)}
			</div>

			<div className="sidebar-seperator" />

			<div className="app-sidebar-menu overflow-x">
				<div
					id="kt_app_sidebar_menu_wrapper"
					className="app-sidebar-wrapper hover-scroll-overlay-y"
					data-kt-scroll="true"
					data-kt-scroll-activate="true"
					data-kt-scroll-dependencies="#kt_app_sidebar_logo, #kt_app_sidebar_footer"
					data-kt-scroll-wrappers="#kt_app_sidebar_menu"
					data-kt-scroll-offset="5px"
					data-kt-scroll-save-state="true"
				>
					<div
						onClick={() => {
							//click handle to trigger event bubbling
						}}
						className="menu menu-column menu-rounded menu-sub-indention px-3"
						id="#kt_app_sidebar_menu"
						data-kt-menu="true"
						data-kt-menu-expand="false"
					>
						<SidebarMenuMain />
					</div>
				</div>
			</div>
		</>
	);
};

export default SidebarMenu;
