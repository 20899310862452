import { useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useGetAssignedTagsQuery, useGetTagsForRefQuery } from 'src/pages/analytics/tag/api/tagApiSlice';
import { HorizontalTagList, TagPicker } from 'src/pages/analytics/tag/components/TagPickers';

import { KTSVG } from '../../_metronic/helpers';
import { PopUp } from '../Popup';
import useModal from './useModal';

const ModalHeader = () => {
	const intl = useIntl();
	const [tagCoordinates, setTagCoordinates] = useState(null);
	const { editMode, title, onClose, tags, tagConfig, payload } = useModal();
	const { data: assignedTags } = useGetTagsForRefQuery([tagConfig?.payload?._id || tagConfig?.payload], { skip: !editMode });
	const [tagLocation, setTagLocation] = useState(null);
	const tagIcon = useRef(null);
	const [popup, setPopup] = useState(false);
	const [tagRef, setTagRef] = useState(null);
	const toggleDiv = (e) => {
		setTagRef(e.nativeEvent.target);
		setPopup(true);
		e.stopPropagation();
		e.preventDefault();
	};

	return (
		<div className="modal-header">
			<div className="d-flex">
				<h1 className="text-capitalize">{editMode ? `${intl.formatMessage({ id: 'GENERAL.EDIT' })} ${title}` : title}</h1>
			</div>
			<div className="d-flex">
				<span style={{ alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
					<HorizontalTagList tags={assignedTags} />
				</span>
				{editMode && tagConfig && (
					<div ref={(el) => (tagIcon.current = el)} className="btn btn-sm btn-icon btn-active-color-primary mx-4" onClick={toggleDiv}>
						<i className="fas fs-2 fa-tags" />
					</div>
				)}
				<div className="btn btn-sm btn-icon btn-active-color-primary" onClick={onClose}>
					<KTSVG className="svg-icon-1" path="/media/icons/duotune/abstract/abs012.svg" />
				</div>
				{popup && (
					<PopUp show={popup} anchorElement={tagRef} selfClose={() => setPopup(false)} style={{}}>
						<TagPicker
							individualRowId={tagConfig.payload._id || tagConfig.payload}
							selectedRows={[]}
							refModel={tagConfig.referenceModel}
							section={[tagConfig.section]}
							tagLocation
							onApply={() => {
								setPopup(false);
							}}
						/>
					</PopUp>
				)}
			</div>
		</div>
	);
};

export default ModalHeader;
