import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Controller } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import Select from 'react-select';
import { customStyles } from 'src/common/inputStyles';
import { DateFormat, formatDate } from 'src/helpers/dates';

import useModal from '../../../components/modal/useModal';
import { selectActiveStore } from '../../../reducers/business/businessSlice';
import { useGetStoreAvailabilityQuery } from '../../../reducers/reservation/reservationApiSlice';
import MergedTableDropdown from '../reservationPages/MergedTableDropdown';

const ReservationServiceAreas = ({ register, control, watch, errors, setValue }) => {
	// Selectors
	const { _id: activeStoreId } = useSelector(selectActiveStore);

	const [availableTime, setAvailableTime] = useState(null);

	// Watchers
	const startDate = watch('date') ?? new Date();
	const storeId = watch('store_id') ?? activeStoreId;
	const area = watch('area');
	const areaId = watch('area_id');
	const people = watch('people');
	const source = watch('source');
	const time = watch('time');
	const duration = watch('duration_mins') ?? 120;
	const serviceTables = watch('tables') ?? [];

	// State
	const [openDropdown, setOpenDropdown] = useState('');
	const [areas, setAreas] = useState([]);
	const [tables, setTables] = useState([]);
	const [selectedServiceAreaId, setSelectedServiceAreaId] = useState(areaId ?? '');
	const [selectedTablesId, setSelectedTablesId] = useState([]);
	const [editModeChildFlag, setEditModeChildFlag] = useState(true);
	const [editTime, setEditTime] = useState(null);
	const [editPeopleSize, setEditPeopleSize] = useState(null);
	const [editSource, setEditSource] = useState(null);
	const [editStatus, setEditStatus] = useState(null);
	const [editDuration, setEditDuration] = useState(null);
	const [editPreferences, setEditPreferences] = useState(null);
	const [editServiceAreas, setEditServiceAreas] = useState(null);
	const [editTables, setEditTables] = useState([]);
	const [availableHours, setAvailableHours] = useState([]);
	const [defaultAvailableHour, setDefaultAvailableHour] = useState('');

	const intl = useIntl();
	// Availability
	const today = new Date();
	today.setHours(0, 0, 0, 0);
	const startOfDay = new Date(startDate);
	startOfDay.setHours(0, 0, 0, 0);
	const formattedDate = formatDate(startDate, DateFormat.DAY_MONTH_YEAR_HYPHEN);

	const { id } = useModal();
	// Availability that also sets the global state
	const { data: availabilityResponse = {}, isSuccess: isAvailabilitySuccess } = useGetStoreAvailabilityQuery({
		path: { id: storeId !== activeStoreId ? storeId : activeStoreId },
		reservationId: id,
		people: people ?? 2,
		date: formattedDate,
		source: source ?? 'walk-in',
		duration: duration ?? 120,
		time: time ?? undefined,
		skip: storeId === '' && startOfDay >= today,
	});

	// Functions
	const dropdownValue = openDropdown;
	const handleOpenDropdownChange = (value) => {
		setOpenDropdown(value);
	};

	const handleAreaIdChange = (newAreaId) => {
		setSelectedServiceAreaId(newAreaId);
	};

	const handleServiceAreaIdChange = (selectedOption) => {
		handleAreaIdChange(selectedOption.id);
	};

	function arraysAreEqual(arr1, arr2) {
		return arr1.length === arr2.length && arr1.every((value, index) => value === arr2[index]);
	}

	const handleTableSelection = (selectedTables) => {
		const selectedTableLabels = selectedTables.map((table) => table.type);

		const selectedTableIds = tableOptions.filter((table) => selectedTableLabels.includes(table.label)).map((table) => table.id);

		if (!arraysAreEqual(selectedTablesId, selectedTableIds)) {
			setSelectedTablesId(selectedTableIds);
			setValue('table_ids', selectedTableIds);
		}
	};

	// Use effect hooks for synchronization with external ecosystem
	useEffect(() => {
		if (isAvailabilitySuccess) {
			const availability = availabilityResponse?.availability || [];
			if (availability.length === 0) {
				setAvailableTime('');
				setAvailableHours([]);
				setAreas([]);
				setTables([]);
				setValue('area', {});
				setValue('area_id', '');
				setValue('tables', []);
				setValue('table_ids', []);
			} else {
				const currentDate = new Date();
				const currentHour = currentDate.getHours();
				const currentMinute = currentDate.getMinutes();

				let filteredAvailability = availability;

				const startDt = new Date(startDate);
				if (
					startDt.getDate() === currentDate.getDate() &&
					startDt.getMonth() === currentDate.getMonth() &&
					startDt.getFullYear() === currentDate.getFullYear()
				) {
					filteredAvailability = availability.filter((slot) => {
						const [slotHour, slotMinute] = slot.time.split(':').map(Number);
						return slotHour > currentHour || (slotHour === currentHour && slotMinute >= currentMinute);
					});
				}

				const hours = filteredAvailability.map((slot) => ({
					value: slot.time,
					label: slot.time + (slot.discount ? ` - ${slot.discount}` : ''),
				}));
				setAvailableHours(hours);
				if (hours.length) {
					setDefaultAvailableHour(hours[0].label);
				}
			}
		}
	}, [isAvailabilitySuccess, availabilityResponse.availability, startDate]);

	const defaultHour = useMemo(() => {
		return availableHours.length > 0 ? defaultAvailableHour : '';
	}, [availableHours, defaultAvailableHour]);

	useEffect(() => {
		setAvailableTime(defaultHour);
	}, [defaultHour, setAvailableTime, setValue]);

	useEffect(() => {
		const selectedTimeData = availabilityResponse.availability?.find((slot) => slot.time === availableTime);
		const areaOptions = selectedTimeData
			? selectedTimeData.areas.map((area) => ({
					id: area['_id'],
					value: area.title,
					label: area.title,
			  }))
			: [];
		setAreas(areaOptions);
	}, [availableTime, availabilityResponse.availability]);

	const [tableOptions, setTableOptions] = useState([]);

	useEffect(() => {
		const selectedTimeData = availabilityResponse.availability?.find((slot) => slot.time === availableTime);
		const selectedAreaData = selectedTimeData ? selectedTimeData.areas.find((area) => area['_id'] === selectedServiceAreaId) : undefined;
		const newTables = selectedAreaData
			? selectedAreaData.tables.map((table) => ({
					id: table['_id'],
					value: table.title,
					label: table.title,
			  }))
			: [];
		setTables(newTables.map((table) => table.label));
		setTableOptions(newTables);
	}, [availableTime, availabilityResponse.availability, selectedServiceAreaId]);

	// Edit Mode

	const findMatchingServiceAreas = useCallback(
		(reservationArea) => {
			return areas.find((option) => option.id === reservationArea);
		},
		[areas]
	);

	// Separate useEffect for handling areas
	useEffect(() => {
		if (areas.length > 0) {
			const matchingServiceAreaOption = findMatchingServiceAreas(areaId);
			setEditServiceAreas(matchingServiceAreaOption);
		}
	}, [areas]);

	useEffect(() => {
		if (editTime != null && editModeChildFlag) {
			const selectedTimeData = availabilityResponse.availability?.find((slot) => slot.time === editTime.value);
			const selectedAreaData = selectedTimeData ? selectedTimeData.areas.find((area) => area['_id'] === areaId) : undefined;
			const newTables = selectedAreaData
				? selectedAreaData.tables.map((table) => ({
						id: table['_id'],
						value: table.title,
						label: table.title,
				  }))
				: [];
			const matchedTableNames = tables
				.map((resTable) => {
					const match = newTables.find((newTable) => newTable.id === resTable._id);
					return match ? match.value : null;
				})
				.filter((value) => value !== null);
			setEditTables(matchedTableNames);
			const matchedIds = tables
				.map((resTable) => {
					const match = newTables.find((newTable) => newTable.id === resTable._id);
					return match ? match.id : null;
				})
				.filter((id) => id !== null);
			setSelectedTablesId(matchedIds);
			setValue('table_ids', matchedIds);
			setEditModeChildFlag(false);
		}
	}, [editTime]);

	useEffect(() => {
		if (editTime) {
			setAvailableTime(editTime.value);
			setEditTime(null);
		}
		if (editPeopleSize) {
			setEditPeopleSize(null);
		}
		if (editSource) {
			setEditSource(null);
		}
		if (editStatus) {
			setEditStatus(null);
		}
		if (editDuration) {
			setEditDuration(null);
		}
		if (editServiceAreas) {
			handleServiceAreaIdChange(editServiceAreas);
			setEditServiceAreas(null);
		}
	}, [editTime, editPeopleSize, editSource, editStatus, editDuration, editPreferences, editServiceAreas]);

	// Custom dropdown
	const tableRef = useRef();
	const dropdownRefs = [tableRef];
	// On click listeners for dropdowns
	useEffect(() => {
		const handleClick = (ev) => {
			const isClickInsideAnyDropdown = dropdownRefs.some((ref) => ref.current && ref.current.contains(ev.target));
			const isClickInsideOpenDropdown = openDropdown !== '';

			if (!isClickInsideAnyDropdown && isClickInsideOpenDropdown) {
				setOpenDropdown('');
			}
		};

		window.addEventListener('click', handleClick);
		return () => {
			window.removeEventListener('click', handleClick);
		};
	}, [dropdownRefs, openDropdown]);
	return (
		<>
			<div className="row pb-5">
				<div className="col-12">
					<div className="input-group">
						<label htmlFor="guest-email" className="pb-3 mb-0 fs-7 fw-bold required">
							<FormattedMessage id="RESERVATION.DETAILS_OPTIONS.SERVICE_AREA" />
						</label>
						<Controller
							name="area"
							control={control}
							render={({ field }) => (
								<Select
									{...field}
									styles={customStyles}
									className="basic-single"
									classNamePrefix="select"
									{...register('area')}
									defaultValue={area}
									options={areas}
									onChange={(selectedOption) => {
										field.onChange(selectedOption);
										handleServiceAreaIdChange(selectedOption);
										setValue('area_id', selectedOption.id);
									}}
									placeholder={intl.formatMessage({ id: 'PLACEHOLDER.SELECT' })}
								/>
							)}
						/>
						{errors?.area && (
							<div className="fv-plugins-message-container">
								<div className="fv-help-block">
									<span role="alert">{errors.area.message}</span>
								</div>
							</div>
						)}
					</div>
				</div>
			</div>

			{tables.length > 0 && Object.keys(area).length > 0 ? (
				<MergedTableDropdown
					openDropdownValue={dropdownValue}
					onOpenDropdownChange={handleOpenDropdownChange}
					tableRef={dropdownRefs}
					onTableSelection={handleTableSelection}
					tables={tables}
					serviceTables={serviceTables && serviceTables.length > 0 ? serviceTables.map((t) => ({ id: t._id, type: t.title })) : null}
					editTables={editTables}
					setEditTables={setEditTables}
					register={register}
					watch={watch}
					control={control}
					errors={errors}
				/>
			) : (
				<div className="col-12 col-sm-6">
					<div className="pb-3 fs-7 fw-bold">
						<FormattedMessage id="RESERVATION.MERGED_TABLE.ASSIGNED_TABLE" />
					</div>
					<div className="custom-select">
						<div className="select-wrapper">
							<div className="select-header location-field p-4">
								<span className="selected-option">
									<FormattedMessage id="RESERVATION.DETAILS_OPTIONS.NO_AVAILABLE_TABLES" />
								</span>
							</div>
						</div>
					</div>
				</div>
			)}
		</>
	);
};

export default ReservationServiceAreas;
