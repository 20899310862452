import React, { useCallback, useContext, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useEscKey } from 'src/helpers/utils';

export const PopUpContext = React.createContext();

export const PopUp = ({ children, style, show, anchorElement, selfClose, showCloseIcon = true }) => {
	const selfRef = useRef(null);
	const [position, setPosition] = useState({ x: 0, y: 0 });
	const [reload, setReload] = useState(0);

	const updatePosition = () => {
		if (!show || !selfRef.current || !anchorElement) {
			return;
		}

		const targetElement = anchorElement.current || anchorElement;
		if (!targetElement) return;

		const anchorRect = targetElement.getBoundingClientRect();
		const selfRect = selfRef.current.getBoundingClientRect();

		// Ensure popup stays within viewport
		const viewportWidth = window.innerWidth;
		const viewportHeight = window.innerHeight;

		let x = anchorRect.x - selfRect.width;
		let y = anchorRect.y;

		// Adjust horizontal position if popup would go off-screen
		if (x < 0) {
			x = anchorRect.right;
		}

		// Adjust vertical position if popup would go off-screen
		if (y + selfRect.height > viewportHeight) {
			y = viewportHeight - selfRect.height - 10;
		}
		if (x === 0 && y === 0) {
			return;
		}

		setPosition({ x, y });
	};

	useEffect(() => {
		updatePosition();

		const handleResize = () => {
			requestAnimationFrame(updatePosition);
		};

		window.addEventListener('resize', handleResize);
		window.addEventListener('scroll', handleResize, true);

		return () => {
			window.removeEventListener('resize', handleResize);
			window.removeEventListener('scroll', handleResize, true);
		};
	}, [show, anchorElement, reload]);

	useEffect(() => {
		const handleClick = (e) => {
			if (position.x === 0 && position.y === 0) return;
			if (selfRef.current && !selfRef.current.contains(e.target)) {
				if (e.target.type === 'checkbox') return;
				selfClose?.();
				setPosition({ x: 0, y: 0 });
			}
		};

		if (show) {
			window.addEventListener('click', handleClick);
		}

		if (!show) {
			window.removeEventListener('click', handleClick);
		}

		return () => {
			window.removeEventListener('click', handleClick);
		};
	}, [show, selfClose, position]);

	const refreshView = useCallback(() => {
		setReload((prev) => prev + 1);
	}, []);

	const onClose = () => {
		setPosition({ x: 0, y: 0 });
		selfClose?.();
	};

	if (!show || !position)
		// return (
		// 	<span>
		// 		{JSON.stringify(position)}show: {`${show}`}
		// 	</span>
		// );
		return null;

	return (
		<PopUpContext.Provider value={{ reload, onClose, refreshView }}>
			<div
				ref={selfRef}
				style={{
					border: '1px solid #E0E0E0',
					backgroundColor: '#FFFFFF',
					borderRadius: 4,
					position: 'fixed',
					maxWidth: 200,
					zIndex: 1001,
					opacity: position.x === 0 && position.y === 0 ? 0 : 1,
					padding: 10,
					...style,
					left: position.x,
					top: position.y,
				}}
			>
				{showCloseIcon && (
					<div
						style={{
							display: 'flex',
							justifyContent: 'flex-end',
							alignItems: 'center',
							fontSize: '16',
						}}
					>
						<i
							className="bi bi-x"
							onClick={() => {
								onClose();
								setPosition({ x: 0, y: 0 });
							}}
						/>
					</div>
				)}
				{position.x === 0 && position.y === 0 ? <></> : children}
			</div>
		</PopUpContext.Provider>
	);
};
