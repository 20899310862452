import clsx from 'clsx';
import React, { memo, useEffect, useState } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

const MergedTableDropdown = ({ openDropdownValue, onOpenDropdownChange, tableRef, onTableSelection, tables, editTables, serviceTables }) => {
	const [manualTableFields, setManualTableFields] = useState([]);
	const initialValues = { table: serviceTables ? serviceTables : [{ type: tables[0] }] };
	const { watch, control } = useForm({
		defaultValues: {
			...initialValues,
		},
	});

	const {
		fields: tableFields,
		append: appendTable,
		remove: removeTable,
	} = useFieldArray({
		name: 'table',
		control,
	});

	const selectedTables = watch('table');

	const [currentOpenDropdown, setCurrentOpenDropdown] = useState(-1);

	useEffect(() => {
		onOpenDropdownChange(openDropdownValue);
		onTableSelection(selectedTables);
	}, [onOpenDropdownChange, onTableSelection, openDropdownValue, selectedTables]);

	const toggleTableDropdown = (index) => {
		if (currentOpenDropdown === index) {
			setCurrentOpenDropdown(-1);
			onOpenDropdownChange('');
		} else {
			setCurrentOpenDropdown(index);
			onOpenDropdownChange(`table_${index}`);
		}
	};

	const getAvailableOptions = (currentIndex) => {
		return tables.filter((option) => !selectedTables.some((table, idx) => table.type === option && idx !== currentIndex));
	};

	const getNextAvailableOption = () => {
		const selected = selectedTables.map((t) => t.type);
		const unselectedTables = tables.filter((option) => !selected.includes(option));
		if (unselectedTables.length) {
			return unselectedTables[0];
		}
		return '';
	};

	// Initialize manualTableFields when the component mounts or editTables changes
	useEffect(() => {
		if (editTables.length > 0) {
			setManualTableFields(editTables.map((table) => ({ type: table })));
		}
	}, [editTables]);

	// Synchronize manualTableFields with useFieldArray
	useEffect(() => {
		// Remove all existing fields first
		if (manualTableFields.length > 0) {
			for (let i = tableFields.length - 1; i >= 0; i--) {
				removeTable(i);
			}

			// Append new fields from manualTableFields
			manualTableFields.forEach((field) => {
				appendTable(field);
			});
		}
	}, [manualTableFields, removeTable, appendTable]);

	return (
		<div className="p-5 generic-border">
			{tableFields && tableFields.length > 0
				? tableFields.map((fieldTable, index) => (
						<div className="row" key={fieldTable?.id}>
							<div className="col-12">
								<Controller
									name={`table[${index}].type`}
									control={control}
									defaultValue={fieldTable?.type}
									render={({ field }) => (
										<div className="row pb-5">
											<div className={clsx('col-12 col-sm-5')}>
												<div className="pb-3 fs-7 fw-bold required">
													<FormattedMessage id="RESERVATION.MERGED_TABLE.ASSIGNED_TABLE" />
												</div>
												<div className={clsx('custom-select', { open: currentOpenDropdown === index })}>
													<div className="select-wrapper">
														<div
															className="select-header icon-left p-4"
															onClick={() => toggleTableDropdown(index)}
															ref={tableRef[index]}
														>
															<span className="selected-option fs-6 fw-bold">{field.value}</span>
															<i className="fa-solid fa-chevron-down arrow-icon" />
														</div>
														<ul
															className={clsx('select-options fs-6 fw-bold', {
																'd-none': currentOpenDropdown !== index,
															})}
														>
															{getAvailableOptions(index).map((tableOption) => (
																<li
																	key={tableOption}
																	className={clsx('option', { active: field.value === tableOption })}
																	onClick={() => {
																		field.onChange(tableOption);
																		setCurrentOpenDropdown(-1);
																	}}
																>
																	{tableOption}
																</li>
															))}
														</ul>
													</div>
													<div className={clsx('custom-select-icon icon-right', { 'd-none': tableFields.length < 2 })}>
														<a href="#" onClick={() => removeTable(index)}>
															<i className="fa-solid fa-trash-can text-danger" />
														</a>
													</div>
												</div>
											</div>
										</div>
									)}
								/>
								{/*{errors?.tables && (*/}
								{/*	<div className="fv-plugins-message-container">*/}
								{/*		<div className="fv-help-block">*/}
								{/*			<span role="alert">{errors.tables.message}</span>*/}
								{/*		</div>*/}
								{/*	</div>*/}
								{/*)}*/}
							</div>
						</div>
				  ))
				: null}
			{tableFields.length < tables.length && (
				<div className="row">
					<div className="col-12 col-sm-5">
						<button
							type="button"
							className="secondary-button fw-bold w-100 fs-6 p-4"
							onClick={() => {
								appendTable({ type: getNextAvailableOption() });
							}}
						>
							<FormattedMessage id="RESERVATION.MERGED_TABLE.MERGE_TABLE" />
						</button>
					</div>
				</div>
			)}
		</div>
	);
};

export default memo(MergedTableDropdown);
