import useTable from './useTable';

function useTableWithItems({ title, items, totalRows, selectable, content, additionalContextValues, onRowClick, tagConfig = {} }) {
	const useQuery = (args) => {
		const sort = args?.query?.sort;
		let sortedItems = items.toSorted(sortItems(sort));
		return { data: { items: sortedItems, count: totalRows }, isLoading: false, isSuccess: true };
	};

	const tableValues = {
		title,
		tableName: 'items',
		useQuery: useQuery,
		selectable,
		content,
		onRowClick,
		tagConfig,
		additionalContextValues,
	};

	return useTable(tableValues);
}

function sortItems(criteria = {}) {
	return function (a, b) {
		let sort = 0;
		Object.keys(criteria).forEach((field) => {
			const order = criteria[field] === 'asc' ? 1 : -1;

			if (a[field] < b[field]) sort = -1 * order;
			if (a[field] > b[field]) sort = 1 * order;
		});

		return sort;
	};
}

export default useTableWithItems;
